import create from "zustand";
import { persist, redux } from "zustand/middleware";
import { assetApi } from "../../../core/http/openAPIClient";
import { log } from "./../../hooks/useLogMiddleware";
import {
  clearAssetData,
  fetchAssetDataFailure,
  fetchAssetDataStart,
  fetchAssetDataSuccess,
} from "./actions";
import { initialState, reducer } from "./reducer";

const useAssetStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "assets",
    }),
  ),
);

export const fetchAssets = async (tenant_code: string) => {
  const dispatch = useAssetStore.getState().dispatch;
  dispatch(fetchAssetDataStart());
  await assetApi
    .assetsApiV1TenantCodeAssetsGet(tenant_code)
    .then((res) => {
      dispatch(fetchAssetDataSuccess(res.data));
    })
    .catch((e) => {
      console.error("fetch assets error", e);
      dispatch(fetchAssetDataFailure(e));
    });
};

export const clearAssets = () => {
  useAssetStore.getState().dispatch(clearAssetData());
};

export default useAssetStore;
