import { Gender } from "../types";

export const MEMBER_REGISTRATION_STEP_IMAGES_PATH =
  "/assets/member_registration/step_{step}.png";
export const MEMBER_REGISTRATION_CARD_IMAGE_PATH =
  "/assets/member_registration/membership_card.png";

export const GENDER_VALIDATION_TEXT = "性別を選択してください";
export const GENDER_SELECTION_OPTIONS = [
  { name: "男性", value: Gender.Male },
  { name: "女性", value: Gender.Female },
  { name: "回答しない", value: Gender.NoAnswer },
];

export const PASSWORD_CHANGE_TEXT = `パスワードを変更する場合は下記に新しいパスワードを入力してください。`;
export const PASSWORD_CHANGE_SUB_TEXT = `※何も入力しなければパスワードは変更されません。`;

export const GENDER_EXCHANGE_SELECTION_OPTIONS = {
  male: 1,
  female: 2,
  not_specified: 3,
};
export const GENDER_EXCHANGE_SELECTION_OPTIONS_NUMBER = [
  "male",
  "female",
  "not_specified",
];

export const STARTING_YEAR = 1920;
export const CURRENT_YEAR = new Date().getFullYear();
export const YEAR_VALIDATION_TEXT = "生まれた年を選択してください";
export const YEAR_SELECTION_OPTIONS = Array.from(
  {
    length: CURRENT_YEAR - STARTING_YEAR + 1,
  },
  (value: any, num: number) => ({
    name: (CURRENT_YEAR - num).toString() + "年",
    value: CURRENT_YEAR - num,
  }),
);

export const MONTH_VALIDATION_TEXT = "誕生月を選択してください";
export const MONTH_SELECTION_OPTIONS = Array.from(
  { length: 12 },
  (value: any, num: number) => ({
    name: (num + 1).toString() + "月",
    value: num + 1,
  }),
);

export const MEMBERSHIP_NUMBER_VALIDATION_TEXT = "会員番号を入力してください";

export const CAMERA_CAPTURE_SUBTEXT =
  "カメラを利用して、カードに記載している番号を読み取り入力することが出来ます";
export const APP_LINKAGE_SUBTEXT =
  "アプリと自動連携し、会員番号を入力することが出来ます";

export const ERROR_CODE_MEMBER_ID = [
  "invalid-member-code-not-found",
  "invalid-member-code-suspended",
  "invalid-member-code-withdrawn",
];
