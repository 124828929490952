import styled from "styled-components";

export const NotificationRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const NotificationTitle = styled.h3`
  margin-top: 30px;
`;

export const NotificationItem = styled.div`
  margin: 0 16px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #cccccccc;
  text-align: left;
  cursor: pointer;
`;

export const NotificationDate = styled.div`
  color: #999999;
  font-size: 12px;
  margin: 8px 0 5px 0;
`;

export const NotificationContent = styled.div`
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  word-wrap: break-word;
`;

export const NotificationBadge = styled.div`
  display: inline-block;
  height: 100%;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px;
  color: #ffffff;
  background-color: #cc7673;
`;

export const PaginationText = styled.div`
  font-size: 14px;
  margin: 0 0 16px 0;
`;

export const PaginationButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  margin: 0 12px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  color: ${(props) => (props.disabled ? "#c3c3c3" : "#000000")};
  background-color: #ffffff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    color: ${(props) => (props.disabled ? "#c3c3c3" : "#ffffff")};
    background-color: ${(props) => (props.disabled ? "#ffffff" : "#c3c3c3")};
  }
`;

export const NoNotification = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const NoNotificationText = styled.p`
  font-size: 16px;
  color: #999999;
`;
