import {
  FETCH_CAMPAIGN_DATA_FAILURE,
  FETCH_CAMPAIGN_DATA_START,
  FETCH_CAMPAIGN_DATA_SUCCESS,
  FETCH_CATEGORY_DATA,
  FILTER_CAMPAIGN_DATA,
  FILTER_PROMOTED_CAMPAIGN_DATA,
  JOIN_CAMPAIGN_FAILURE,
  JOIN_CAMPAIGN_SUCCESS,
  RESET_CAMPAIGN_DATA,
} from "./actions";

export const initialState = {
  loading: false,
  campaigns: [],
  filteredCampaigns: [],
  filteredPromotedCampaigns: [],
  categories: [],
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_CAMPAIGN_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        campaigns: action.payload,
      };
    case FETCH_CAMPAIGN_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FILTER_CAMPAIGN_DATA:
      return { ...state, filteredCampaigns: action.payload };
    case FILTER_PROMOTED_CAMPAIGN_DATA:
      return { ...state, filteredPromotedCampaigns: action.payload };
    case RESET_CAMPAIGN_DATA:
      return { ...state, filteredCampaigns: state.campaigns };
    case JOIN_CAMPAIGN_SUCCESS:
      return state;
    case JOIN_CAMPAIGN_FAILURE:
      return state;
    case FETCH_CATEGORY_DATA:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};
