import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { LineLinkageMode, useLineLink } from "../../../lib/hooks/useLineLink";
import { useNavigate } from "../../../lib/hooks/useNavigate";
import { HeaderTemplate } from "../../template";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 56px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
`;

export const LineAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const code = query.get("code");
  const state = query.get("state");
  const mode = query.get("mode") as LineLinkageMode;
  const { lineLogin, lineRegister, lineLinkage } = useLineLink(mode);
  //
  useEffect(() => {
    if (code && state) {
      if (mode === "login") {
        lineLogin(code, state);
      } else if (mode === "register") {
        lineRegister(code, state);
      } else if (mode === "linkage") {
        lineLinkage(code, state);
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [code, state]);
  //
  return (
    <HeaderTemplate>
      <Container />
    </HeaderTemplate>
  );
};
