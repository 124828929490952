import { settingStore } from "../../components/template/store/setting";

const setting = settingStore.getState();
export const PRIMARY_COLOR = setting.theme_color
  ? setting.theme_color
  : "#01469b";

export const LINK_TEXT_COLOR = "#007AFF";
export const LINE_ID_COLOR = "#06C755";
export const CONNECT_MEMBER_COLOR = "#fa8b0a";

export const DISABLED_COLOR = "#D3D3D3";
