import { ModalProps } from "../../ui/modal/types";

export const defaultModalProps: ModalProps = {
  isShow: false,
  title: "",
  subTitle: "",
  image_url: "",
  LINE: false,
  contents: "",
  buttons: [],
  onClose: () => {
    /**/
  },
};
