import {
  APPLY_POINT_SUCCESS,
  CLEAR_EARNED_CAMPAIGN_DATA,
  FETCH_EARNED_CAMPAIGN_DATA_FAILURE,
  FETCH_EARNED_CAMPAIGN_DATA_START,
  FETCH_EARNED_CAMPAIGN_DATA_SUCCESS,
  FILTER_EARNED_CAMPAIGN_DATA,
  RESET_EARNED_CAMPAIGN_DATA,
} from "./actions";

export const initialState = {
  loading: false,
  earnedCampaigns: [],
  filteredEarnedCampaigns: [],
  isAppliedPoint: false,
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_EARNED_CAMPAIGN_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_EARNED_CAMPAIGN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        earnedCampaigns: action.payload,
      };
    case FETCH_EARNED_CAMPAIGN_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FILTER_EARNED_CAMPAIGN_DATA:
      return { ...state, filteredEarnedCampaigns: action.payload };
    case RESET_EARNED_CAMPAIGN_DATA:
      return {
        ...state,
        filteredEarnedCampaigns: state.earnedCampaigns,
      };
    case APPLY_POINT_SUCCESS:
      return { ...state, isAppliedPoint: action.payload !== null };
    case CLEAR_EARNED_CAMPAIGN_DATA:
      return initialState;
    default:
      return state;
  }
};
