import { Information } from "../../lib/information/redux/types";

export const filterWithinPublishPeriodInformations = (
  informations: Information[],
) => {
  const now = new Date();
  return informations.filter((information) => {
    return (
      new Date(information.start_at) < now && new Date(information.end_at) > now
    );
  });
};
