import React, { useCallback, useEffect } from "react";
import {
  MEMBER_REGISTRATION_PASSWORD_SUBTEXT,
  PASSWORD_VALIDATION_TEXT,
} from "../../../../common/constant/validations";
import { BtnContainer, HeaderContainer } from "../../../../common/style";
import useAssetStore from "../../../../lib/asset/redux/store";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { TextField } from "../../../ui";
import { Selection } from "../../../ui/selection/Selection";
import {
  GENDER_EXCHANGE_SELECTION_OPTIONS,
  GENDER_SELECTION_OPTIONS,
  GENDER_VALIDATION_TEXT,
  MONTH_SELECTION_OPTIONS,
  MONTH_VALIDATION_TEXT,
  PASSWORD_CHANGE_SUB_TEXT,
  PASSWORD_CHANGE_TEXT,
  YEAR_SELECTION_OPTIONS,
  YEAR_VALIDATION_TEXT,
} from "../constant";
import useMemberRegistrationStore, {
  setMemberRegistrationConfirmPassword,
  setMemberRegistrationEmail,
  setMemberRegistrationGender,
  setMemberRegistrationMonth,
  setMemberRegistrationPassword,
  setMemberRegistrationYear,
} from "../redux/store";
import {
  Container,
  PasswordChangeSubText,
  PasswordChangeText,
  SelectionContainer,
  TextContainer,
} from "../style";

export const EditMemberContainer: React.FC = () => {
  const gender = useMemberRegistrationStore((state) => state.gender);
  const year = useMemberRegistrationStore((state) => state.year);
  const month = useMemberRegistrationStore((state) => state.month);

  const genderError = useMemberRegistrationStore((state) => state.genderError);
  const yearError = useMemberRegistrationStore((state) => state.yearError);
  const monthError = useMemberRegistrationStore((state) => state.monthError);
  const passwordError = useMemberRegistrationStore(
    (state) => state.passwordError,
  );
  const confirmPasswordError = useMemberRegistrationStore(
    (state) => state.confirmPasswordError,
  );
  const navigation = useNavigate();

  const member = useAssetStore((state) => state.member);
  useEffect(() => {
    setMemberRegistrationEmail(member.email);
    setMemberRegistrationGender(
      GENDER_EXCHANGE_SELECTION_OPTIONS[
        member.gender as keyof typeof GENDER_EXCHANGE_SELECTION_OPTIONS
      ],
    );
    setMemberRegistrationYear(member.birth_year);
    setMemberRegistrationMonth(member.birth_month);
  }, [member]);

  const onPrev = useCallback(() => {
    navigation("/");
  }, []);

  return (
    <div>
      <HeaderContainer>
        <BtnContainer
          onClick={() => {
            history.back();
          }}
        >
          <img src="/assets/common/icon_back.svg" />
        </BtnContainer>
        <h3>会員情報編集</h3>
      </HeaderContainer>

      <TextContainer>会員情報を入力してください</TextContainer>
      <Container>
        <SelectionContainer>
          <Selection
            name="gender"
            label="性別"
            required
            placeholder={GENDER_VALIDATION_TEXT}
            options={GENDER_SELECTION_OPTIONS}
            error={genderError}
            //isEdit = true for checking editing flag
            onSelection={(value) => {
              // initially when member page is render onSelection() function get call once and make the submit button
              // enable even without changing value. In order to resolve that we check the state member gender with the input value
              // if the value is different, that mean user have change the gender value
              setMemberRegistrationGender(
                value,
                GENDER_EXCHANGE_SELECTION_OPTIONS[
                  member.gender as keyof typeof GENDER_EXCHANGE_SELECTION_OPTIONS
                ] !== value
                  ? true
                  : false,
                member,
              );
            }}
            value={gender ? gender.toString() : undefined}
          />
        </SelectionContainer>

        <SelectionContainer>
          <Selection
            name="year"
            label="生まれた年"
            required
            placeholder={YEAR_VALIDATION_TEXT}
            options={YEAR_SELECTION_OPTIONS}
            error={yearError}
            //isEdit = true for checking editing flag
            onSelection={(value) =>
              // initially when member page is render onSelection() function get call once and make the submit button
              // enable even without changing value. In order to resolve that we check the state member year with the input value
              // if the value is different, that mean user have change the year value
              setMemberRegistrationYear(
                value,
                member.birth_year !== value ? true : false,
                member,
              )
            }
            value={year ? year.toString() : undefined}
          />
        </SelectionContainer>

        <SelectionContainer>
          <Selection
            name="month"
            label="誕生月"
            required
            placeholder={MONTH_VALIDATION_TEXT}
            options={MONTH_SELECTION_OPTIONS}
            error={monthError}
            onSelection={(value) =>
              // initially when member page is render onSelection() function get call once and make the submit button
              // enable even without changing value. In order to resolve that we check the state member year with the input value
              // if the value is different, that mean user have change the year value
              setMemberRegistrationMonth(
                value,
                member.birth_month !== value ? true : false,
                member,
              )
            }
            value={month ? month.toString() : undefined}
          />
        </SelectionContainer>

        <PasswordChangeText>{PASSWORD_CHANGE_TEXT}</PasswordChangeText>
        <PasswordChangeSubText>
          {PASSWORD_CHANGE_SUB_TEXT}
        </PasswordChangeSubText>

        <TextField
          type="password"
          name="password"
          label="パスワード"
          placeholder={PASSWORD_VALIDATION_TEXT}
          subtext={MEMBER_REGISTRATION_PASSWORD_SUBTEXT}
          onInput={(res) => {
            setMemberRegistrationPassword(res, member);
          }}
          error={
            passwordError === PASSWORD_VALIDATION_TEXT ? null : passwordError
          }
        />
        <TextField
          type="password"
          name="confirmPassword"
          label="パスワード（確認用）"
          placeholder={PASSWORD_VALIDATION_TEXT}
          onInput={(res) => {
            setMemberRegistrationConfirmPassword(res, member);
          }}
          error={
            confirmPasswordError === PASSWORD_VALIDATION_TEXT
              ? null
              : confirmPasswordError
          }
        />
      </Container>
    </div>
  );
};
