import React, { useCallback } from "react";
import {
  EMAIL_VALIDATION_TEXT,
  FORGOT_PASSWORD_EMAIL_SUBTEXT,
} from "../../../../common/constant/validations";
import { BtnContainer, HeaderContainer } from "../../../../common/style";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { TextField } from "../../../ui";
import useForgotPasswordStore, { setForgotPasswordEmail } from "../redux/store";
import { TextContainer } from "../style";

export const ForgotPasswordContainer: React.FC = () => {
  const email = useForgotPasswordStore((state) => state.email);
  const error = useForgotPasswordStore((state) => state.emailError);
  const navigation = useNavigate();

  const onPrev = useCallback(() => {
    navigation("/login");
  }, []);

  return (
    <div>
      <HeaderContainer>
        <BtnContainer onClick={onPrev}>
          <img src="/assets/common/icon_back.svg" />
        </BtnContainer>
        <h3>パスワードを忘れた方</h3>
      </HeaderContainer>

      <TextContainer>
        <TextField
          type="email"
          name="email"
          label="メールアドレス"
          required
          placeholder={EMAIL_VALIDATION_TEXT}
          subtext={FORGOT_PASSWORD_EMAIL_SUBTEXT}
          value={email}
          onInput={setForgotPasswordEmail}
          error={error}
        />
      </TextContainer>
    </div>
  );
};
