import create from "zustand";
import { persist, redux } from "zustand/middleware";
import { isFutureDate, isWithinPeriod } from "../../../../../common/utils/date";
import useAssetStore from "../../../../../lib/asset/redux/store";
import { log } from "../../../../../lib/hooks/useLogMiddleware";
import { EarnedCampaign, Reward } from "../../campaign.type";
import useCampaignStore from "../campaign/store";
import { pointApi } from "./../../../../../core/http/openAPIClient";
import {
  applyPointFailure,
  applyPointSuccess,
  clearEarnedCampaignData,
  fetchEarnedCampaignSuccess,
  filterEarnedCampaignData,
} from "./actions";
import { initialState, reducer } from "./reducer";

const useEarnedCampaignStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "earned-campaigns",
    }),
  ),
);

export const fetchEarnedCampaigns = async () => {
  const dispatch = useEarnedCampaignStore.getState().dispatch;
  const campaigns = useCampaignStore.getState().campaigns;
  const memberRewards = useAssetStore.getState().memberRewards;
  const formattedRewards = memberRewards
    .filter((reward?: Reward) => reward !== undefined)
    .sort((a?: Reward, b?: Reward) => {
      return (
        new Date(b ? b.aquired_at : 0).getTime() -
        new Date(a ? a.aquired_at : 0).getTime()
      );
    })
    .sort((a?: Reward) => {
      return !a?.used_at ? -1 : 0;
    });
  dispatch(fetchEarnedCampaignSuccess(formattedRewards));
  dispatch(filterEarnedCampaignData(formattedRewards));
};

export const filterEarnedCampaigns = (option: string) => {
  const state = useEarnedCampaignStore.getState();
  if (option === "all") {
    return state.dispatch(filterEarnedCampaignData(state.earnedCampaigns));
  }
  const filteredCampaigns = state.earnedCampaigns.filter(
    (campaign: EarnedCampaign) => {
      if (option === "available") {
        return (
          isFutureDate(campaign.used_at) &&
          isWithinPeriod(campaign.start_at, campaign.end_at)
        );
      }
      if (option === "used") return !isFutureDate(campaign.used_at);
    },
  );
  state.dispatch(filterEarnedCampaignData(filteredCampaigns));
};

export const resetEarnedCampaignData = () => {
  const state = useEarnedCampaignStore.getState();
  state.dispatch(filterEarnedCampaignData(state.earnedCampaigns));
};

export const applyPointData = async (
  tenant_code: string,
  member_reward_id: number,
  onSuccess: () => void,
) => {
  const dispatch = useEarnedCampaignStore.getState().dispatch;
  await pointApi
    .pointExchangeApiV1TenantCodePointExchangePost(tenant_code, {
      tenant_code,
      member_reward_id,
    })
    .then((res) => {
      console.log("appli point success", res);
      dispatch(applyPointSuccess(res));
      onSuccess();
    })
    .catch((error) => {
      console.error("appli point error", error);
      dispatch(applyPointFailure(error));
    });
};

export const clearEarnedCampaign = () => {
  useEarnedCampaignStore.getState().dispatch(clearEarnedCampaignData());
};

export default useEarnedCampaignStore;
