import styled from "styled-components";

export const StyledSelection = styled.select<{ selected: boolean }>`
  margin: 12px 0px;
  padding: 10px;
  border-radius: 5px;
  color: ${({ selected }) => (selected ? "#000000" : "#999999")};
  border: 1px solid #999999;
  text-align: left;
  width: 100%;
  height: 48px;
  font-family: "Noto Sans JP", sans-serif;
  cursor: pointer;
`;

export const SelectionLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const RequiredLabel = styled.div`
  margin: 5px 10px;
  padding: 0px 5px;
  color: #ffffff;
  background-color: #cc7673;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
`;

export const DropdownSelectionIcon = styled.div`
  position: absolute;
  top: 18%;
  right: 10px;
`;

export const SelectionSubtext = styled.div`
  color: #999999;
  font-size: 12px;
  text-align: left;
`;

export const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  text-align: left;
`;

export const SelectionOptionDropdown = styled.div`
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  z-index: 10;
`;

export const SelectionOptionItem = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #999999;
  }
`;

export const DisabledSelection = styled.div`
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid #99999999;
`;

export const Selection = styled.select`
  border: 1px solid black;
`;
