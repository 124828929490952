import lotie from "lottie-web";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { onSuccessAppliedPoint } from "../../../../common/utils/campaign";
import { rewardApi } from "../../../../core/http/openAPIClient";
import { applyPointData } from "../../../features/campaign/redux/earnedCampaign/store";

export const useRoulette = (className: string) => {
  // state
  const [isLottery, setIsLottery] = useState<boolean | null>(null);
  const { tenant_code } = useParams();

  const onLottery = useCallback(
    (rewardId: number, point_auto_assignment: boolean) => {
      return rewardApi
        .lotteryDrawApiV1TenantCodeLotteryDrawPost(tenant_code ?? "", {
          member_reward_id: rewardId,
          tenant_code,
        })
        .then((res) => {
          onStart(!!res.data.result);
          if (res.data.result === true && point_auto_assignment === true) {
            applyPointData(tenant_code ?? "", rewardId, () => {
              onSuccessAppliedPoint(tenant_code ?? "");
            });
          }
        });
    },
    [],
  );

  const onStart = useCallback((_isWin: boolean) => {
    const target = document
      .getElementsByClassName(className)
      .item(0) as Element;
    const path = _isWin
      ? "/assets/animation/roulette_win/data.json"
      : "/assets/animation/roulette_lose/data.json";
    const anim = lotie.loadAnimation({
      container: target,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: path,
    });
    anim.addEventListener("complete", () => {
      setIsLottery(_isWin);
    });
    anim.play();
  }, []);

  const onClose = useCallback(() => {
    setIsLottery(null);
  }, []);
  return {
    isLottery,
    onLottery,
    onStart,
    onClose,
  };
};
