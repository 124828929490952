import React, { useCallback } from "react";
import { Button } from "../../button/Button";
import { useModal } from "../store";
import {
  Bg,
  ButtonWrapper,
  CloseBtn,
  Container,
  Image,
  Message,
  SubTitle,
  Title,
} from "../style/style";

export const ModalContainer: React.FC = () => {
  // state
  const isShow = useModal((state) => state.isShow);
  const closeModal = useModal((state) => state.closeModal);
  const title = useModal((state) => state.title);
  const subTitle = useModal((state) => state.subTitle);
  const isShowCloseButton = useModal((state) => state.isShowCloseButton);
  const image_url = useModal((state) => state.image_url);
  const contents = useModal((state) => state.contents);
  const buttons = useModal((state) => state.buttons);
  const useAnim = useModal((state) => state.useAnimation);
  const titleSize = useModal((state) => state.titleSize);
  const close = useCallback(() => {
    closeModal();
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <div>
      <Bg isOpen={isShow} />
      <Container isOpen={isShow}>
        <div>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          {title && <Title textsize={titleSize}>{title}</Title>}
          {isShowCloseButton && (
            <CloseBtn
              onClick={() => {
                close();
              }}
            >
              <img src="/assets/ui/modal/close.svg" alt="close" />
            </CloseBtn>
          )}
        </div>
        {image_url && <Image src={image_url ?? ""} alt="sample" />}
        {contents !== "" && <Message useAnim={useAnim}>{contents}</Message>}
        <ButtonWrapper>
          {buttons?.map((button, index) => {
            return (
              <div
                key={`custom-button-${index}`}
                style={{ marginBottom: "15px" }}
              >
                <Button
                  key={button.name}
                  text={button.name}
                  styling={button.styling}
                  color={button.color}
                  onClick={
                    button.callback
                      ? (button.callback as any)
                      : () => {
                          /* nothing to do */
                        }
                  }
                />
              </div>
            );
          })}
        </ButtonWrapper>
      </Container>
    </div>
  );
};
