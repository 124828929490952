import React, { useEffect } from "react";
import { LINE_ID_COLOR } from "../../../../common/constant/colors";
import {
  EMAIL_VALIDATION_TEXT,
  MEMBER_REGISTRATION_PASSWORD_SUBTEXT,
  PASSWORD_VALIDATION_TEXT,
} from "../../../../common/constant/validations";
import { HeaderContainer } from "../../../../common/style";
import { useLineLink } from "../../../../lib/hooks/useLineLink";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { LoginType, settingStore } from "../../../template/store/setting";
import { Button, TextField } from "../../../ui";
import { Selection } from "../../../ui/selection/Selection";
import {
  GENDER_SELECTION_OPTIONS,
  GENDER_VALIDATION_TEXT,
  MEMBER_REGISTRATION_STEP_IMAGES_PATH,
  MONTH_SELECTION_OPTIONS,
  MONTH_VALIDATION_TEXT,
  YEAR_SELECTION_OPTIONS,
  YEAR_VALIDATION_TEXT,
} from "../constant";
import useMemberRegistrationStore, {
  setMemberRegistrationConfirmPassword,
  setMemberRegistrationEmail,
  setMemberRegistrationGender,
  setMemberRegistrationMonth,
  setMemberRegistrationPassword,
  setMemberRegistrationYear,
} from "../redux/store";
import { ButtonContainer, Container, StepImage, SubTitle } from "../style";

export const MemberRegistrationStepOneContainer: React.FC = () => {
  const email = useMemberRegistrationStore((state) => state.email);
  const gender = useMemberRegistrationStore((state) => state.gender);
  const year = useMemberRegistrationStore((state) => state.year);
  const month = useMemberRegistrationStore((state) => state.month);
  const password = useMemberRegistrationStore((state) => state.password);
  const confirmPassword = useMemberRegistrationStore(
    (state) => state.confirmPassword,
  );
  const emailError = useMemberRegistrationStore((state) => state.emailError);
  const genderError = useMemberRegistrationStore((state) => state.genderError);
  const yearError = useMemberRegistrationStore((state) => state.yearError);
  const monthError = useMemberRegistrationStore((state) => state.monthError);
  const passwordError = useMemberRegistrationStore(
    (state) => state.passwordError,
  );
  const confirmPasswordError = useMemberRegistrationStore(
    (state) => state.confirmPasswordError,
  );
  const navigation = useNavigate();
  const login_type: LoginType = settingStore((state) => state.login_type);

  const { showLineLink } = useLineLink("register");

  useEffect(() => {
    setMemberRegistrationPassword("");
    setMemberRegistrationConfirmPassword("");
  }, []);

  return (
    <div>
      <HeaderContainer>
        <h3>新規会員登録</h3>
      </HeaderContainer>

      <StepImage
        src={MEMBER_REGISTRATION_STEP_IMAGES_PATH.replace("{step}", "1")}
      />

      {(login_type === "line" || login_type === "email_line") && (
        <ButtonContainer>
          <Button
            text="LINE IDで登録"
            color={LINE_ID_COLOR}
            styling="border"
            fontWeight="bold"
            onClick={showLineLink}
          />
          <SubTitle>会員情報を入力してください</SubTitle>
        </ButtonContainer>
      )}

      <Container>
        <TextField
          type="email"
          name="email"
          label="メールアドレス"
          required
          placeholder={EMAIL_VALIDATION_TEXT}
          onInput={setMemberRegistrationEmail}
          error={emailError}
          value={email}
          disactived={true}
        />

        <Selection
          name="gender"
          label="性別"
          required
          placeholder={GENDER_VALIDATION_TEXT}
          options={GENDER_SELECTION_OPTIONS}
          error={genderError}
          onSelection={setMemberRegistrationGender}
          value={gender ? gender.toString() : undefined}
        />

        <Selection
          name="year"
          label="生まれた年"
          required
          placeholder={YEAR_VALIDATION_TEXT}
          options={YEAR_SELECTION_OPTIONS}
          error={yearError}
          onSelection={setMemberRegistrationYear}
          value={year ? year.toString() : undefined}
        />

        <Selection
          name="month"
          label="誕生月"
          required
          placeholder={MONTH_VALIDATION_TEXT}
          options={MONTH_SELECTION_OPTIONS}
          error={monthError}
          onSelection={setMemberRegistrationMonth}
          value={month ? month.toString() : undefined}
        />

        <TextField
          type="password"
          name="password"
          label="パスワード"
          required
          placeholder={PASSWORD_VALIDATION_TEXT}
          subtext={MEMBER_REGISTRATION_PASSWORD_SUBTEXT}
          onInput={setMemberRegistrationPassword}
          error={passwordError}
        />

        <TextField
          type="password"
          name="confirmPassword"
          label="パスワード再入力"
          required
          placeholder={PASSWORD_VALIDATION_TEXT}
          onInput={setMemberRegistrationConfirmPassword}
          error={confirmPasswordError}
        />
      </Container>
    </div>
  );
};
