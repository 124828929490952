import styled from "styled-components";

type CustomProps = {
  option?: string;
  selectedOption?: string;
  checked?: boolean;
};

type ButtonProps = {
  color?: string;
};

export const CampaignCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 0 auto;
  margin: 20px 5%;
  font-family: Arial, sans-serif;
  position: relative;
`;

export const CampaignCardImage = styled.img`
  width: 84px;
  height: 84px;
  border-radius: 8px;
  margin-top: 14px;
  margin-left: 12px;
`;

export const CampaignDetailLink = styled.button<ButtonProps>`
  background-color: ${(props) => (props.color ? props.color : "#10469b")};
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: ${(props) => (props.color ? props.color : "#10469b")} 1px solid;
  border-radius: 8px;
  padding: 8px 0;
  margin: 0 16px 16px 16px;
  width: 90%;
  cursor: pointer;
`;

export const CampaignCardNameWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
`;

export const CampaignCardName = styled.p`
  font-size: 16px;
  text-align: left;
  margin: 12px 0px 0px 0;
  font-weight: bold;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const RewardDescription = styled.div`
  font-size: 16px;
  text-align: left;
  margin: 0px 0px 26px 0;
  font-weight: bold;
`;

export const CampaignCardText = styled.p`
  font-size: 14px;
  width: 100%;
  margin: 0 0 10px 0;
  text-align: center;
`;

export const CampaignCardMile = styled.div`
  font-size: 14px;
  margin-left: 10px;
`;

export const RewardUsed = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: transparent;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 1;
`;
export const RewardUsedBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
`;
export const RewardUsedTxt = styled.span`
  position: relative;
  z-index: 2;
`;

export const CampaignWrapperMessage = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: left;
  display: flex;
  margin-left: 16px;
`;

export const CampaignSpanTitle = styled.span`
  font-size: 14px;
  font-weight: 100;
`;
