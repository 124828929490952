import { MemberData } from "./type";

export const MEMBER_REGISTRATION_SET_EMAIL = "MEMBER_REGISTRATION_SET_EMAIL";
export const MEMBER_REGISTRATION_SET_GENDER = "MEMBER_REGISTRATION_SET_GENDER";
export const MEMBER_REGISTRATION_SET_YEAR = "MEMBER_REGISTRATION_SET_YEAR";
export const MEMBER_REGISTRATION_SET_MONTH = "MEMBER_REGISTRATION_SET_MONTH";
export const MEMBER_REGISTRATION_SET_PASSWORD =
  "MEMBER_REGISTRATION_SET_PASSWORD";
export const MEMBER_REGISTRATION_SET_CONFIRM_PASSWORD =
  "MEMBER_REGISTRATION_SET_CONFIRM_PASSWORD";
export const MEMBER_REGISTRATION_SET_MEMBERSHIP_NUMBER =
  "MEMBER_REGISTRATION_SET_MEMBERSHIP_NUMBER";
export const MEMBER_REGISTRATION_SET_LINE_SERVICE_ID =
  "MEMBER_REGISTRATION_SET_LINE_SERVICE_ID";
export const MEMBER_REGISTRATION_SET_TOKEN = "MEMBER_REGISTRATION_SET_TOKEN";

export const MEMBER_REGISTRATION_START = "MEMBER_REGISTRATION_START";
export const MEMBER_REGISTRATION_SUCCESS = "MEMBER_REGISTRATION_SUCCESS";
export const MEMBER_REGISTRATION_FAILURE = "MEMBER_REGISTRATION_FAILURE";

export const MEMBER_REGISTRATION_EMAIL_SET_EMAIL =
  "MEMBER_REGISTRATION_EMAIL_SET_EMAIL";
export const MEMBER_REGISTRATION_EMAIL_START =
  "MEMBER_REGISTRATION_EMAIL_START";
export const MEMBER_REGISTRATION_EMAIL_SUCCESS =
  "MEMBER_REGISTRATION_EMAIL_SUCCESS";
export const MEMBER_REGISTRATION_EMAIL_FAILURE =
  "MEMBER_REGISTRATION_EMAIL_FAILURE";

export const MEMBER_REGISTRATION_RESET_PASSWORD =
  "MEMBER_REGISTRATION_RESET_PASSWORD";

export const EDIT_MEMBER_START = "EDIT_MEMBER_START";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_FAILURE = "EDIT_MEMBER_FAILURE";

export const SET_MEMBER_DATA = "SET_MEMMBER_DATA";

export const CLEAR_MEMBER_INFORMATION = "CLEAR_MEMBER_INFORMATION";

export const memberRegistrationSetEmail = (
  email: string,
  isEdit?: boolean,
) => ({
  type: MEMBER_REGISTRATION_SET_EMAIL,
  payload: { email, isEdit },
});
export const memberRegistrationSetGender = (
  gender: number,
  isEdit?: boolean,
  originData?: MemberData,
) => ({
  type: MEMBER_REGISTRATION_SET_GENDER,
  payload: { gender, isEdit, originData },
});
export const memberRegistrationSetYear = (
  year: number,
  isEdit?: boolean,
  originData?: MemberData,
) => ({
  type: MEMBER_REGISTRATION_SET_YEAR,
  payload: { year, isEdit, originData },
});
export const memberRegistrationSetMonth = (
  month: number,
  isEdit?: boolean,
  originData?: MemberData,
) => ({
  type: MEMBER_REGISTRATION_SET_MONTH,
  payload: { month, isEdit, originData },
});
export const memberRegistrationSetPassword = (
  password: string,
  originData?: MemberData,
) => ({
  type: MEMBER_REGISTRATION_SET_PASSWORD,
  payload: { password, originData },
});
export const memberRegistrationSetConfirmPassword = (
  password: string,
  originData?: MemberData,
) => ({
  type: MEMBER_REGISTRATION_SET_CONFIRM_PASSWORD,
  payload: { password, originData },
});
export const memberRegistrationSetMembershipNumber = (
  membershipNumber: string,
) => ({
  type: MEMBER_REGISTRATION_SET_MEMBERSHIP_NUMBER,
  payload: membershipNumber,
});
export const memberRegistrationSetLineServiceId = (lineServiceId: string) => ({
  type: MEMBER_REGISTRATION_SET_LINE_SERVICE_ID,
  payload: lineServiceId,
});

export const memberRegistrationStart = () => ({
  type: MEMBER_REGISTRATION_START,
});
export const memberRegistrationSuccess = (data: any) => ({
  type: MEMBER_REGISTRATION_SUCCESS,
  payload: data,
});
export const memberRegistrationFailure = (error: any) => ({
  type: MEMBER_REGISTRATION_FAILURE,
  payload: error,
});

export const memberRegistrationSetToken = (token: string) => ({
  type: MEMBER_REGISTRATION_SET_TOKEN,
  payload: token,
});
export const memberRegistrationEmailSetEmail = (email: string) => ({
  type: MEMBER_REGISTRATION_EMAIL_SET_EMAIL,
  payload: email,
});
export const memberRegistrationEmailStart = () => ({
  type: MEMBER_REGISTRATION_EMAIL_START,
});
export const memberRegistrationEmailSuccess = (data: any) => ({
  type: MEMBER_REGISTRATION_EMAIL_SUCCESS,
  payload: data,
});
export const memberRegistrationEmailFailure = (error: any) => ({
  type: MEMBER_REGISTRATION_EMAIL_FAILURE,
  payload: error,
});
export const memberRegistrationResetPassword = () => ({
  type: MEMBER_REGISTRATION_RESET_PASSWORD,
});

export const editMemberStart = () => ({
  type: EDIT_MEMBER_START,
});
export const editMemberSuccess = (data: any) => ({
  type: EDIT_MEMBER_SUCCESS,
  payload: data,
});
export const editMemberFailure = (error: any) => ({
  type: EDIT_MEMBER_FAILURE,
  payload: error,
});

export const memberData = (data: MemberData) => ({
  type: SET_MEMBER_DATA,
  payload: data,
});

export const clearMemberInfo = () => ({ type: CLEAR_MEMBER_INFORMATION });
