export const RESET_PASSWORD_SET_PASSWORD = "RESET_PASSWORD_SET_PASSWORD";
export const RESET_PASSWORD_SET_CONFIRM_PASSWORD =
  "RESET_PASSWORD_SET_CONFIRM_PASSWORD";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const resetPasswordSetPassword = (password: string) => ({
  type: RESET_PASSWORD_SET_PASSWORD,
  payload: password,
});
export const resetPasswordSetConfirmPassword = (password: string) => ({
  type: RESET_PASSWORD_SET_CONFIRM_PASSWORD,
  payload: password,
});

export const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
});
export const resetPasswordSuccess = (data: any) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});
export const resetPasswordFailure = (error: any) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});
