import React from "react";
import { CSSProperties } from "styled-components";
import { Icon, LabelText, StyledLabelRepeat } from "./style/label-repeat";

interface ProgressBarProps {
  style?: CSSProperties;
  is_card: boolean;
}

export const RewardLabel: React.FC<ProgressBarProps> = (props) => {
  const labelText = (is_card: boolean) => {
    if (!is_card) {
      return "何回でも";
    }
    return `何回 \nでも`;
  };
  return (
    <StyledLabelRepeat is_card={props.is_card}>
      <LabelText>{labelText(props.is_card)}</LabelText>
      <Icon is_card={props.is_card}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={!props.is_card ? 12 : 17}
          height={!props.is_card ? 14 : 18}
          viewBox="0 -2 12 12"
        >
          <path
            id="パス_1237"
            data-name="パス 1237"
            d="M55.655,6.575v8.532m4.783-4.266H50.873m7.932-5.835c-.843-.945-3.15,1.569-3.15,1.569S59.815,6.139,58.805,5.006Zm-3.15,1.569s-2.306-2.514-3.149-1.569C51.5,6.139,55.655,6.575,55.655,6.575Zm-3.357,0h6.714a1.356,1.356,0,0,1,1.426,1.272v5.989a1.356,1.356,0,0,1-1.426,1.271H52.3a1.356,1.356,0,0,1-1.426-1.271V7.847A1.356,1.356,0,0,1,52.3,6.575Z"
            transform="translate(-50.26 -6.168)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.244"
          />
        </svg>
      </Icon>
    </StyledLabelRepeat>
  );
};
