import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "../../../lib/hooks/useNavigate";
import { ERROR_CODE_MEMBER_ID } from "../../features/member_registration/constant";
import {
  MemberRegistrationStepOneContainer,
  MemberRegistrationStepThreeContainer,
  MemberRegistrationStepTwoContainer,
} from "../../features/member_registration/container";
import { useMemberRegister } from "../../features/member_registration/hooks/useMemberRegister";
import useMemberRegistrationStore, {
  onMemberRegistration,
  remapMemberData,
  removeMemberData,
  resetPassword,
  setMemberData,
  setMemberRegistrationEmail,
  setMemberRegistrationToken,
} from "../../features/member_registration/redux/store";
import { HeaderTemplate } from "../../template";
import { settingStore } from "../../template/store/setting";
import { Base } from "../../template/style";
import { Modal } from "../../ui";
import { Footer } from "../../ui/footer/Footer";

const MAX_STEPS = 3;

export const MemberRegistration: React.FC = () => {
  const { step, tenant_code } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const uuid = query.get("uuid");
  const token = query.get("token");
  const queryEmail = query.get("email")?.replace(/\s/g, "+");
  const error_code = query.get("error-code");
  const member_id = query.get("member_id");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(
    step ? Number(step) : 0,
  );

  const { loginWithRegisterData, checkSuPayId } = useMemberRegister();
  const email = useMemberRegistrationStore((state) => state.email);
  const membershipNumber = useMemberRegistrationStore(
    (state) => state.membershipNumber,
  );
  const enabled = useMemberRegistrationStore((state) => state.enabled);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const lineFriend = settingStore((state) => state.line_settings);

  useEffect(() => {
    if (uuid) {
      // 連携時はページ遷移させない
      return;
    }
    if (
      !step ||
      isNaN(Number(step)) ||
      Number(step) < 1 ||
      Number(step) > MAX_STEPS
    )
      return setCurrentStep(1);

    if (Number(step) > 1 && !email) return setCurrentStep(1);

    setCurrentStep(Number(step));
  }, [step, uuid]);

  useEffect(() => {
    if (currentStep === 1 && !token && !email) {
      navigate(`/login`);
    }
    if (uuid) {
      // アプリ連携時はページ遷移させない
      return;
    }
    if (error_code) {
      return setCurrentStep(2);
    }
    if (currentStep > 1 && currentStep <= MAX_STEPS)
      navigate(`/register/${currentStep}`);
    else setCurrentStep(1);
  }, [currentStep]);

  useEffect(() => {
    if (token) {
      if (queryEmail) {
        setMemberRegistrationEmail(queryEmail, false);
      }
      setMemberRegistrationToken(token ?? "");
    }
    if (uuid) {
      const registerData = remapMemberData(uuid);
      if (registerData && member_id) {
        setMemberData({
          ...registerData,
          membershipNumber: member_id,
        });
        removeMemberData(uuid);
        if (step) {
          setCurrentStep(Number(step));
        }
      }
    }
  }, [uuid, step, member_id, token]);

  const onNext = useCallback(() => {
    if (currentStep === 3) {
      resetPassword();
      return setCurrentStep(1);
    }
    if (currentStep === 2) {
      if (error_code) {
        navigate(`/register/3`);
      }
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, membershipNumber]);

  const onComplete = useCallback(() => {
    setIsComplete(true);
    setIsButtonDisabled(false);
  }, []);

  const onClose = useCallback(() => {
    if (lineFriend?.allow_adding_friends) {
      navigate("/line_friend");
    } else {
      loginWithRegisterData();
    }
    setIsComplete(false);
  }, [loginWithRegisterData, lineFriend]);

  return (
    <Base>
      <HeaderTemplate>
        {currentStep === 1 && <MemberRegistrationStepOneContainer />}
        {currentStep === 2 && <MemberRegistrationStepTwoContainer />}
        {currentStep === 3 && <MemberRegistrationStepThreeContainer />}
        <Modal
          isShow={isComplete}
          title={"会員登録しました"}
          subTitle={""}
          contents={""}
          onClose={onClose}
        />
      </HeaderTemplate>
      <Footer
        buttonDisabled={
          (currentStep === 2 ? membershipNumber.length === 0 : !enabled) ||
          isButtonDisabled
        }
        buttonText={currentStep === 3 ? "登録" : "次へ"}
        onClickButton={
          currentStep === 3
            ? () => {
                setIsButtonDisabled(true);
                onMemberRegistration(tenant_code ?? "")
                  .then(onComplete)
                  .catch((e) => {
                    setIsButtonDisabled(false);
                    const isError = ERROR_CODE_MEMBER_ID.includes(
                      e.error.response.data["error-code"],
                    );
                    if (isError) {
                      navigate(
                        `/register/2?from=true&error-code=${e.error.response.data["error-code"]}`,
                      );
                    }
                  });
              }
            : onNext
        }
        secondaryButtonText={currentStep === 3 ? "入力内容を変更" : undefined}
        onClickSecondaryButton={onNext}
      />
    </Base>
  );
};
