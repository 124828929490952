import { MenuItem } from "../types";

export const menus = (
  isLineLinked: boolean,
  isEnableLineLinked: boolean,
  isLoggedIn: boolean,
  showLineLink: () => void,
  hasUnreadNotification: boolean,
  logoutHandler: () => void,
): MenuItem[] => {
  const loggedInMenus: MenuItem[] = [
    {
      name: "お知らせ",
      route: "/notification/1",
      badge: hasUnreadNotification
        ? { color: "#CC7673", text: "NEW" }
        : undefined,
      isEnable: true,
    },
    {
      name: "メールアドレス変更",
      route: "/change_email",
      isEnable: true,
    },
    {
      name: "会員情報編集",
      route: "/edit_member",
      isEnable: true,
    },
    {
      name: "登録した会員番号の変更",
      route: "/change_register_number",
      isEnable: true,
    },
    {
      name: "利用規約",
      route: "/terms_of_service",
      isEnable: true,
    },
    {
      name: "LINE IDと連携",
      subtext:
        "LINE IDと連携することで、簡単にログインができるようになります。",
      badge: {
        color: isLineLinked ? "#06C755" : "#999999",
        text: isLineLinked ? "連携済み" : "未連携",
      },
      onClick: showLineLink,
      isEnable: isEnableLineLinked,
    },
    {
      name: "ログアウト",
      onClick: logoutHandler,
      isEnable: true,
    },
    {
      name: "退会",
      action: "withdraw",
      isEnable: true,
    },
  ];
  const loggedOutMenus: MenuItem[] = [
    {
      name: "ログイン",
      route: "/login",
      isEnable: true,
    },
    {
      name: "新規会員登録",
      route: "/walkthrough",
      isEnable: true,
    },
    {
      name: "利用規約",
      route: "/terms_of_service",
      isEnable: true,
    },
  ];
  return isLoggedIn ? loggedInMenus : loggedOutMenus;
};
