import styled from "styled-components";

type CustomProps = {
  option?: string;
  selectedOption?: string;
  checked?: boolean;
  isAquired?: boolean;
};

export const CampaignCard = styled.div`
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 0 auto;
  margin: 20px 5%;
  font-family: Arial, sans-serif;
`;

export const CampaignCardImage = styled.img`
  width: 100%;
  height: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const CampaignDetailLink = styled.div<{ isAlreadyEarned?: boolean }>`
  background-color: #ffffff;
  color: black;
  font-size: 14px;
  font-weight: medium;
  border: 1px solid black;
  border-radius: 8px;
  margin: 10px 0px 16px 10px;
  width: 0 auto;
  width: 64px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Noto Sans JP", sans-serif;
  cursor: pointer;
`;

export const CampaignCardText = styled.div`
  font-size: 13px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-top: 10px;
`;

export const TextContainer = styled.p`
  font-size: 14px;
  width: 100%;
  text-align: center;
`;

export const CampaignCardContentWrapper = styled.div<CustomProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  ${({ isAquired }) =>
    isAquired &&
    `
    display: flex;
    place-items: center;
    justify-content: space-between;

  `}
`;

export const CampaignCardMile = styled.div`
  font-size: 12px;
  width: 100%;
  height: 60px;
  min-width: 162px;
  margin-top: 0.75rem;
  text-align: left;
  margin-left: 5px;
  margin-right: 7px;
`;

export const CampaignCardMileText = styled.span`
  color: #0271ff;
  font-weight: bold;
`;

export const CampaignAquiredText = styled.div`
  color: #0271ff;
  font-size: 14px;
  font-weight: bold;
`;

export const CampaignDetailAquiredText = styled.div`
  color: #0271ff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

export const CampaignButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-family: "Noto Sans JP", sans-serif;
`;
