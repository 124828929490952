import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
`;
const Message = styled.p`
  font-size: 16px;
  line-height: 31px;
  color: #999999;
`;

export const NotFound: React.FC = () => {
  return (
    <Container>
      <Message>
        {"お探しのページは見つかりませんでした"}
        <br />
        {"もう一度URLをお確かめください。"}
      </Message>
    </Container>
  );
};
