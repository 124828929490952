import { ResetPasswordValidationSchema } from "../validation";
import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SET_CONFIRM_PASSWORD,
  RESET_PASSWORD_SET_PASSWORD,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
} from "./actions";
import { ResetPasswordState } from "./type";

export const initialState: ResetPasswordState = {
  loading: false,
  password: "",
  passwordError: null,
  confirmPassword: "",
  confirmPasswordError: null,
  enabled: false,
};

export const reducer = (state = initialState, action: any) => {
  let resetPasswordInfo;
  switch (action.type) {
    case RESET_PASSWORD_SET_PASSWORD:
      resetPasswordInfo = ResetPasswordValidationSchema.safeParse({
        password: action.payload,
        confirmPassword: state.confirmPassword,
      });

      if (resetPasswordInfo.success) {
        return {
          ...state,
          password: action.payload,
          passwordError: null,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        return {
          ...state,
          password: action.payload,
          passwordError:
            resetPasswordInfo.error.formErrors.fieldErrors["password"]?.[0] ??
            resetPasswordInfo.error.formErrors.formErrors?.[0] ??
            null,
          confirmPasswordError:
            resetPasswordInfo.error.formErrors.formErrors?.[0] ?? null,
          enabled: false,
        };
      }
    case RESET_PASSWORD_SET_CONFIRM_PASSWORD:
      resetPasswordInfo = ResetPasswordValidationSchema.safeParse({
        password: state.password,
        confirmPassword: action.payload,
      });
      if (resetPasswordInfo.success) {
        return {
          ...state,
          passwordError: null,
          confirmPassword: action.payload,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        return {
          ...state,
          passwordError:
            resetPasswordInfo.error.formErrors.formErrors?.[0] ?? null,
          confirmPassword: action.payload,
          confirmPasswordError:
            resetPasswordInfo.error.formErrors.fieldErrors[
              "confirmPassword"
            ]?.[0] ??
            resetPasswordInfo.error.formErrors.formErrors?.[0] ??
            null,
          enabled: false,
        };
      }
    case RESET_PASSWORD_START:
      return { ...state, loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case RESET_PASSWORD_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
