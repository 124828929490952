import React from "react";

interface ProgressBarProps {
  style: string;
}

export const ProgressDotSmall: React.FC<ProgressBarProps> = (props) => {
  return (
    <div className={props.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 6 6"
      >
        <circle
          id="楕円形_4"
          data-name="楕円形 4"
          cx="3"
          cy="3"
          r="3"
          fill="#fff"
        />
      </svg>
    </div>
  );
};
