import getPkce from "oauth-pkce";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { AppConfig } from "../../../../AppConfig";
import {
  getSupayAuthToken,
  getSupayCardStatus,
  getSupayToken,
} from "../../../../core/http/httpClient";
import { LocalStorageManager } from "../../../../core/storage/LocalStorageManager";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { settingStore } from "../../../template/store/setting";
import {
  onLogin,
  setLoginEmail,
  setLoginPassword,
} from "../../login/redux/store";
import useMemberRegistrationStore, {
  saveMemberData,
  setMemberRegistrationMembershipNumber,
} from "../redux/store";

export const useMemberRegister = () => {
  const { tenant_code } = useParams();
  const email = useMemberRegistrationStore((state) => state.email);
  const password = useMemberRegistrationStore((state) => state.password);
  const deepLink = settingStore((state) => state.retail_id_input.deep_link_url);
  const [verifier, setVerifier] = useState<string>("");
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [memberId, setMemberId] = useState<string>("");
  const TRIAL_TENANT_CODES = process.env.REACT_APP_TRIAL_TENANT_CODE?.split(
    " ",
  ) as string[];

  const navigate = useNavigate();
  const loginWithRegisterData = useCallback(() => {
    if (tenant_code && email && password) {
      setLoginEmail(email);
      setLoginPassword(password);
      onLogin(tenant_code, (res) => {
        LocalStorageManager.Instance.saveObject(
          AppConfig.Instance.LocalStorageKey["accessToken"],
          res.data.access_token,
        );
        navigate("/campaign");
      });
    }
  }, [email, password, tenant_code, navigate]);

  const setAppLinkage = useCallback(
    (targetPage: string) => {
      if (tenant_code && TRIAL_TENANT_CODES.includes(tenant_code)) {
        const index = TRIAL_TENANT_CODES.indexOf(tenant_code);
        const _uuid = crypto.randomUUID();
        saveMemberData(_uuid);
        //get verifier and code_challenge of 43 characters long. We can change from 43 to anything else
        getPkce(43, (error, { verifier, challenge }) => {
          // handle generate success
          if (!error) {
            const redirect_uri =
              process.env.REACT_APP_API_BASE +
              TRIAL_TENANT_CODES[index] +
              "/su-pay/redirect";
            const target_url = targetPage + "?uuid=" + _uuid;
            localStorage.setItem("verifier", verifier);
            localStorage.setItem("redirect_uri", redirect_uri);
            localStorage.setItem("target_url", target_url);
            const SUPAY_AUTH_LINK = (
              process.env.REACT_APP_SUPAY_AUTH_LINK?.split(" ") as string[]
            )[index];
            const SUPAY_AUTH_CLIENT_ID = (
              process.env.REACT_APP_SUPAY_AUTH_CLIENT_ID?.split(" ") as string[]
            )[index];
            const url =
              "${su-pay_url}/v1/authorization?client_id=${client_id}&scope=openid&response_type=code&redirect_uri=${redirect_uri}&code_challenge_method=S256&code_challenge=${code_challenge}"
                .replace("${su-pay_url}", SUPAY_AUTH_LINK)
                .replace("${client_id}", SUPAY_AUTH_CLIENT_ID)
                .replace("${redirect_uri}", redirect_uri)
                .replace("${code_challenge}", challenge);
            location.href = url;
          }
        });
      } else {
        window.location.href = deepLink;
      }
    },
    [tenant_code],
  );

  const checkSuPayId = async (membershipNumber: string) => {
    const tokenResult = await getSupayToken().catch((e) => {
      return { error: e };
    });
    if (
      (tokenResult as any).error ||
      (tokenResult as any).data.code !== "M9200"
    ) {
      console.log("error:", (tokenResult as any).error);
      return false;
    }
    const token = (tokenResult as any).data.data;
    const cardStatus = await getSupayCardStatus(membershipNumber, token).catch(
      (e) => {
        return { error: e };
      },
    );
    if (
      (cardStatus as any).error ||
      (cardStatus as any).data.code !== "M9200"
    ) {
      console.log("error:", (cardStatus as any).error);
      return false;
    }
    console.log("cardStatus:", cardStatus);
    return true;
  };

  const getSupayId = async (params: any, tenant_code: string) => {
    await getSupayAuthToken(params, tenant_code)
      .then((res) => {
        const resultJson = JSON.parse((res as any).data.body);
        setMemberRegistrationMembershipNumber(resultJson.member_id);
        const targetUrl =
          (localStorage.getItem("target_url") as string) +
          "&member_id=" +
          resultJson.member_id;
        navigate(targetUrl);
      })
      .catch((err) => {
        console.log("supay token error:", err);
        return { error: err };
      });
  };

  return {
    verifier,
    redirectUrl,
    memberId,
    setAppLinkage,
    loginWithRegisterData,
    checkSuPayId,
    getSupayId,
  };
};
