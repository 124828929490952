import React, { MouseEvent, MouseEventHandler } from "react";
import { settingStore } from "../../../template/store/setting";
import { FilledMemberButton } from "../../../ui/button/style";

export type ConnectMemberButtonProps = {
  text: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  color?: string;
};

export const ConnectMemberButton: React.FC<ConnectMemberButtonProps> = ({
  text,
  disabled,
  onClick,
  ...props
}: ConnectMemberButtonProps) => {
  const handleOnClick = (e: MouseEvent) => {
    if (disabled) return;

    if (onClick) onClick(e);
  };
  const themeColor = settingStore((store) => store.theme_color);

  return (
    <>
      <FilledMemberButton
        disabled={disabled}
        onClick={handleOnClick}
        color={themeColor}
        {...props}
      >
        {text}
      </FilledMemberButton>
    </>
  );
};
