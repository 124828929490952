export const Manifest = () => {
  const url = window.location.href;
  const manifestObj = {
    start_url: url,
  };
  const jsonManifest = JSON.stringify(manifestObj);
  const blob = new Blob([jsonManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  return manifestURL;
};
