import { useParams, useNavigate as useReactNavigate } from "react-router-dom";

export const useNavigate = () => {
  const reactNavigate = useReactNavigate();
  const { tenant_code } = useParams();

  const navigate = (path: string, options?: { replace?: boolean }) => {
    if (path.startsWith("?")) {
      reactNavigate(path, options);
    } else {
      reactNavigate(
        `/${tenant_code}/${path.startsWith("/") ? path.substring(1) : path}`,
        options,
      );
    }
  };

  return navigate;
};
