import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchAssets } from "../../../lib/asset/redux/store";
import { ResponseType } from "../../../lib/index.type";
import { MemberRegistrationEmailContainer } from "../../features/member_registration";
import useMemberRegistrationStore, {
  sendMemberRegistrationEmail,
  updateMemberInfomation,
} from "../../features/member_registration/redux/store";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { Modal } from "../../ui";
import { Footer } from "../../ui/footer/Footer";
import { useNavigate } from "../../../lib/hooks/useNavigate";

export const ChangeEmail: React.FC = () => {
  const { tenant_code } = useParams();
  const navigation = useNavigate();
  const enabled = useMemberRegistrationStore((state) => state.enabled);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const queryEmail = query.get("email")?.replace(/\s/g, "+");

  const onCompleteSendEmail = useCallback(() => {
    navigation("/mail_send");
  }, []);

  const onCompleteChangeEmail = useCallback(() => {
    setModalProps({
      isShow: true,
      title: "メールアドレスを変更しました。",
      subTitle: "",
      contents: "",
      onClose: () => {
        setModalProps({ ...modalProps, isShow: false });
      },
    });
  }, []);

  const onFailedChangeEmail = useCallback(() => {
    setModalProps({
      isShow: true,
      title: "メールアドレスを変更できませんでした。",
      subTitle: "",
      contents: "",
      onClose: () => {
        setModalProps({ ...modalProps, isShow: false });
      },
    });
  }, []);

  interface ModalProps {
    isShow: boolean;
    title: string;
    subTitle: string;
    contents: string;
    onClose: () => void;
  }

  const [modalProps, setModalProps] = useState<ModalProps>({
    isShow: false,
    title: "",
    subTitle: "",
    contents: "",
    onClose: () => {
      /**/
    },
  });

  useEffect(() => {
    if (token && tenant_code && queryEmail) {
      updateMemberInfomation(tenant_code, token)
        .then((res) => {
          if ((res as ResponseType)?.error) {
            return onFailedChangeEmail();
          } else {
            setTimeout(() => {
              fetchAssets(tenant_code || "");
            }, 500);
            return onCompleteChangeEmail();
          }
        })
        .catch((e) => {
          return onFailedChangeEmail;
        });
    }
  }, []);

  return (
    <Base>
      <HeaderTemplate>
        <MemberRegistrationEmailContainer page={`update`} />
        <Modal {...modalProps} />
      </HeaderTemplate>
      <Footer
        buttonText="送信"
        onClickButton={() => {
          sendMemberRegistrationEmail(tenant_code ?? "", "update")
            .then(onCompleteSendEmail)
            .catch((e) => {
              /* nothing to do */
              console.log("error", e);
            });
        }}
        buttonDisabled={!enabled}
      />
    </Base>
  );
};
