/* tslint:disable */
/* eslint-disable */
/**
 * MM(Maker Mileage) API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.11
 * Contact: developers@quad.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {Member}
     * @memberof Asset
     */
    'member'?: Member;
    /**
     * 
     * @type {Array<MemberCampaign>}
     * @memberof Asset
     */
    'member_campaigns'?: Array<MemberCampaign>;
    /**
     * 
     * @type {Array<MemberReward>}
     * @memberof Asset
     */
    'member_rewards'?: Array<MemberReward>;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof Asset
     */
    'notifications'?: Array<Notification>;
    /**
     * 
     * @type {Array<MemberNotification>}
     * @memberof Asset
     */
    'member_notifications'?: Array<MemberNotification>;
}
/**
 * 
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'access_token'?: string;
}
/**
 * 
 * @export
 * @interface BirthMonth
 */
export interface BirthMonth {
}
/**
 * 
 * @export
 * @interface BirthYear
 */
export interface BirthYear {
}
/**
 * 
 * @export
 * @interface ChangeMemberCode
 */
export interface ChangeMemberCode {
    /**
     * 
     * @type {string}
     * @memberof ChangeMemberCode
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeMemberCode
     */
    'member_code'?: string;
}
/**
 * 
 * @export
 * @interface ConnectOAuth
 */
export interface ConnectOAuth {
    /**
     * 
     * @type {string}
     * @memberof ConnectOAuth
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectOAuth
     */
    'line_service_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateMember
 */
export interface CreateMember {
    /**
     * 
     * @type {string}
     * @memberof CreateMember
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMember
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMember
     */
    'member_code'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof CreateMember
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof CreateMember
     */
    'birth_year'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMember
     */
    'birth_month'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMember
     */
    'password'?: string;
    /**
     * 
     * @type {LineServiceId}
     * @memberof CreateMember
     */
    'line_service_id'?: LineServiceId;
}


/**
 * 
 * @export
 * @interface EntryCampaign
 */
export interface EntryCampaign {
    /**
     * 
     * @type {string}
     * @memberof EntryCampaign
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntryCampaign
     */
    'campaign_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'male',
    Female: 'female',
    NotSpecified: 'not_specified'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GetOAuthURL
 */
export interface GetOAuthURL {
    /**
     * 
     * @type {string}
     * @memberof GetOAuthURL
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOAuthURL
     */
    'oauth_service_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOAuthURL
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface IdPosCampaign
 */
export interface IdPosCampaign {
    /**
     * 
     * @type {number}
     * @memberof IdPosCampaign
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaign
     */
    'campaign_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaign
     */
    'campaign_content'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaign
     */
    'campaign_period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaign
     */
    'campaign_period_end'?: string;
    /**
     * 
     * @type {Array<IdPosCampaignProduct>}
     * @memberof IdPosCampaign
     */
    'campaign_products'?: Array<IdPosCampaignProduct>;
}
/**
 * 
 * @export
 * @interface IdPosCampaignList
 */
export interface IdPosCampaignList {
    /**
     * 
     * @type {Array<IdPosCampaign>}
     * @memberof IdPosCampaignList
     */
    'campaign_list'?: Array<IdPosCampaign>;
}
/**
 * 
 * @export
 * @interface IdPosCampaignMemberList
 */
export interface IdPosCampaignMemberList {
    /**
     * 
     * @type {Array<IdPosMemberCampaignList>}
     * @memberof IdPosCampaignMemberList
     */
    'member_list'?: Array<IdPosMemberCampaignList>;
}
/**
 * 
 * @export
 * @interface IdPosCampaignProduct
 */
export interface IdPosCampaignProduct {
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaignProduct
     */
    'period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaignProduct
     */
    'period_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosCampaignProduct
     */
    'jancode'?: string;
}
/**
 * 
 * @export
 * @interface IdPosMember
 */
export interface IdPosMember {
    /**
     * 
     * @type {string}
     * @memberof IdPosMember
     */
    'member_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosMember
     */
    'joined_at'?: string;
}
/**
 * 
 * @export
 * @interface IdPosMemberCampaign
 */
export interface IdPosMemberCampaign {
    /**
     * 
     * @type {number}
     * @memberof IdPosMemberCampaign
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdPosMemberCampaign
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosMemberCampaign
     */
    'period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdPosMemberCampaign
     */
    'period_end'?: string;
    /**
     * 
     * @type {Array<IdPosMember>}
     * @memberof IdPosMemberCampaign
     */
    'members'?: Array<IdPosMember>;
}
/**
 * 
 * @export
 * @interface IdPosMemberCampaignList
 */
export interface IdPosMemberCampaignList {
    /**
     * 
     * @type {Array<IdPosMemberCampaign>}
     * @memberof IdPosMemberCampaignList
     */
    'campaigns'?: Array<IdPosMemberCampaign>;
}
/**
 * 
 * @export
 * @interface LineServiceId
 */
export interface LineServiceId {
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface LotteryDraw
 */
export interface LotteryDraw {
    /**
     * 
     * @type {string}
     * @memberof LotteryDraw
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof LotteryDraw
     */
    'member_reward_id'?: number;
}
/**
 * 
 * @export
 * @interface LotteryResult
 */
export interface LotteryResult {
    /**
     * 
     * @type {number}
     * @memberof LotteryResult
     */
    'member_reward_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LotteryResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LotteryResult
     */
    'code_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LotteryResult
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'tenant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'member_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'email'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof Member
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'birth_year'?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'birth_month'?: number;
}


/**
 * 
 * @export
 * @interface MemberCampaign
 */
export interface MemberCampaign {
    /**
     * 
     * @type {number}
     * @memberof MemberCampaign
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberCampaign
     */
    'campaign_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberCampaign
     */
    'accumulated_mileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberCampaign
     */
    'entry_date'?: string;
    /**
     * 
     * @type {Array<PurchaseLog>}
     * @memberof MemberCampaign
     */
    'purchase_logs': Array<PurchaseLog>;
}
/**
 * 
 * @export
 * @interface MemberCode
 */
export interface MemberCode {
}
/**
 * 
 * @export
 * @interface MemberNotification
 */
export interface MemberNotification {
    /**
     * 
     * @type {number}
     * @memberof MemberNotification
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberNotification
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberNotification
     */
    'end_at'?: string;
}
/**
 * 
 * @export
 * @interface MemberReward
 */
export interface MemberReward {
    /**
     * 
     * @type {number}
     * @memberof MemberReward
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberReward
     */
    'campaign_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberReward
     */
    'campaign_reward_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'banner_image_url'?: string;
    /**
     * 
     * @type {RewardType}
     * @memberof MemberReward
     */
    'reward_type'?: RewardType;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'term_of_use'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'start_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'end_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberReward
     */
    'is_lottery'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberReward
     */
    'point_auto_assignment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'used_at'?: string;
    /**
     * 
     * @type {PrizeStatus}
     * @memberof MemberReward
     */
    'prize_status'?: PrizeStatus;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'code_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReward
     */
    'aquired_at'?: string;
}


/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'highlight_flag'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'start_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'end_at'?: string;
}
/**
 * 
 * @export
 * @interface OAuthCode
 */
export interface OAuthCode {
    /**
     * 
     * @type {string}
     * @memberof OAuthCode
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthCode
     */
    'redirect_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthCode
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthCode
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface OAuthCodeForMemberCode
 */
export interface OAuthCodeForMemberCode {
    /**
     * 
     * @type {string}
     * @memberof OAuthCodeForMemberCode
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthCodeForMemberCode
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthCodeForMemberCode
     */
    'code_verifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthCodeForMemberCode
     */
    'redirect_uri'?: string;
}
/**
 * 
 * @export
 * @interface OAuthInfo
 */
export interface OAuthInfo {
    /**
     * 
     * @type {string}
     * @memberof OAuthInfo
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthInfo
     */
    'auth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthInfo
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface OAuthURL
 */
export interface OAuthURL {
    /**
     * 
     * @type {string}
     * @memberof OAuthURL
     */
    'info_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthURL
     */
    'auth_url'?: string;
}
/**
 * 
 * @export
 * @interface Password
 */
export interface Password {
}
/**
 * 
 * @export
 * @interface PointExchange
 */
export interface PointExchange {
    /**
     * 
     * @type {string}
     * @memberof PointExchange
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof PointExchange
     */
    'member_reward_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PrizeStatus = {
    Won: 'won',
    Lost: 'lost',
    InProgress: 'in_progress',
    NotDrawn: 'not_drawn',
    None: 'none'
} as const;

export type PrizeStatus = typeof PrizeStatus[keyof typeof PrizeStatus];


/**
 * 
 * @export
 * @interface PurchaseLog
 */
export interface PurchaseLog {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLog
     */
    'date'?: string;
    /**
     * 
     * @type {object}
     * @memberof PurchaseLog
     */
    'purchase_logs'?: object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RewardType = {
    Point: 'point',
    Coupon: 'coupon'
} as const;

export type RewardType = typeof RewardType[keyof typeof RewardType];


/**
 * 
 * @export
 * @interface SendPasswordReset
 */
export interface SendPasswordReset {
    /**
     * 
     * @type {string}
     * @memberof SendPasswordReset
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPasswordReset
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPasswordReset
     */
    'new_password'?: string;
}
/**
 * 
 * @export
 * @interface SendPasswordResetEmail
 */
export interface SendPasswordResetEmail {
    /**
     * 
     * @type {string}
     * @memberof SendPasswordResetEmail
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPasswordResetEmail
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SendVerificationEmail
 */
export interface SendVerificationEmail {
    /**
     * 
     * @type {string}
     * @memberof SendVerificationEmail
     */
    'tenant_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendVerificationEmail
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendVerificationEmail
     */
    'mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendVerificationEmail
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMember
 */
export interface UpdateMember {
    /**
     * 
     * @type {string}
     * @memberof UpdateMember
     */
    'token'?: string;
    /**
     * 
     * @type {MemberCode}
     * @memberof UpdateMember
     */
    'member_code'?: MemberCode;
    /**
     * 
     * @type {UpdateMemberGender}
     * @memberof UpdateMember
     */
    'gender'?: UpdateMemberGender;
    /**
     * 
     * @type {BirthYear}
     * @memberof UpdateMember
     */
    'birth_year'?: BirthYear;
    /**
     * 
     * @type {BirthMonth}
     * @memberof UpdateMember
     */
    'birth_month'?: BirthMonth;
    /**
     * 
     * @type {Password}
     * @memberof UpdateMember
     */
    'password'?: Password;
}
/**
 * 
 * @export
 * @interface UpdateMemberGender
 */
export interface UpdateMemberGender {
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet_1: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet_1', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet_2: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet_2', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.assetsApiV1AssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet_1(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet_1(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.assetsApiV1AssetsGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.assetsApiV1TenantCodeAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet_2(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet_2(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.assetsApiV1TenantCodeAssetsGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet_1(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet_1(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet_2(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet_2(tenantCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsApiV1AssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsApiV1AssetsGet_1(tenantCode: string, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsApiV1AssetsGet_1(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsApiV1TenantCodeAssetsGet_2(tenantCode: string, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet_2(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1CampaignEntryPost: async (entryCampaign: EntryCampaign, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryCampaign' is not null or undefined
            assertParamExists('campaignEntryApiV1CampaignEntryPost', 'entryCampaign', entryCampaign)
            const localVarPath = `/api/v1/campaign/entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryCampaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1CampaignEntryPost_1: async (entryCampaign: EntryCampaign, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryCampaign' is not null or undefined
            assertParamExists('campaignEntryApiV1CampaignEntryPost_1', 'entryCampaign', entryCampaign)
            const localVarPath = `/api/v1/campaign/entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryCampaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {string} tenantCode 
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1TenantCodeCampaignEntryPost: async (tenantCode: string, entryCampaign: EntryCampaign, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('campaignEntryApiV1TenantCodeCampaignEntryPost', 'tenantCode', tenantCode)
            // verify required parameter 'entryCampaign' is not null or undefined
            assertParamExists('campaignEntryApiV1TenantCodeCampaignEntryPost', 'entryCampaign', entryCampaign)
            const localVarPath = `/api/v1/{tenant_code}/campaign/entry`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryCampaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {string} tenantCode 
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1TenantCodeCampaignEntryPost_2: async (tenantCode: string, entryCampaign: EntryCampaign, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('campaignEntryApiV1TenantCodeCampaignEntryPost_2', 'tenantCode', tenantCode)
            // verify required parameter 'entryCampaign' is not null or undefined
            assertParamExists('campaignEntryApiV1TenantCodeCampaignEntryPost_2', 'entryCampaign', entryCampaign)
            const localVarPath = `/api/v1/{tenant_code}/campaign/entry`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryCampaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.assetsApiV1AssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.assetsApiV1TenantCodeAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignEntryApiV1CampaignEntryPost(entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignEntryApiV1CampaignEntryPost(entryCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignEntryApiV1CampaignEntryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignEntryApiV1CampaignEntryPost_1(entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignEntryApiV1CampaignEntryPost_1(entryCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignEntryApiV1CampaignEntryPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {string} tenantCode 
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignEntryApiV1TenantCodeCampaignEntryPost(tenantCode: string, entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignEntryApiV1TenantCodeCampaignEntryPost(tenantCode, entryCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignEntryApiV1TenantCodeCampaignEntryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {string} tenantCode 
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignEntryApiV1TenantCodeCampaignEntryPost_2(tenantCode: string, entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignEntryApiV1TenantCodeCampaignEntryPost_2(tenantCode, entryCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignEntryApiV1TenantCodeCampaignEntryPost_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1CampaignEntryPost(entryCampaign: EntryCampaign, options?: any): AxiosPromise<any> {
            return localVarFp.campaignEntryApiV1CampaignEntryPost(entryCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1CampaignEntryPost_1(entryCampaign: EntryCampaign, options?: any): AxiosPromise<any> {
            return localVarFp.campaignEntryApiV1CampaignEntryPost_1(entryCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {string} tenantCode 
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1TenantCodeCampaignEntryPost(tenantCode: string, entryCampaign: EntryCampaign, options?: any): AxiosPromise<any> {
            return localVarFp.campaignEntryApiV1TenantCodeCampaignEntryPost(tenantCode, entryCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン参加API
         * @summary Campaign Entry
         * @param {string} tenantCode 
         * @param {EntryCampaign} entryCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignEntryApiV1TenantCodeCampaignEntryPost_2(tenantCode: string, entryCampaign: EntryCampaign, options?: any): AxiosPromise<any> {
            return localVarFp.campaignEntryApiV1TenantCodeCampaignEntryPost_2(tenantCode, entryCampaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).assetsApiV1AssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン参加API
     * @summary Campaign Entry
     * @param {EntryCampaign} entryCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignEntryApiV1CampaignEntryPost(entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignEntryApiV1CampaignEntryPost(entryCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン参加API
     * @summary Campaign Entry
     * @param {EntryCampaign} entryCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignEntryApiV1CampaignEntryPost_1(entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignEntryApiV1CampaignEntryPost_1(entryCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン参加API
     * @summary Campaign Entry
     * @param {string} tenantCode 
     * @param {EntryCampaign} entryCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignEntryApiV1TenantCodeCampaignEntryPost(tenantCode: string, entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignEntryApiV1TenantCodeCampaignEntryPost(tenantCode, entryCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン参加API
     * @summary Campaign Entry
     * @param {string} tenantCode 
     * @param {EntryCampaign} entryCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignEntryApiV1TenantCodeCampaignEntryPost_2(tenantCode: string, entryCampaign: EntryCampaign, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignEntryApiV1TenantCodeCampaignEntryPost_2(tenantCode, entryCampaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IdPosApi - axios parameter creator
 * @export
 */
export const IdPosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 【ID-POS連携】キャンペーンリストAPI
         * @summary Campaign List
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignListApiV1TenantCodeIdPosCampaignListGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('campaignListApiV1TenantCodeIdPosCampaignListGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/id_pos/campaign/list`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 【ID-POS連携】キャンペーンリストAPI
         * @summary Campaign List
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignListApiV1TenantCodeIdPosCampaignListGet_1: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('campaignListApiV1TenantCodeIdPosCampaignListGet_1', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/id_pos/campaign/list`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 【ID-POS連携】キャンペーン参加メンバーリストAPI
         * @summary Campaign Members
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMembersApiV1TenantCodeIdPosCampaignMembersGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('campaignMembersApiV1TenantCodeIdPosCampaignMembersGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/id_pos/campaign/members`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 【ID-POS連携】キャンペーン参加メンバーリストAPI
         * @summary Campaign Members
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/id_pos/campaign/members`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdPosApi - functional programming interface
 * @export
 */
export const IdPosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdPosApiAxiosParamCreator(configuration)
    return {
        /**
         * 【ID-POS連携】キャンペーンリストAPI
         * @summary Campaign List
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignListApiV1TenantCodeIdPosCampaignListGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdPosCampaignList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignListApiV1TenantCodeIdPosCampaignListGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IdPosApi.campaignListApiV1TenantCodeIdPosCampaignListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 【ID-POS連携】キャンペーンリストAPI
         * @summary Campaign List
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignListApiV1TenantCodeIdPosCampaignListGet_1(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdPosCampaignList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignListApiV1TenantCodeIdPosCampaignListGet_1(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IdPosApi.campaignListApiV1TenantCodeIdPosCampaignListGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 【ID-POS連携】キャンペーン参加メンバーリストAPI
         * @summary Campaign Members
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignMembersApiV1TenantCodeIdPosCampaignMembersGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdPosCampaignMemberList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignMembersApiV1TenantCodeIdPosCampaignMembersGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IdPosApi.campaignMembersApiV1TenantCodeIdPosCampaignMembersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 【ID-POS連携】キャンペーン参加メンバーリストAPI
         * @summary Campaign Members
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdPosCampaignMemberList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IdPosApi.campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IdPosApi - factory interface
 * @export
 */
export const IdPosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdPosApiFp(configuration)
    return {
        /**
         * 【ID-POS連携】キャンペーンリストAPI
         * @summary Campaign List
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignListApiV1TenantCodeIdPosCampaignListGet(tenantCode: string, options?: any): AxiosPromise<IdPosCampaignList> {
            return localVarFp.campaignListApiV1TenantCodeIdPosCampaignListGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 【ID-POS連携】キャンペーンリストAPI
         * @summary Campaign List
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignListApiV1TenantCodeIdPosCampaignListGet_1(tenantCode: string, options?: any): AxiosPromise<IdPosCampaignList> {
            return localVarFp.campaignListApiV1TenantCodeIdPosCampaignListGet_1(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 【ID-POS連携】キャンペーン参加メンバーリストAPI
         * @summary Campaign Members
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMembersApiV1TenantCodeIdPosCampaignMembersGet(tenantCode: string, options?: any): AxiosPromise<IdPosCampaignMemberList> {
            return localVarFp.campaignMembersApiV1TenantCodeIdPosCampaignMembersGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 【ID-POS連携】キャンペーン参加メンバーリストAPI
         * @summary Campaign Members
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2(tenantCode: string, options?: any): AxiosPromise<IdPosCampaignMemberList> {
            return localVarFp.campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2(tenantCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdPosApi - object-oriented interface
 * @export
 * @class IdPosApi
 * @extends {BaseAPI}
 */
export class IdPosApi extends BaseAPI {
    /**
     * 【ID-POS連携】キャンペーンリストAPI
     * @summary Campaign List
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdPosApi
     */
    public campaignListApiV1TenantCodeIdPosCampaignListGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return IdPosApiFp(this.configuration).campaignListApiV1TenantCodeIdPosCampaignListGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 【ID-POS連携】キャンペーンリストAPI
     * @summary Campaign List
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdPosApi
     */
    public campaignListApiV1TenantCodeIdPosCampaignListGet_1(tenantCode: string, options?: RawAxiosRequestConfig) {
        return IdPosApiFp(this.configuration).campaignListApiV1TenantCodeIdPosCampaignListGet_1(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 【ID-POS連携】キャンペーン参加メンバーリストAPI
     * @summary Campaign Members
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdPosApi
     */
    public campaignMembersApiV1TenantCodeIdPosCampaignMembersGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return IdPosApiFp(this.configuration).campaignMembersApiV1TenantCodeIdPosCampaignMembersGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 【ID-POS連携】キャンペーン参加メンバーリストAPI
     * @summary Campaign Members
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdPosApi
     */
    public campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2(tenantCode: string, options?: RawAxiosRequestConfig) {
        return IdPosApiFp(this.configuration).campaignMembersApiV1TenantCodeIdPosCampaignMembersGet_2(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MemberNotificationsApi - axios parameter creator
 * @export
 */
export const MemberNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberNotificationsApi - functional programming interface
 * @export
 */
export const MemberNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberNotificationsApi.assetsApiV1AssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberNotificationsApi.assetsApiV1TenantCodeAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberNotificationsApi - factory interface
 * @export
 */
export const MemberNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberNotificationsApiFp(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberNotificationsApi - object-oriented interface
 * @export
 * @class MemberNotificationsApi
 * @extends {BaseAPI}
 */
export class MemberNotificationsApi extends BaseAPI {
    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberNotificationsApi
     */
    public assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return MemberNotificationsApiFp(this.configuration).assetsApiV1AssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberNotificationsApi
     */
    public assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return MemberNotificationsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MembersApi - axios parameter creator
 * @export
 */
export const MembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインAPI
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1MembersLoginPost: async (login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginApiV1MembersLoginPost', 'login', login)
            const localVarPath = `/api/v1/members/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1MembersLoginPost_1: async (login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginApiV1MembersLoginPost_1', 'login', login)
            const localVarPath = `/api/v1/members/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {string} tenantCode 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1TenantCodeMembersLoginPost: async (tenantCode: string, login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('loginApiV1TenantCodeMembersLoginPost', 'tenantCode', tenantCode)
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginApiV1TenantCodeMembersLoginPost', 'login', login)
            const localVarPath = `/api/v1/{tenant_code}/members/login`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {string} tenantCode 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1TenantCodeMembersLoginPost_2: async (tenantCode: string, login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('loginApiV1TenantCodeMembersLoginPost_2', 'tenantCode', tenantCode)
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginApiV1TenantCodeMembersLoginPost_2', 'login', login)
            const localVarPath = `/api/v1/{tenant_code}/members/login`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Member Delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1MembersDeleteGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/members/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Member Delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1MembersDeleteGet_3: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/members/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Member Delete
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1TenantCodeMembersDeleteGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('memberDeleteApiV1TenantCodeMembersDeleteGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/members/delete`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Member Delete
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1TenantCodeMembersDeleteGet_4: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('memberDeleteApiV1TenantCodeMembersDeleteGet_4', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/members/delete`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1MembersRegisterPost: async (createMember: CreateMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMember' is not null or undefined
            assertParamExists('memberRegisterApiV1MembersRegisterPost', 'createMember', createMember)
            const localVarPath = `/api/v1/members/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1MembersRegisterPost_5: async (createMember: CreateMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMember' is not null or undefined
            assertParamExists('memberRegisterApiV1MembersRegisterPost_5', 'createMember', createMember)
            const localVarPath = `/api/v1/members/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {string} tenantCode 
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1TenantCodeMembersRegisterPost: async (tenantCode: string, createMember: CreateMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('memberRegisterApiV1TenantCodeMembersRegisterPost', 'tenantCode', tenantCode)
            // verify required parameter 'createMember' is not null or undefined
            assertParamExists('memberRegisterApiV1TenantCodeMembersRegisterPost', 'createMember', createMember)
            const localVarPath = `/api/v1/{tenant_code}/members/register`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {string} tenantCode 
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1TenantCodeMembersRegisterPost_6: async (tenantCode: string, createMember: CreateMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('memberRegisterApiV1TenantCodeMembersRegisterPost_6', 'tenantCode', tenantCode)
            // verify required parameter 'createMember' is not null or undefined
            assertParamExists('memberRegisterApiV1TenantCodeMembersRegisterPost_6', 'createMember', createMember)
            const localVarPath = `/api/v1/{tenant_code}/members/register`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1MembersOauthConnectPost: async (connectOAuth: ConnectOAuth, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectOAuth' is not null or undefined
            assertParamExists('oauthConnectApiV1MembersOauthConnectPost', 'connectOAuth', connectOAuth)
            const localVarPath = `/api/v1/members/oauth/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectOAuth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1MembersOauthConnectPost_7: async (connectOAuth: ConnectOAuth, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectOAuth' is not null or undefined
            assertParamExists('oauthConnectApiV1MembersOauthConnectPost_7', 'connectOAuth', connectOAuth)
            const localVarPath = `/api/v1/members/oauth/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectOAuth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthConnectPost: async (tenantCode: string, connectOAuth: ConnectOAuth, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthConnectPost', 'tenantCode', tenantCode)
            // verify required parameter 'connectOAuth' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthConnectPost', 'connectOAuth', connectOAuth)
            const localVarPath = `/api/v1/{tenant_code}/members/oauth/connect`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectOAuth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthConnectPost_8: async (tenantCode: string, connectOAuth: ConnectOAuth, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthConnectPost_8', 'tenantCode', tenantCode)
            // verify required parameter 'connectOAuth' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthConnectPost_8', 'connectOAuth', connectOAuth)
            const localVarPath = `/api/v1/{tenant_code}/members/oauth/connect`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectOAuth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth会員番号API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost: async (tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost', 'tenantCode', tenantCode)
            // verify required parameter 'oAuthCodeForMemberCode' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost', 'oAuthCodeForMemberCode', oAuthCodeForMemberCode)
            const localVarPath = `/api/v1/{tenant_code}/oauth/member_code`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCodeForMemberCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth会員番号API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9: async (tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9', 'tenantCode', tenantCode)
            // verify required parameter 'oAuthCodeForMemberCode' is not null or undefined
            assertParamExists('oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9', 'oAuthCodeForMemberCode', oAuthCodeForMemberCode)
            const localVarPath = `/api/v1/{tenant_code}/oauth/member_code`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCodeForMemberCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password Reset
         * @param {SendPasswordReset} sendPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetApiV1MembersPasswordResetPost: async (sendPasswordReset: SendPasswordReset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPasswordReset' is not null or undefined
            assertParamExists('passwordResetApiV1MembersPasswordResetPost', 'sendPasswordReset', sendPasswordReset)
            const localVarPath = `/api/v1/members/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password Reset
         * @param {string} tenantCode 
         * @param {SendPasswordReset} sendPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetApiV1TenantCodeMembersPasswordResetPost: async (tenantCode: string, sendPasswordReset: SendPasswordReset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('passwordResetApiV1TenantCodeMembersPasswordResetPost', 'tenantCode', tenantCode)
            // verify required parameter 'sendPasswordReset' is not null or undefined
            assertParamExists('passwordResetApiV1TenantCodeMembersPasswordResetPost', 'sendPasswordReset', sendPasswordReset)
            const localVarPath = `/api/v1/{tenant_code}/members/password/reset`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Email
         * @param {string} tenantCode 
         * @param {SendVerificationEmail} sendVerificationEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailApiV1TenantCodeMembersVerificationEmailSendPost: async (tenantCode: string, sendVerificationEmail: SendVerificationEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('sendEmailApiV1TenantCodeMembersVerificationEmailSendPost', 'tenantCode', tenantCode)
            // verify required parameter 'sendVerificationEmail' is not null or undefined
            assertParamExists('sendEmailApiV1TenantCodeMembersVerificationEmailSendPost', 'sendVerificationEmail', sendVerificationEmail)
            const localVarPath = `/api/v1/{tenant_code}/members/verification_email/send`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendVerificationEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Password Reset
         * @param {SendPasswordResetEmail} sendPasswordResetEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetApiV1MembersPasswordResetSendPost: async (sendPasswordResetEmail: SendPasswordResetEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPasswordResetEmail' is not null or undefined
            assertParamExists('sendPasswordResetApiV1MembersPasswordResetSendPost', 'sendPasswordResetEmail', sendPasswordResetEmail)
            const localVarPath = `/api/v1/members/password_reset/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordResetEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Password Reset
         * @param {string} tenantCode 
         * @param {SendPasswordResetEmail} sendPasswordResetEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost: async (tenantCode: string, sendPasswordResetEmail: SendPasswordResetEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost', 'tenantCode', tenantCode)
            // verify required parameter 'sendPasswordResetEmail' is not null or undefined
            assertParamExists('sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost', 'sendPasswordResetEmail', sendPasswordResetEmail)
            const localVarPath = `/api/v1/{tenant_code}/members/password_reset/send`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordResetEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Member
         * @param {UpdateMember} updateMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberApiV1MembersUpdatePost: async (updateMember: UpdateMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMember' is not null or undefined
            assertParamExists('updateMemberApiV1MembersUpdatePost', 'updateMember', updateMember)
            const localVarPath = `/api/v1/members/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Member
         * @param {string} tenantCode 
         * @param {UpdateMember} updateMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberApiV1TenantCodeMembersUpdatePost: async (tenantCode: string, updateMember: UpdateMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('updateMemberApiV1TenantCodeMembersUpdatePost', 'tenantCode', tenantCode)
            // verify required parameter 'updateMember' is not null or undefined
            assertParamExists('updateMemberApiV1TenantCodeMembersUpdatePost', 'updateMember', updateMember)
            const localVarPath = `/api/v1/{tenant_code}/members/update`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1MembersMemberIdUpdatePost: async (changeMemberCode: ChangeMemberCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeMemberCode' is not null or undefined
            assertParamExists('updateMemberCodeApiV1MembersMemberIdUpdatePost', 'changeMemberCode', changeMemberCode)
            const localVarPath = `/api/v1/members/member_id/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeMemberCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1MembersMemberIdUpdatePost_10: async (changeMemberCode: ChangeMemberCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeMemberCode' is not null or undefined
            assertParamExists('updateMemberCodeApiV1MembersMemberIdUpdatePost_10', 'changeMemberCode', changeMemberCode)
            const localVarPath = `/api/v1/members/member_id/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeMemberCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {string} tenantCode 
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost: async (tenantCode: string, changeMemberCode: ChangeMemberCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost', 'tenantCode', tenantCode)
            // verify required parameter 'changeMemberCode' is not null or undefined
            assertParamExists('updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost', 'changeMemberCode', changeMemberCode)
            const localVarPath = `/api/v1/{tenant_code}/members/member_id/update`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeMemberCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {string} tenantCode 
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11: async (tenantCode: string, changeMemberCode: ChangeMemberCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11', 'tenantCode', tenantCode)
            // verify required parameter 'changeMemberCode' is not null or undefined
            assertParamExists('updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11', 'changeMemberCode', changeMemberCode)
            const localVarPath = `/api/v1/{tenant_code}/members/member_id/update`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeMemberCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembersApi - functional programming interface
 * @export
 */
export const MembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembersApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインAPI
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginApiV1MembersLoginPost(login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginApiV1MembersLoginPost(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.loginApiV1MembersLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginApiV1MembersLoginPost_1(login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginApiV1MembersLoginPost_1(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.loginApiV1MembersLoginPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {string} tenantCode 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginApiV1TenantCodeMembersLoginPost(tenantCode: string, login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginApiV1TenantCodeMembersLoginPost(tenantCode, login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.loginApiV1TenantCodeMembersLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {string} tenantCode 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginApiV1TenantCodeMembersLoginPost_2(tenantCode: string, login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginApiV1TenantCodeMembersLoginPost_2(tenantCode, login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.loginApiV1TenantCodeMembersLoginPost_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Member Delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberDeleteApiV1MembersDeleteGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberDeleteApiV1MembersDeleteGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberDeleteApiV1MembersDeleteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Member Delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberDeleteApiV1MembersDeleteGet_3(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberDeleteApiV1MembersDeleteGet_3(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberDeleteApiV1MembersDeleteGet_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Member Delete
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberDeleteApiV1TenantCodeMembersDeleteGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberDeleteApiV1TenantCodeMembersDeleteGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberDeleteApiV1TenantCodeMembersDeleteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Member Delete
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberDeleteApiV1TenantCodeMembersDeleteGet_4(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberDeleteApiV1TenantCodeMembersDeleteGet_4(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberDeleteApiV1TenantCodeMembersDeleteGet_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRegisterApiV1MembersRegisterPost(createMember: CreateMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRegisterApiV1MembersRegisterPost(createMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberRegisterApiV1MembersRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRegisterApiV1MembersRegisterPost_5(createMember: CreateMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRegisterApiV1MembersRegisterPost_5(createMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberRegisterApiV1MembersRegisterPost_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {string} tenantCode 
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRegisterApiV1TenantCodeMembersRegisterPost(tenantCode: string, createMember: CreateMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRegisterApiV1TenantCodeMembersRegisterPost(tenantCode, createMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberRegisterApiV1TenantCodeMembersRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {string} tenantCode 
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRegisterApiV1TenantCodeMembersRegisterPost_6(tenantCode: string, createMember: CreateMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRegisterApiV1TenantCodeMembersRegisterPost_6(tenantCode, createMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.memberRegisterApiV1TenantCodeMembersRegisterPost_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthConnectApiV1MembersOauthConnectPost(connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthConnectApiV1MembersOauthConnectPost(connectOAuth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.oauthConnectApiV1MembersOauthConnectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthConnectApiV1MembersOauthConnectPost_7(connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthConnectApiV1MembersOauthConnectPost_7(connectOAuth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.oauthConnectApiV1MembersOauthConnectPost_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthConnectApiV1TenantCodeMembersOauthConnectPost(tenantCode: string, connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthConnectApiV1TenantCodeMembersOauthConnectPost(tenantCode, connectOAuth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.oauthConnectApiV1TenantCodeMembersOauthConnectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthConnectApiV1TenantCodeMembersOauthConnectPost_8(tenantCode: string, connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthConnectApiV1TenantCodeMembersOauthConnectPost_8(tenantCode, connectOAuth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.oauthConnectApiV1TenantCodeMembersOauthConnectPost_8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth会員番号API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(tenantCode, oAuthCodeForMemberCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth会員番号API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9(tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9(tenantCode, oAuthCodeForMemberCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Password Reset
         * @param {SendPasswordReset} sendPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetApiV1MembersPasswordResetPost(sendPasswordReset: SendPasswordReset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetApiV1MembersPasswordResetPost(sendPasswordReset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.passwordResetApiV1MembersPasswordResetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Password Reset
         * @param {string} tenantCode 
         * @param {SendPasswordReset} sendPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetApiV1TenantCodeMembersPasswordResetPost(tenantCode: string, sendPasswordReset: SendPasswordReset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetApiV1TenantCodeMembersPasswordResetPost(tenantCode, sendPasswordReset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.passwordResetApiV1TenantCodeMembersPasswordResetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Email
         * @param {string} tenantCode 
         * @param {SendVerificationEmail} sendVerificationEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenantCode: string, sendVerificationEmail: SendVerificationEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenantCode, sendVerificationEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.sendEmailApiV1TenantCodeMembersVerificationEmailSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Password Reset
         * @param {SendPasswordResetEmail} sendPasswordResetEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordResetApiV1MembersPasswordResetSendPost(sendPasswordResetEmail: SendPasswordResetEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPasswordResetApiV1MembersPasswordResetSendPost(sendPasswordResetEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.sendPasswordResetApiV1MembersPasswordResetSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Password Reset
         * @param {string} tenantCode 
         * @param {SendPasswordResetEmail} sendPasswordResetEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenantCode: string, sendPasswordResetEmail: SendPasswordResetEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenantCode, sendPasswordResetEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Member
         * @param {UpdateMember} updateMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberApiV1MembersUpdatePost(updateMember: UpdateMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberApiV1MembersUpdatePost(updateMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.updateMemberApiV1MembersUpdatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Member
         * @param {string} tenantCode 
         * @param {UpdateMember} updateMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberApiV1TenantCodeMembersUpdatePost(tenantCode: string, updateMember: UpdateMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberApiV1TenantCodeMembersUpdatePost(tenantCode, updateMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.updateMemberApiV1TenantCodeMembersUpdatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberCodeApiV1MembersMemberIdUpdatePost(changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberCodeApiV1MembersMemberIdUpdatePost(changeMemberCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.updateMemberCodeApiV1MembersMemberIdUpdatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberCodeApiV1MembersMemberIdUpdatePost_10(changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberCodeApiV1MembersMemberIdUpdatePost_10(changeMemberCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.updateMemberCodeApiV1MembersMemberIdUpdatePost_10']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {string} tenantCode 
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost(tenantCode: string, changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost(tenantCode, changeMemberCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {string} tenantCode 
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11(tenantCode: string, changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11(tenantCode, changeMemberCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembersApi.updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MembersApi - factory interface
 * @export
 */
export const MembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembersApiFp(configuration)
    return {
        /**
         * ログインAPI
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1MembersLoginPost(login: Login, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.loginApiV1MembersLoginPost(login, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1MembersLoginPost_1(login: Login, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.loginApiV1MembersLoginPost_1(login, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {string} tenantCode 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1TenantCodeMembersLoginPost(tenantCode: string, login: Login, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.loginApiV1TenantCodeMembersLoginPost(tenantCode, login, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインAPI
         * @summary Login
         * @param {string} tenantCode 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiV1TenantCodeMembersLoginPost_2(tenantCode: string, login: Login, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.loginApiV1TenantCodeMembersLoginPost_2(tenantCode, login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Member Delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1MembersDeleteGet(options?: any): AxiosPromise<any> {
            return localVarFp.memberDeleteApiV1MembersDeleteGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Member Delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1MembersDeleteGet_3(options?: any): AxiosPromise<any> {
            return localVarFp.memberDeleteApiV1MembersDeleteGet_3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Member Delete
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1TenantCodeMembersDeleteGet(tenantCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.memberDeleteApiV1TenantCodeMembersDeleteGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Member Delete
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberDeleteApiV1TenantCodeMembersDeleteGet_4(tenantCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.memberDeleteApiV1TenantCodeMembersDeleteGet_4(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1MembersRegisterPost(createMember: CreateMember, options?: any): AxiosPromise<Member> {
            return localVarFp.memberRegisterApiV1MembersRegisterPost(createMember, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1MembersRegisterPost_5(createMember: CreateMember, options?: any): AxiosPromise<Member> {
            return localVarFp.memberRegisterApiV1MembersRegisterPost_5(createMember, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {string} tenantCode 
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1TenantCodeMembersRegisterPost(tenantCode: string, createMember: CreateMember, options?: any): AxiosPromise<Member> {
            return localVarFp.memberRegisterApiV1TenantCodeMembersRegisterPost(tenantCode, createMember, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー情報登録API
         * @summary Member Register
         * @param {string} tenantCode 
         * @param {CreateMember} createMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterApiV1TenantCodeMembersRegisterPost_6(tenantCode: string, createMember: CreateMember, options?: any): AxiosPromise<Member> {
            return localVarFp.memberRegisterApiV1TenantCodeMembersRegisterPost_6(tenantCode, createMember, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1MembersOauthConnectPost(connectOAuth: ConnectOAuth, options?: any): AxiosPromise<any> {
            return localVarFp.oauthConnectApiV1MembersOauthConnectPost(connectOAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1MembersOauthConnectPost_7(connectOAuth: ConnectOAuth, options?: any): AxiosPromise<any> {
            return localVarFp.oauthConnectApiV1MembersOauthConnectPost_7(connectOAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthConnectPost(tenantCode: string, connectOAuth: ConnectOAuth, options?: any): AxiosPromise<any> {
            return localVarFp.oauthConnectApiV1TenantCodeMembersOauthConnectPost(tenantCode, connectOAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth連携API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {ConnectOAuth} connectOAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthConnectPost_8(tenantCode: string, connectOAuth: ConnectOAuth, options?: any): AxiosPromise<any> {
            return localVarFp.oauthConnectApiV1TenantCodeMembersOauthConnectPost_8(tenantCode, connectOAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth会員番号API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options?: any): AxiosPromise<any> {
            return localVarFp.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(tenantCode, oAuthCodeForMemberCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth会員番号API
         * @summary Oauth Connect
         * @param {string} tenantCode 
         * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9(tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options?: any): AxiosPromise<any> {
            return localVarFp.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9(tenantCode, oAuthCodeForMemberCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password Reset
         * @param {SendPasswordReset} sendPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetApiV1MembersPasswordResetPost(sendPasswordReset: SendPasswordReset, options?: any): AxiosPromise<any> {
            return localVarFp.passwordResetApiV1MembersPasswordResetPost(sendPasswordReset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password Reset
         * @param {string} tenantCode 
         * @param {SendPasswordReset} sendPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetApiV1TenantCodeMembersPasswordResetPost(tenantCode: string, sendPasswordReset: SendPasswordReset, options?: any): AxiosPromise<any> {
            return localVarFp.passwordResetApiV1TenantCodeMembersPasswordResetPost(tenantCode, sendPasswordReset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Email
         * @param {string} tenantCode 
         * @param {SendVerificationEmail} sendVerificationEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenantCode: string, sendVerificationEmail: SendVerificationEmail, options?: any): AxiosPromise<any> {
            return localVarFp.sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenantCode, sendVerificationEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Password Reset
         * @param {SendPasswordResetEmail} sendPasswordResetEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetApiV1MembersPasswordResetSendPost(sendPasswordResetEmail: SendPasswordResetEmail, options?: any): AxiosPromise<any> {
            return localVarFp.sendPasswordResetApiV1MembersPasswordResetSendPost(sendPasswordResetEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Password Reset
         * @param {string} tenantCode 
         * @param {SendPasswordResetEmail} sendPasswordResetEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenantCode: string, sendPasswordResetEmail: SendPasswordResetEmail, options?: any): AxiosPromise<any> {
            return localVarFp.sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenantCode, sendPasswordResetEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Member
         * @param {UpdateMember} updateMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberApiV1MembersUpdatePost(updateMember: UpdateMember, options?: any): AxiosPromise<any> {
            return localVarFp.updateMemberApiV1MembersUpdatePost(updateMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Member
         * @param {string} tenantCode 
         * @param {UpdateMember} updateMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberApiV1TenantCodeMembersUpdatePost(tenantCode: string, updateMember: UpdateMember, options?: any): AxiosPromise<any> {
            return localVarFp.updateMemberApiV1TenantCodeMembersUpdatePost(tenantCode, updateMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1MembersMemberIdUpdatePost(changeMemberCode: ChangeMemberCode, options?: any): AxiosPromise<Member> {
            return localVarFp.updateMemberCodeApiV1MembersMemberIdUpdatePost(changeMemberCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1MembersMemberIdUpdatePost_10(changeMemberCode: ChangeMemberCode, options?: any): AxiosPromise<Member> {
            return localVarFp.updateMemberCodeApiV1MembersMemberIdUpdatePost_10(changeMemberCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {string} tenantCode 
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost(tenantCode: string, changeMemberCode: ChangeMemberCode, options?: any): AxiosPromise<Member> {
            return localVarFp.updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost(tenantCode, changeMemberCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員番号 (member_id) を更新PI
         * @summary Update Member Code
         * @param {string} tenantCode 
         * @param {ChangeMemberCode} changeMemberCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11(tenantCode: string, changeMemberCode: ChangeMemberCode, options?: any): AxiosPromise<Member> {
            return localVarFp.updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11(tenantCode, changeMemberCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembersApi - object-oriented interface
 * @export
 * @class MembersApi
 * @extends {BaseAPI}
 */
export class MembersApi extends BaseAPI {
    /**
     * ログインAPI
     * @summary Login
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public loginApiV1MembersLoginPost(login: Login, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).loginApiV1MembersLoginPost(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインAPI
     * @summary Login
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public loginApiV1MembersLoginPost_1(login: Login, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).loginApiV1MembersLoginPost_1(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインAPI
     * @summary Login
     * @param {string} tenantCode 
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public loginApiV1TenantCodeMembersLoginPost(tenantCode: string, login: Login, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).loginApiV1TenantCodeMembersLoginPost(tenantCode, login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインAPI
     * @summary Login
     * @param {string} tenantCode 
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public loginApiV1TenantCodeMembersLoginPost_2(tenantCode: string, login: Login, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).loginApiV1TenantCodeMembersLoginPost_2(tenantCode, login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Member Delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberDeleteApiV1MembersDeleteGet(options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberDeleteApiV1MembersDeleteGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Member Delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberDeleteApiV1MembersDeleteGet_3(options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberDeleteApiV1MembersDeleteGet_3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Member Delete
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberDeleteApiV1TenantCodeMembersDeleteGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberDeleteApiV1TenantCodeMembersDeleteGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Member Delete
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberDeleteApiV1TenantCodeMembersDeleteGet_4(tenantCode: string, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberDeleteApiV1TenantCodeMembersDeleteGet_4(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー情報登録API
     * @summary Member Register
     * @param {CreateMember} createMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberRegisterApiV1MembersRegisterPost(createMember: CreateMember, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberRegisterApiV1MembersRegisterPost(createMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー情報登録API
     * @summary Member Register
     * @param {CreateMember} createMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberRegisterApiV1MembersRegisterPost_5(createMember: CreateMember, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberRegisterApiV1MembersRegisterPost_5(createMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー情報登録API
     * @summary Member Register
     * @param {string} tenantCode 
     * @param {CreateMember} createMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberRegisterApiV1TenantCodeMembersRegisterPost(tenantCode: string, createMember: CreateMember, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberRegisterApiV1TenantCodeMembersRegisterPost(tenantCode, createMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー情報登録API
     * @summary Member Register
     * @param {string} tenantCode 
     * @param {CreateMember} createMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public memberRegisterApiV1TenantCodeMembersRegisterPost_6(tenantCode: string, createMember: CreateMember, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).memberRegisterApiV1TenantCodeMembersRegisterPost_6(tenantCode, createMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth連携API
     * @summary Oauth Connect
     * @param {ConnectOAuth} connectOAuth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public oauthConnectApiV1MembersOauthConnectPost(connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).oauthConnectApiV1MembersOauthConnectPost(connectOAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth連携API
     * @summary Oauth Connect
     * @param {ConnectOAuth} connectOAuth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public oauthConnectApiV1MembersOauthConnectPost_7(connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).oauthConnectApiV1MembersOauthConnectPost_7(connectOAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth連携API
     * @summary Oauth Connect
     * @param {string} tenantCode 
     * @param {ConnectOAuth} connectOAuth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public oauthConnectApiV1TenantCodeMembersOauthConnectPost(tenantCode: string, connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).oauthConnectApiV1TenantCodeMembersOauthConnectPost(tenantCode, connectOAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth連携API
     * @summary Oauth Connect
     * @param {string} tenantCode 
     * @param {ConnectOAuth} connectOAuth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public oauthConnectApiV1TenantCodeMembersOauthConnectPost_8(tenantCode: string, connectOAuth: ConnectOAuth, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).oauthConnectApiV1TenantCodeMembersOauthConnectPost_8(tenantCode, connectOAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth会員番号API
     * @summary Oauth Connect
     * @param {string} tenantCode 
     * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(tenantCode, oAuthCodeForMemberCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth会員番号API
     * @summary Oauth Connect
     * @param {string} tenantCode 
     * @param {OAuthCodeForMemberCode} oAuthCodeForMemberCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9(tenantCode: string, oAuthCodeForMemberCode: OAuthCodeForMemberCode, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost_9(tenantCode, oAuthCodeForMemberCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password Reset
     * @param {SendPasswordReset} sendPasswordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public passwordResetApiV1MembersPasswordResetPost(sendPasswordReset: SendPasswordReset, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).passwordResetApiV1MembersPasswordResetPost(sendPasswordReset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password Reset
     * @param {string} tenantCode 
     * @param {SendPasswordReset} sendPasswordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public passwordResetApiV1TenantCodeMembersPasswordResetPost(tenantCode: string, sendPasswordReset: SendPasswordReset, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).passwordResetApiV1TenantCodeMembersPasswordResetPost(tenantCode, sendPasswordReset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Email
     * @param {string} tenantCode 
     * @param {SendVerificationEmail} sendVerificationEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenantCode: string, sendVerificationEmail: SendVerificationEmail, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenantCode, sendVerificationEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Password Reset
     * @param {SendPasswordResetEmail} sendPasswordResetEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public sendPasswordResetApiV1MembersPasswordResetSendPost(sendPasswordResetEmail: SendPasswordResetEmail, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).sendPasswordResetApiV1MembersPasswordResetSendPost(sendPasswordResetEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Password Reset
     * @param {string} tenantCode 
     * @param {SendPasswordResetEmail} sendPasswordResetEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenantCode: string, sendPasswordResetEmail: SendPasswordResetEmail, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenantCode, sendPasswordResetEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Member
     * @param {UpdateMember} updateMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberApiV1MembersUpdatePost(updateMember: UpdateMember, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberApiV1MembersUpdatePost(updateMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Member
     * @param {string} tenantCode 
     * @param {UpdateMember} updateMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberApiV1TenantCodeMembersUpdatePost(tenantCode: string, updateMember: UpdateMember, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberApiV1TenantCodeMembersUpdatePost(tenantCode, updateMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員番号 (member_id) を更新PI
     * @summary Update Member Code
     * @param {ChangeMemberCode} changeMemberCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberCodeApiV1MembersMemberIdUpdatePost(changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberCodeApiV1MembersMemberIdUpdatePost(changeMemberCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員番号 (member_id) を更新PI
     * @summary Update Member Code
     * @param {ChangeMemberCode} changeMemberCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberCodeApiV1MembersMemberIdUpdatePost_10(changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberCodeApiV1MembersMemberIdUpdatePost_10(changeMemberCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員番号 (member_id) を更新PI
     * @summary Update Member Code
     * @param {string} tenantCode 
     * @param {ChangeMemberCode} changeMemberCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost(tenantCode: string, changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost(tenantCode, changeMemberCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員番号 (member_id) を更新PI
     * @summary Update Member Code
     * @param {string} tenantCode 
     * @param {ChangeMemberCode} changeMemberCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11(tenantCode: string, changeMemberCode: ChangeMemberCode, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberCodeApiV1TenantCodeMembersMemberIdUpdatePost_11(tenantCode, changeMemberCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.assetsApiV1AssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.assetsApiV1TenantCodeAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).assetsApiV1AssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1OauthInfoPost: async (oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthInfoApiV1OauthInfoPost', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/oauth/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1OauthInfoPost_1: async (oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthInfoApiV1OauthInfoPost_1', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/oauth/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1TenantCodeOauthInfoPost: async (tenantCode: string, oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthInfoApiV1TenantCodeOauthInfoPost', 'tenantCode', tenantCode)
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthInfoApiV1TenantCodeOauthInfoPost', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/{tenant_code}/oauth/info`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1TenantCodeOauthInfoPost_2: async (tenantCode: string, oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthInfoApiV1TenantCodeOauthInfoPost_2', 'tenantCode', tenantCode)
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthInfoApiV1TenantCodeOauthInfoPost_2', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/{tenant_code}/oauth/info`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1OauthLoginPost: async (oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthLoginApiV1OauthLoginPost', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/oauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1OauthLoginPost_3: async (oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthLoginApiV1OauthLoginPost_3', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/oauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1TenantCodeOauthLoginPost: async (tenantCode: string, oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthLoginApiV1TenantCodeOauthLoginPost', 'tenantCode', tenantCode)
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthLoginApiV1TenantCodeOauthLoginPost', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/{tenant_code}/oauth/login`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1TenantCodeOauthLoginPost_4: async (tenantCode: string, oAuthCode: OAuthCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthLoginApiV1TenantCodeOauthLoginPost_4', 'tenantCode', tenantCode)
            // verify required parameter 'oAuthCode' is not null or undefined
            assertParamExists('oauthLoginApiV1TenantCodeOauthLoginPost_4', 'oAuthCode', oAuthCode)
            const localVarPath = `/api/v1/{tenant_code}/oauth/login`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1OauthUrlPost: async (getOAuthURL: GetOAuthURL, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOAuthURL' is not null or undefined
            assertParamExists('oauthUrlApiV1OauthUrlPost', 'getOAuthURL', getOAuthURL)
            const localVarPath = `/api/v1/oauth/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOAuthURL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1OauthUrlPost_5: async (getOAuthURL: GetOAuthURL, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOAuthURL' is not null or undefined
            assertParamExists('oauthUrlApiV1OauthUrlPost_5', 'getOAuthURL', getOAuthURL)
            const localVarPath = `/api/v1/oauth/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOAuthURL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {string} tenantCode 
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1TenantCodeOauthUrlPost: async (tenantCode: string, getOAuthURL: GetOAuthURL, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthUrlApiV1TenantCodeOauthUrlPost', 'tenantCode', tenantCode)
            // verify required parameter 'getOAuthURL' is not null or undefined
            assertParamExists('oauthUrlApiV1TenantCodeOauthUrlPost', 'getOAuthURL', getOAuthURL)
            const localVarPath = `/api/v1/{tenant_code}/oauth/url`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOAuthURL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {string} tenantCode 
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1TenantCodeOauthUrlPost_6: async (tenantCode: string, getOAuthURL: GetOAuthURL, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('oauthUrlApiV1TenantCodeOauthUrlPost_6', 'tenantCode', tenantCode)
            // verify required parameter 'getOAuthURL' is not null or undefined
            assertParamExists('oauthUrlApiV1TenantCodeOauthUrlPost_6', 'getOAuthURL', getOAuthURL)
            const localVarPath = `/api/v1/{tenant_code}/oauth/url`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOAuthURL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthInfoApiV1OauthInfoPost(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthInfoApiV1OauthInfoPost(oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthInfoApiV1OauthInfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthInfoApiV1OauthInfoPost_1(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthInfoApiV1OauthInfoPost_1(oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthInfoApiV1OauthInfoPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthInfoApiV1TenantCodeOauthInfoPost(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthInfoApiV1TenantCodeOauthInfoPost(tenantCode, oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthInfoApiV1TenantCodeOauthInfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthInfoApiV1TenantCodeOauthInfoPost_2(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthInfoApiV1TenantCodeOauthInfoPost_2(tenantCode, oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthInfoApiV1TenantCodeOauthInfoPost_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthLoginApiV1OauthLoginPost(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthLoginApiV1OauthLoginPost(oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthLoginApiV1OauthLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthLoginApiV1OauthLoginPost_3(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthLoginApiV1OauthLoginPost_3(oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthLoginApiV1OauthLoginPost_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthLoginApiV1TenantCodeOauthLoginPost(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthLoginApiV1TenantCodeOauthLoginPost(tenantCode, oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthLoginApiV1TenantCodeOauthLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthLoginApiV1TenantCodeOauthLoginPost_4(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthLoginApiV1TenantCodeOauthLoginPost_4(tenantCode, oAuthCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthLoginApiV1TenantCodeOauthLoginPost_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthUrlApiV1OauthUrlPost(getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthUrlApiV1OauthUrlPost(getOAuthURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthUrlApiV1OauthUrlPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthUrlApiV1OauthUrlPost_5(getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthUrlApiV1OauthUrlPost_5(getOAuthURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthUrlApiV1OauthUrlPost_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {string} tenantCode 
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthUrlApiV1TenantCodeOauthUrlPost(tenantCode: string, getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthUrlApiV1TenantCodeOauthUrlPost(tenantCode, getOAuthURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthUrlApiV1TenantCodeOauthUrlPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {string} tenantCode 
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthUrlApiV1TenantCodeOauthUrlPost_6(tenantCode: string, getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthUrlApiV1TenantCodeOauthUrlPost_6(tenantCode, getOAuthURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.oauthUrlApiV1TenantCodeOauthUrlPost_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1OauthInfoPost(oAuthCode: OAuthCode, options?: any): AxiosPromise<OAuthInfo> {
            return localVarFp.oauthInfoApiV1OauthInfoPost(oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1OauthInfoPost_1(oAuthCode: OAuthCode, options?: any): AxiosPromise<OAuthInfo> {
            return localVarFp.oauthInfoApiV1OauthInfoPost_1(oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1TenantCodeOauthInfoPost(tenantCode: string, oAuthCode: OAuthCode, options?: any): AxiosPromise<OAuthInfo> {
            return localVarFp.oauthInfoApiV1TenantCodeOauthInfoPost(tenantCode, oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthユーザー取得API
         * @summary Oauth Info
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthInfoApiV1TenantCodeOauthInfoPost_2(tenantCode: string, oAuthCode: OAuthCode, options?: any): AxiosPromise<OAuthInfo> {
            return localVarFp.oauthInfoApiV1TenantCodeOauthInfoPost_2(tenantCode, oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1OauthLoginPost(oAuthCode: OAuthCode, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.oauthLoginApiV1OauthLoginPost(oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1OauthLoginPost_3(oAuthCode: OAuthCode, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.oauthLoginApiV1OauthLoginPost_3(oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1TenantCodeOauthLoginPost(tenantCode: string, oAuthCode: OAuthCode, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.oauthLoginApiV1TenantCodeOauthLoginPost(tenantCode, oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuthログインAPI
         * @summary Oauth Login
         * @param {string} tenantCode 
         * @param {OAuthCode} oAuthCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLoginApiV1TenantCodeOauthLoginPost_4(tenantCode: string, oAuthCode: OAuthCode, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.oauthLoginApiV1TenantCodeOauthLoginPost_4(tenantCode, oAuthCode, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1OauthUrlPost(getOAuthURL: GetOAuthURL, options?: any): AxiosPromise<OAuthURL> {
            return localVarFp.oauthUrlApiV1OauthUrlPost(getOAuthURL, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1OauthUrlPost_5(getOAuthURL: GetOAuthURL, options?: any): AxiosPromise<OAuthURL> {
            return localVarFp.oauthUrlApiV1OauthUrlPost_5(getOAuthURL, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {string} tenantCode 
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1TenantCodeOauthUrlPost(tenantCode: string, getOAuthURL: GetOAuthURL, options?: any): AxiosPromise<OAuthURL> {
            return localVarFp.oauthUrlApiV1TenantCodeOauthUrlPost(tenantCode, getOAuthURL, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth用のURLを取得API
         * @summary Oauth Url
         * @param {string} tenantCode 
         * @param {GetOAuthURL} getOAuthURL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthUrlApiV1TenantCodeOauthUrlPost_6(tenantCode: string, getOAuthURL: GetOAuthURL, options?: any): AxiosPromise<OAuthURL> {
            return localVarFp.oauthUrlApiV1TenantCodeOauthUrlPost_6(tenantCode, getOAuthURL, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * OAuthユーザー取得API
     * @summary Oauth Info
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthInfoApiV1OauthInfoPost(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthInfoApiV1OauthInfoPost(oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthユーザー取得API
     * @summary Oauth Info
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthInfoApiV1OauthInfoPost_1(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthInfoApiV1OauthInfoPost_1(oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthユーザー取得API
     * @summary Oauth Info
     * @param {string} tenantCode 
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthInfoApiV1TenantCodeOauthInfoPost(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthInfoApiV1TenantCodeOauthInfoPost(tenantCode, oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthユーザー取得API
     * @summary Oauth Info
     * @param {string} tenantCode 
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthInfoApiV1TenantCodeOauthInfoPost_2(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthInfoApiV1TenantCodeOauthInfoPost_2(tenantCode, oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthログインAPI
     * @summary Oauth Login
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthLoginApiV1OauthLoginPost(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthLoginApiV1OauthLoginPost(oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthログインAPI
     * @summary Oauth Login
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthLoginApiV1OauthLoginPost_3(oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthLoginApiV1OauthLoginPost_3(oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthログインAPI
     * @summary Oauth Login
     * @param {string} tenantCode 
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthLoginApiV1TenantCodeOauthLoginPost(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthLoginApiV1TenantCodeOauthLoginPost(tenantCode, oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuthログインAPI
     * @summary Oauth Login
     * @param {string} tenantCode 
     * @param {OAuthCode} oAuthCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthLoginApiV1TenantCodeOauthLoginPost_4(tenantCode: string, oAuthCode: OAuthCode, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthLoginApiV1TenantCodeOauthLoginPost_4(tenantCode, oAuthCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth用のURLを取得API
     * @summary Oauth Url
     * @param {GetOAuthURL} getOAuthURL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthUrlApiV1OauthUrlPost(getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthUrlApiV1OauthUrlPost(getOAuthURL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth用のURLを取得API
     * @summary Oauth Url
     * @param {GetOAuthURL} getOAuthURL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthUrlApiV1OauthUrlPost_5(getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthUrlApiV1OauthUrlPost_5(getOAuthURL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth用のURLを取得API
     * @summary Oauth Url
     * @param {string} tenantCode 
     * @param {GetOAuthURL} getOAuthURL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthUrlApiV1TenantCodeOauthUrlPost(tenantCode: string, getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthUrlApiV1TenantCodeOauthUrlPost(tenantCode, getOAuthURL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth用のURLを取得API
     * @summary Oauth Url
     * @param {string} tenantCode 
     * @param {GetOAuthURL} getOAuthURL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthUrlApiV1TenantCodeOauthUrlPost_6(tenantCode: string, getOAuthURL: GetOAuthURL, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthUrlApiV1TenantCodeOauthUrlPost_6(tenantCode, getOAuthURL, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PointApi - axios parameter creator
 * @export
 */
export const PointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1PointExchangePost: async (pointExchange: PointExchange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointExchange' is not null or undefined
            assertParamExists('pointExchangeApiV1PointExchangePost', 'pointExchange', pointExchange)
            const localVarPath = `/api/v1/point/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointExchange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1PointExchangePost_1: async (pointExchange: PointExchange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointExchange' is not null or undefined
            assertParamExists('pointExchangeApiV1PointExchangePost_1', 'pointExchange', pointExchange)
            const localVarPath = `/api/v1/point/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointExchange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {string} tenantCode 
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1TenantCodePointExchangePost: async (tenantCode: string, pointExchange: PointExchange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('pointExchangeApiV1TenantCodePointExchangePost', 'tenantCode', tenantCode)
            // verify required parameter 'pointExchange' is not null or undefined
            assertParamExists('pointExchangeApiV1TenantCodePointExchangePost', 'pointExchange', pointExchange)
            const localVarPath = `/api/v1/{tenant_code}/point/exchange`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointExchange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {string} tenantCode 
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1TenantCodePointExchangePost_2: async (tenantCode: string, pointExchange: PointExchange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('pointExchangeApiV1TenantCodePointExchangePost_2', 'tenantCode', tenantCode)
            // verify required parameter 'pointExchange' is not null or undefined
            assertParamExists('pointExchangeApiV1TenantCodePointExchangePost_2', 'pointExchange', pointExchange)
            const localVarPath = `/api/v1/{tenant_code}/point/exchange`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointExchange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointApi - functional programming interface
 * @export
 */
export const PointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointApiAxiosParamCreator(configuration)
    return {
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointExchangeApiV1PointExchangePost(pointExchange: PointExchange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointExchangeApiV1PointExchangePost(pointExchange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointApi.pointExchangeApiV1PointExchangePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointExchangeApiV1PointExchangePost_1(pointExchange: PointExchange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointExchangeApiV1PointExchangePost_1(pointExchange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointApi.pointExchangeApiV1PointExchangePost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {string} tenantCode 
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointExchangeApiV1TenantCodePointExchangePost(tenantCode: string, pointExchange: PointExchange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointExchangeApiV1TenantCodePointExchangePost(tenantCode, pointExchange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointApi.pointExchangeApiV1TenantCodePointExchangePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {string} tenantCode 
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointExchangeApiV1TenantCodePointExchangePost_2(tenantCode: string, pointExchange: PointExchange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointExchangeApiV1TenantCodePointExchangePost_2(tenantCode, pointExchange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointApi.pointExchangeApiV1TenantCodePointExchangePost_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PointApi - factory interface
 * @export
 */
export const PointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointApiFp(configuration)
    return {
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1PointExchangePost(pointExchange: PointExchange, options?: any): AxiosPromise<any> {
            return localVarFp.pointExchangeApiV1PointExchangePost(pointExchange, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1PointExchangePost_1(pointExchange: PointExchange, options?: any): AxiosPromise<any> {
            return localVarFp.pointExchangeApiV1PointExchangePost_1(pointExchange, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {string} tenantCode 
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1TenantCodePointExchangePost(tenantCode: string, pointExchange: PointExchange, options?: any): AxiosPromise<any> {
            return localVarFp.pointExchangeApiV1TenantCodePointExchangePost(tenantCode, pointExchange, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント変換API
         * @summary Point Exchange
         * @param {string} tenantCode 
         * @param {PointExchange} pointExchange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointExchangeApiV1TenantCodePointExchangePost_2(tenantCode: string, pointExchange: PointExchange, options?: any): AxiosPromise<any> {
            return localVarFp.pointExchangeApiV1TenantCodePointExchangePost_2(tenantCode, pointExchange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointApi - object-oriented interface
 * @export
 * @class PointApi
 * @extends {BaseAPI}
 */
export class PointApi extends BaseAPI {
    /**
     * ポイント変換API
     * @summary Point Exchange
     * @param {PointExchange} pointExchange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointExchangeApiV1PointExchangePost(pointExchange: PointExchange, options?: RawAxiosRequestConfig) {
        return PointApiFp(this.configuration).pointExchangeApiV1PointExchangePost(pointExchange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント変換API
     * @summary Point Exchange
     * @param {PointExchange} pointExchange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointExchangeApiV1PointExchangePost_1(pointExchange: PointExchange, options?: RawAxiosRequestConfig) {
        return PointApiFp(this.configuration).pointExchangeApiV1PointExchangePost_1(pointExchange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント変換API
     * @summary Point Exchange
     * @param {string} tenantCode 
     * @param {PointExchange} pointExchange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointExchangeApiV1TenantCodePointExchangePost(tenantCode: string, pointExchange: PointExchange, options?: RawAxiosRequestConfig) {
        return PointApiFp(this.configuration).pointExchangeApiV1TenantCodePointExchangePost(tenantCode, pointExchange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント変換API
     * @summary Point Exchange
     * @param {string} tenantCode 
     * @param {PointExchange} pointExchange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointExchangeApiV1TenantCodePointExchangePost_2(tenantCode: string, pointExchange: PointExchange, options?: RawAxiosRequestConfig) {
        return PointApiFp(this.configuration).pointExchangeApiV1TenantCodePointExchangePost_2(tenantCode, pointExchange, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RewardsApi - axios parameter creator
 * @export
 */
export const RewardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1LotteryDrawPost: async (lotteryDraw: LotteryDraw, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotteryDraw' is not null or undefined
            assertParamExists('lotteryDrawApiV1LotteryDrawPost', 'lotteryDraw', lotteryDraw)
            const localVarPath = `/api/v1/lottery/draw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lotteryDraw, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1LotteryDrawPost_1: async (lotteryDraw: LotteryDraw, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotteryDraw' is not null or undefined
            assertParamExists('lotteryDrawApiV1LotteryDrawPost_1', 'lotteryDraw', lotteryDraw)
            const localVarPath = `/api/v1/lottery/draw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lotteryDraw, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {string} tenantCode 
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1TenantCodeLotteryDrawPost: async (tenantCode: string, lotteryDraw: LotteryDraw, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('lotteryDrawApiV1TenantCodeLotteryDrawPost', 'tenantCode', tenantCode)
            // verify required parameter 'lotteryDraw' is not null or undefined
            assertParamExists('lotteryDrawApiV1TenantCodeLotteryDrawPost', 'lotteryDraw', lotteryDraw)
            const localVarPath = `/api/v1/{tenant_code}/lottery/draw`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lotteryDraw, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {string} tenantCode 
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1TenantCodeLotteryDrawPost_2: async (tenantCode: string, lotteryDraw: LotteryDraw, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('lotteryDrawApiV1TenantCodeLotteryDrawPost_2', 'tenantCode', tenantCode)
            // verify required parameter 'lotteryDraw' is not null or undefined
            assertParamExists('lotteryDrawApiV1TenantCodeLotteryDrawPost_2', 'lotteryDraw', lotteryDraw)
            const localVarPath = `/api/v1/{tenant_code}/lottery/draw`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lotteryDraw, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RewardsApi - functional programming interface
 * @export
 */
export const RewardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RewardsApiAxiosParamCreator(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.assetsApiV1AssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.assetsApiV1TenantCodeAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryDrawApiV1LotteryDrawPost(lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryDrawApiV1LotteryDrawPost(lotteryDraw, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.lotteryDrawApiV1LotteryDrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryDrawApiV1LotteryDrawPost_1(lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryDrawApiV1LotteryDrawPost_1(lotteryDraw, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.lotteryDrawApiV1LotteryDrawPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {string} tenantCode 
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryDrawApiV1TenantCodeLotteryDrawPost(tenantCode: string, lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryDrawApiV1TenantCodeLotteryDrawPost(tenantCode, lotteryDraw, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.lotteryDrawApiV1TenantCodeLotteryDrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {string} tenantCode 
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryDrawApiV1TenantCodeLotteryDrawPost_2(tenantCode: string, lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryDrawApiV1TenantCodeLotteryDrawPost_2(tenantCode, lotteryDraw, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.lotteryDrawApiV1TenantCodeLotteryDrawPost_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RewardsApi - factory interface
 * @export
 */
export const RewardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RewardsApiFp(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1LotteryDrawPost(lotteryDraw: LotteryDraw, options?: any): AxiosPromise<LotteryResult> {
            return localVarFp.lotteryDrawApiV1LotteryDrawPost(lotteryDraw, options).then((request) => request(axios, basePath));
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1LotteryDrawPost_1(lotteryDraw: LotteryDraw, options?: any): AxiosPromise<LotteryResult> {
            return localVarFp.lotteryDrawApiV1LotteryDrawPost_1(lotteryDraw, options).then((request) => request(axios, basePath));
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {string} tenantCode 
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1TenantCodeLotteryDrawPost(tenantCode: string, lotteryDraw: LotteryDraw, options?: any): AxiosPromise<LotteryResult> {
            return localVarFp.lotteryDrawApiV1TenantCodeLotteryDrawPost(tenantCode, lotteryDraw, options).then((request) => request(axios, basePath));
        },
        /**
         * 抽選API
         * @summary Lottery Draw
         * @param {string} tenantCode 
         * @param {LotteryDraw} lotteryDraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryDrawApiV1TenantCodeLotteryDrawPost_2(tenantCode: string, lotteryDraw: LotteryDraw, options?: any): AxiosPromise<LotteryResult> {
            return localVarFp.lotteryDrawApiV1TenantCodeLotteryDrawPost_2(tenantCode, lotteryDraw, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RewardsApi - object-oriented interface
 * @export
 * @class RewardsApi
 * @extends {BaseAPI}
 */
export class RewardsApi extends BaseAPI {
    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).assetsApiV1AssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 抽選API
     * @summary Lottery Draw
     * @param {LotteryDraw} lotteryDraw 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public lotteryDrawApiV1LotteryDrawPost(lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).lotteryDrawApiV1LotteryDrawPost(lotteryDraw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 抽選API
     * @summary Lottery Draw
     * @param {LotteryDraw} lotteryDraw 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public lotteryDrawApiV1LotteryDrawPost_1(lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).lotteryDrawApiV1LotteryDrawPost_1(lotteryDraw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 抽選API
     * @summary Lottery Draw
     * @param {string} tenantCode 
     * @param {LotteryDraw} lotteryDraw 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public lotteryDrawApiV1TenantCodeLotteryDrawPost(tenantCode: string, lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).lotteryDrawApiV1TenantCodeLotteryDrawPost(tenantCode, lotteryDraw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 抽選API
     * @summary Lottery Draw
     * @param {string} tenantCode 
     * @param {LotteryDraw} lotteryDraw 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public lotteryDrawApiV1TenantCodeLotteryDrawPost_2(tenantCode: string, lotteryDraw: LotteryDraw, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).lotteryDrawApiV1TenantCodeLotteryDrawPost_2(tenantCode, lotteryDraw, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1AssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet: async (tenantCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('assetsApiV1TenantCodeAssetsGet', 'tenantCode', tenantCode)
            const localVarPath = `/api/v1/{tenant_code}/assets`
                .replace(`{${"tenant_code"}}`, encodeURIComponent(String(tenantCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1AssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.assetsApiV1AssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsApiV1TenantCodeAssetsGet(tenantCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.assetsApiV1TenantCodeAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1AssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1AssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * アセット取得API
         * @summary Assets
         * @param {string} tenantCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public assetsApiV1AssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).assetsApiV1AssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセット取得API
     * @summary Assets
     * @param {string} tenantCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public assetsApiV1TenantCodeAssetsGet(tenantCode: string, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).assetsApiV1TenantCodeAssetsGet(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }
}



