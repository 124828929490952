import React from "react";

export const IconArrowRight: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.206"
      height="15.411"
      viewBox="0 0 9.206 15.411"
    >
      <path
        id="Path_1185"
        data-name="Path 1185"
        d="M.354.354l8,7-8,7"
        transform="translate(0.352 0.352)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};
