export const FETCH_ASSET_DATA_START = "FETCH_DATA_START";
export const FETCH_ASSET_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_ASSET_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const CLEAR_ASSET_DATA = "CLEAR_ASSET_DATA";

export const fetchAssetDataStart = () => ({ type: FETCH_ASSET_DATA_START });
export const fetchAssetDataSuccess = (data: any) => ({
  type: FETCH_ASSET_DATA_SUCCESS,
  payload: data,
});
export const fetchAssetDataFailure = (error: any) => ({
  type: FETCH_ASSET_DATA_FAILURE,
  payload: error,
});
export const clearAssetData = () => ({ type: "CLEAR_ASSET_DATA" });
