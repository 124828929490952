import { useCallback, useEffect } from "react";
import { settingStore } from "../../components/template/store/setting";
import { getSetting } from "../../core/http/httpClient";
import { Manifest } from "../../manifest";

export const useSetting = () => {
  // state
  const setSetting = settingStore((state) => state.setSetting);
  const setting = settingStore((state) => state);
  const loadSetting = useCallback(() => {
    const pathName = location.pathname;
    const dirAry = pathName.split("/");
    const tenant_code = dirAry[1];
    if (tenant_code) {
      getSetting(tenant_code)
        .then((result) => {
          setSetting(result.data.setting);
        })
        .catch(() => {
          location.pathname = "/";
        });
    }
  }, []);

  useEffect(() => {
    if (setting) {
      // title
      document.title = setting.meta.title ? setting.meta.title : "";
      // favicon
      const link = document.querySelector<HTMLLinkElement>("link[rel*='icon']");
      if (link) {
        link.href = setting.meta.favicon_url
          ? setting.meta.favicon_url
          : "/favicon.ico";
      }
      const appleTouchIcon = document.querySelector<HTMLLinkElement>(
        "link[rel*='apple-touch-icon']",
      );
      if (appleTouchIcon) {
        appleTouchIcon.href = setting.meta.favicon_url;
      }
      const manifest = document.querySelector<HTMLLinkElement>(
        "#my-manifest-placeholder",
      );
      if (manifest) {
        manifest.setAttribute("href", Manifest());
      }

      const keywords = document.querySelector<HTMLMetaElement>(
        "meta[name='keywords']",
      );
      if (keywords) {
        keywords.content = setting.meta.keywords ? setting.meta.keywords : "";
      }

      // ogp
      const ogUrl = document.querySelector<HTMLMetaElement>(
        "meta[property='og:url']",
      );
      if (ogUrl) {
        ogUrl.content = location.host + location.pathname;
      }
      const ogType = document.querySelector<HTMLMetaElement>(
        "meta[property='og:type']",
      );
      if (ogType) {
        ogType.content = "website";
      }
      const ogTitle = document.querySelector<HTMLMetaElement>(
        "meta[property='og:title']",
      );
      if (ogTitle) {
        ogTitle.content = setting.meta.title ? setting.meta.title : "";
      }
      const ogDescription = document.querySelector<HTMLMetaElement>(
        "meta[property='og:description']",
      );
      if (ogDescription) {
        ogDescription.content = setting.meta.description
          ? setting.meta.description
          : "";
      }
      const ogSiteNmae = document.querySelector<HTMLMetaElement>(
        "meta[property='og:site_name']",
      );
      if (ogSiteNmae) {
        ogSiteNmae.content = setting.meta.title ? setting.meta.title : "";
      }
      const ogImage = document.querySelector<HTMLMetaElement>(
        "meta[property='og:image']",
      );
      if (ogImage) {
        ogImage.content = setting.meta.ogp.image_url
          ? setting.meta.ogp.image_url
          : "";
      }

      const headData = document.head.children;
      for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute("name");
        if (nameVal !== null) {
          if (nameVal.indexOf("keywords") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.keywords ? setting.meta.keywords : "",
            );
          }
          if (nameVal.indexOf("description") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.description ? setting.meta.description : "",
            );
          }
          // OGPの設定
          if (nameVal.indexOf("og:site_name") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.title ? setting.meta.title : "",
            );
          }
          if (nameVal.indexOf("og:image") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.ogp.image_url ? setting.meta.ogp.image_url : "",
            );
          }
          if (nameVal.indexOf("og:image:url") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.ogp.image_url ? setting.meta.ogp.image_url : "",
            );
          }
          if (nameVal.indexOf("og:description") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.description ? setting.meta.description : "",
            );
          }
          // OGP(twitter)の設定
          if (nameVal.indexOf("twitter:title") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.title ? setting.meta.title : "",
            );
          }
          if (nameVal.indexOf("twitter:image") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.ogp.image_url ? setting.meta.ogp.image_url : "",
            );
          }
          if (nameVal.indexOf("twitter:description") != -1) {
            headData[i].setAttribute(
              "content",
              setting.meta.description ? setting.meta.description : "",
            );
          }
        }
      }
    }
  }, [setting]);

  return {
    loadSetting,
    setting,
  };
};
