import { ForgotPasswordValidationSchema } from "../validation";
import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SET_EMAIL,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
} from "./actions";
import { ForgotPasswordState } from "./type";

export const initialState: ForgotPasswordState = {
  loading: false,
  email: "",
  emailError: null,
  enabled: false,
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FORGOT_PASSWORD_SET_EMAIL:
      const forgotPasswordInfo = ForgotPasswordValidationSchema.safeParse({
        email: action.payload,
      });

      if (forgotPasswordInfo.success) {
        return {
          ...state,
          email: action.payload,
          emailError: null,
          enabled: true,
        };
      } else {
        return {
          ...state,
          email: action.payload,
          emailError:
            forgotPasswordInfo.error.formErrors.fieldErrors["email"]?.[0] ??
            null,
          enabled: false,
        };
      }
    case FORGOT_PASSWORD_START:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
