import React from "react";
import { CSSProperties } from "styled-components";
import { StyledLimitLabel } from "./style/label-limit";

interface ProgressBarProps {
  style?: CSSProperties;
}

export const LimitLabel: React.FC<ProgressBarProps> = (props) => {
  return <StyledLimitLabel>終了間近</StyledLimitLabel>;
};
