import { AppConfig } from "../../../../AppConfig";
import { memberApi } from "../../../../core/http/openAPIClient";
import { LocalStorageManager } from "../../../../core/storage/LocalStorageManager";
import { router } from "../../../../index";
import { clearAssets } from "../../../../lib/asset/redux/store";
import { clearEarnedCampaign } from "../../campaign/redux/earnedCampaign/store";
import { clearParticipatingCampaign } from "../../campaign/redux/participatingCampaign/store";
import { clearMember } from "../../member_registration/redux/store";
import { clearInformation } from "../../../../lib/information/redux/store";

export const onClear = () => {
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["accessToken"],
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["assets"],
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["information"],
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["campaigns"],
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["participatingCampaigns"],
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["earnedCampaigns"],
  );
  clearInformation();
  clearAssets();
  clearParticipatingCampaign();
  clearEarnedCampaign();
  clearMember();
};

export const onLogout = () => {
  onClear();
  const tenant_code = location.pathname.split("/")[1];
  router.navigate(`/${tenant_code}/login`);
};

export const onWithdraw = (tenant_code: string) => {
  memberApi
    .memberDeleteApiV1TenantCodeMembersDeleteGet(tenant_code)
    .then((res) => {
      onClear();
      const tenant_code = location.pathname.split("/")[1];
      router.navigate(`/${tenant_code}/login`).then(() => {
        window.location.reload();
      });
    })
    .catch((e) => console.log(e));
};
