import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { formatDateTime } from "../../../../common/utils/date";
import useInformationStore from "../../../../lib/information/redux/store";
import { Information } from "../../../../lib/information/redux/types";
import { templateStore } from "../../../template/store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 7px 5px 0 5px;
  border: solid 1px #b22020;
  text-align: left;
  font-size: 12px;
`;
const Frame = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 0 0 1px 0;
  padding: 0;
  align-items: flex-start;
`;
const TitleText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #b22020;
  margin: 0 10px 0 0;
`;
const TitleDate = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #b22020;
  margin: 0;
`;
const Body = styled.p<{ isExpanded: boolean }>`
  color: #b22020;
  font-size: 12px;
  line-height: 1.7em;
  margin: 0;
  text-align: left;
  word-wrap: break-word;
  ${(props) => {
    if (props.isExpanded) {
      return `
        max-height: 100%;
      `;
    } else {
      return `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
          max-height: 40px;
      `;
    }
  }}
`;

export const PickupInformation: React.FC = () => {
  const isLogin = templateStore((state) => state.isLoggedIn);
  const [expandedItem, setExpandedItem] = useState<number[]>([]);
  const notifications: Information[] = useInformationStore(
    (state) => state.concatedInformationList,
  );

  const pickupNotifications = useMemo(() => {
    return notifications.filter((notification) => {
      return notification.highlight_flag;
    });
  }, [notifications]);

  if (!isLogin || !pickupNotifications || pickupNotifications.length === 0) {
    return null;
  }

  const handleAddExpand = (id: number) => {
    const isExist = expandedItem.filter((item) => item === id);
    if (isExist.length) {
      setExpandedItem(expandedItem.filter((item) => item !== id));
    } else {
      setExpandedItem([...expandedItem, id]);
    }
  };

  const checkWidth = (): number => {
    if (window.innerWidth >= 640) {
      return 170;
    }
    if (window.innerWidth <= 390) {
      return 100;
    }
    return 120;
  };

  return (
    <Container>
      {pickupNotifications.map((notification, index) => {
        return (
          <Frame
            key={notification.end_at}
            onClick={() => handleAddExpand(index)}
          >
            <Title>
              <TitleText>{`重要なお知らせ`}</TitleText>
              <TitleDate>{`${formatDateTime(notification.start_at)}`}</TitleDate>
            </Title>
            <Body isExpanded={expandedItem.includes(index)}>
              {notification.content}
            </Body>
          </Frame>
        );
      })}
    </Container>
  );
};
