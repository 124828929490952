import React, { useEffect, useState } from "react";

export const Image: React.FC<any> = (props) => {
  const defaultLogoUrl = "/assets/common/logo_default.png";
  const [isErr, setErr] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>(defaultLogoUrl);

  useEffect(() => {
    if (isErr) {
      setImgSrc(defaultLogoUrl);
    } else if (props.src) {
      setImgSrc(props.src);
    }
  }, [isErr, props.src]);

  return (
    <img
      {...props}
      src={imgSrc}
      onError={(e) => {
        setErr(true);
      }}
      alt={props.alt ? props.alt : "image"}
    />
  );
};
