import React from "react";

type Props = {
  open?: boolean;
};

export const IconEye: React.FC<Props> = ({ open }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 22"
      width={24}
      height={22}
    >
      <path
        fill="#666"
        fillRule="evenodd"
        d={
          open
            ? "M322.5,34.6c0.8-0.5,1.6-1.4,2.3-2.2c0.6-0.7,1.1-1.6,1.5-2.4c-0.9-2-2.3-3.6-4.1-4.8 c-1.8-1.2-3.8-1.8-5.9-1.8c-0.8,0-1.6,0.1-2.3,0.2c-0.6,0.1-0.4,0.1-1.9,0.5c-1.4,0.4-3.1,1.6-3.1,1.6c-0.7,0.6-1.4,1.2-2,1.9 c-0.6,0.7-1,1.4-1.3,2.3c0.9,2,2.3,3.6,4.2,4.8c1.9,1.2,4.1,1.8,6.3,1.8c0.6,0,1.2,0,1.8-0.1c0.5,0,0.6-0.1,1.3-0.3 C320,35.8,321.7,35.1,322.5,34.6z M320.6,37.3c-1,0.4-1.4,0.5-2.2,0.6c-0.8,0.1-1.6,0.2-2.4,0.2c-2.6,0-5.1-0.8-7.2-2.2 c-2.1-1.5-3.8-3.5-4.8-6c0.4-1,0.9-1.9,1.5-2.8c0.7-0.9,1.5-1.8,2.4-2.6c0,0,1.7-1.3,2.9-1.7c1.2-0.5,1.6-0.6,2.4-0.8 c1-0.2,1.9-0.3,2.9-0.3c2.6,0,5,0.8,7.1,2.2c2.1,1.5,3.8,3.5,4.7,6c-0.5,1.1-1.1,2.2-1.8,3.2c-0.7,1-1.6,1.8-2.6,2.5 c0,0,0.9-0.6,0,0S321.6,37,320.6,37.3z M320.6,30c0,2.6-2.1,4.6-4.6,4.6c-2.6,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6 C318.6,25.3,320.6,27.4,320.6,30z"
            : "M16.064,12.164l-1.2-1.2a2.705,2.705,0,0,0-.736-3.218,2.913,2.913,0,0,0-3.136-.655l-1.2-1.2a3.3,3.3,0,0,1,1.036-.436A5.037,5.037,0,0,1,12,5.318a4.609,4.609,0,0,1,4.636,4.636,4.779,4.779,0,0,1-.15,1.186,3.6,3.6,0,0,1-.423,1.023m3.518,3.518-1.091-1.091a12.809,12.809,0,0,0,2.332-2.2,8.761,8.761,0,0,0,1.459-2.441,10.888,10.888,0,0,0-4.091-4.786,10.709,10.709,0,0,0-5.918-1.759,13.083,13.083,0,0,0-2.345.218,8.69,8.69,0,0,0-1.882.518L6.791,2.864A12.379,12.379,0,0,1,9.232,2.1a13.489,13.489,0,0,1,2.9-.327A12.31,12.31,0,0,1,19.268,4,12.886,12.886,0,0,1,24,9.955a13.481,13.481,0,0,1-1.827,3.191,13.167,13.167,0,0,1-2.591,2.536m1.582,6.164-4.582-4.5a10.584,10.584,0,0,1-2.155.586,14.421,14.421,0,0,1-2.427.2,12.5,12.5,0,0,1-7.227-2.223A13.027,13.027,0,0,1,0,9.955,12.394,12.394,0,0,1,1.514,7.186,15.5,15.5,0,0,1,3.873,4.609L.436,1.173,1.582,0,22.227,20.645ZM4.991,5.755a10.037,10.037,0,0,0-1.95,1.936,8.98,8.98,0,0,0-1.35,2.264,10.92,10.92,0,0,0,4.186,4.786A11.656,11.656,0,0,0,12.218,16.5a14.316,14.316,0,0,0,1.773-.109,4.223,4.223,0,0,0,1.309-.327l-1.745-1.745a2.845,2.845,0,0,1-.736.2,5.335,5.335,0,0,1-.818.068,4.511,4.511,0,0,1-3.273-1.336,4.437,4.437,0,0,1-1.364-3.3,4.989,4.989,0,0,1,.068-.818,3.32,3.32,0,0,1,.2-.736Z"
        }
        transform={open ? "translate(-304 -20)" : "translate(0 0)"}
        clipRule="evenodd"
      />
    </svg>
  );
};
