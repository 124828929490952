import create from "zustand";
import { persist, redux } from "zustand/middleware";
import { excludeCategories } from "../../../../../common/utils/campaign";
import useAssetStore from "../../../../../lib/asset/redux/store";
import { log } from "../../../../../lib/hooks/useLogMiddleware";
import { Campaign, Reward } from "../../campaign.type";
import useCampaignStore from "../campaign/store";
import {
  clearParticipatingCampaignData,
  fetchParticipatingCampaignSuccess,
  fetchParticipatingCategoryData,
  filterParticipatingCampaignData,
} from "./actions";
import { initialState, reducer } from "./reducer";

const useParticipatingCampaignStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "participating-campaigns",
    }),
  ),
);

export const fetchParticipatingCampaigns = async () => {
  const dispatch = useParticipatingCampaignStore.getState().dispatch;
  const campaigns = useCampaignStore.getState().campaigns;
  const memberCampaigns = useAssetStore.getState().memberCampaigns;
  const memberRewards = useAssetStore.getState().memberRewards;
  if (memberCampaigns.length === 0) return;
  const formattedCampaigns = campaigns
    .map((campaign: Campaign) => {
      const matchedCampaign = memberCampaigns.find(
        (c: any) => c.campaign_id === campaign.id,
      );
      const matchedRewards = memberRewards.filter((reward: Reward) => {
        return reward.campaign_id === campaign.id;
      });
      if (memberCampaigns.some((c: any) => c.campaign_id === campaign.id)) {
        return {
          ...campaign,
          accumulated_mileage: matchedCampaign.accumulated_mileage,
          before_campaign_reward_id: matchedCampaign.before_campaign_reward_id,
          before_mileage: matchedCampaign.before_mileage,
          next_campaign_reward_id: matchedCampaign.next_campaign_reward_id,
          next_mileage: matchedCampaign.next_mileage,
          purchase_logs: matchedCampaign.purchase_logs,
          matchedRewards: matchedRewards,
        };
      }
    })
    .filter((campaign: Campaign) => campaign !== undefined);
  formattedCampaigns.sort((a: Campaign, b: Campaign) => {
    return (
      new Date(b.implementation_period_start).getTime() -
      new Date(a.implementation_period_start).getTime()
    );
  });
  // カテゴリの抽出
  const categories = excludeCategories(campaigns);
  try {
    dispatch(fetchParticipatingCategoryData(categories));
    dispatch(fetchParticipatingCampaignSuccess(formattedCampaigns));
    dispatch(filterParticipatingCampaignData(formattedCampaigns));
  } catch (error) {
    console.log("error", error);
  }
};

export const filterParticipatingCampaigns = (category: string) => {
  const state = useParticipatingCampaignStore.getState();
  if (category === "all") {
    return state.dispatch(
      filterParticipatingCampaignData(state.participatingCampaigns),
    );
  }
  const filteredCampaigns = state.participatingCampaigns.filter(
    (campaign: Campaign) => {
      return campaign.campaign_categories.includes(category);
    },
  );
  state.dispatch(filterParticipatingCampaignData(filteredCampaigns));
};

export const resetParticipatingCampaignData = () => {
  const state = useParticipatingCampaignStore.getState();
  state.dispatch(filterParticipatingCampaignData(state.participatingCampaigns));
};

export const clearParticipatingCampaign = () => {
  useParticipatingCampaignStore
    .getState()
    .dispatch(clearParticipatingCampaignData());
};

export default useParticipatingCampaignStore;
