import React from "react";
import { LoginContainer } from "../../features/login";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";

export const Login: React.FC = () => {
  return (
    <Base>
      <HeaderTemplate>
        <LoginContainer />
      </HeaderTemplate>
    </Base>
  );
};
