import { MEMBERSHIP_NUMBER_VALIDATION_TEXT } from "../constant";
import {
  MemberRegistrationEmailValidationSchema,
  MemberRegistrationValidationSchema,
} from "../validation";
import {
  CLEAR_MEMBER_INFORMATION,
  EDIT_MEMBER_FAILURE,
  EDIT_MEMBER_START,
  EDIT_MEMBER_SUCCESS,
  MEMBER_REGISTRATION_EMAIL_FAILURE,
  MEMBER_REGISTRATION_EMAIL_SET_EMAIL,
  MEMBER_REGISTRATION_EMAIL_START,
  MEMBER_REGISTRATION_EMAIL_SUCCESS,
  MEMBER_REGISTRATION_FAILURE,
  MEMBER_REGISTRATION_RESET_PASSWORD,
  MEMBER_REGISTRATION_SET_CONFIRM_PASSWORD,
  MEMBER_REGISTRATION_SET_EMAIL,
  MEMBER_REGISTRATION_SET_GENDER,
  MEMBER_REGISTRATION_SET_LINE_SERVICE_ID,
  MEMBER_REGISTRATION_SET_MEMBERSHIP_NUMBER,
  MEMBER_REGISTRATION_SET_MONTH,
  MEMBER_REGISTRATION_SET_PASSWORD,
  MEMBER_REGISTRATION_SET_TOKEN,
  MEMBER_REGISTRATION_SET_YEAR,
  MEMBER_REGISTRATION_START,
  MEMBER_REGISTRATION_SUCCESS,
  SET_MEMBER_DATA,
} from "./actions";
import { MemberRegistrationState } from "./type";

export const initialState: MemberRegistrationState = {
  loading: false,
  email: "",
  emailError: null,
  gender: null,
  genderError: null,
  year: null,
  yearError: null,
  month: null,
  monthError: null,
  password: "",
  passwordError: null,
  confirmPassword: "",
  confirmPasswordError: null,
  membershipNumber: "",
  membershipNumberError: null,
  lineServiceId: null,
  token: "",
  enabled: false,
};

export const reducer = (state = initialState, action: any) => {
  let memberRegistrationInfo;
  switch (action.type) {
    case MEMBER_REGISTRATION_SET_EMAIL:
      memberRegistrationInfo = MemberRegistrationValidationSchema.safeParse({
        email: action.payload.email,
        gender: state.gender,
        year: state.year,
        month: state.month,
        password: state.password,
        confirmPassword: state.confirmPassword,
      });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          email: action.payload.email,
          emailError: null,
          genderError: null,
          yearError: null,
          monthError: null,
          passwordError: null,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        const isError =
          memberRegistrationInfo.error.formErrors.fieldErrors["email"]?.[0] ??
          memberRegistrationInfo.error.formErrors.formErrors?.[0];
        return {
          ...state,
          email: action.payload.email,
          emailError:
            memberRegistrationInfo.error.formErrors.fieldErrors["email"]?.[0] ??
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ??
            null,
          enabled:
            //enable the submit button for update member information.
            //Either user change password or not, the password and confirm password should be the same in order to enable the button
            action.payload.isEdit && state.password === state.confirmPassword
              ? isError
                ? false
                : action.payload.isEdit
              : false,
        };
      }
    case MEMBER_REGISTRATION_SET_GENDER:
      memberRegistrationInfo = MemberRegistrationValidationSchema.safeParse({
        email: state.email,
        gender: action.payload.gender,
        year: state.year,
        month: state.month,
        password: state.password,
        confirmPassword: state.confirmPassword,
      });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          emailError: null,
          gender: action.payload.gender,
          genderError: null,
          yearError: null,
          monthError: null,
          passwordError: null,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        const gender =
          action.payload.originData?.gender === "male"
            ? 1
            : action.payload.originData?.gender === "female"
              ? 2
              : action.payload.originData?.gender === "not_specified"
                ? 3
                : undefined;
        const year = action.payload.originData?.birth_year;
        const month = action.payload.originData?.birth_month;
        return {
          ...state,
          gender: action.payload.gender,
          genderError:
            memberRegistrationInfo.error.formErrors.fieldErrors[
              "gender"
            ]?.[0] ??
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ??
            null,
          //enable the submit button for update member information.
          //Either user change password or not, the password and confirm password should be the same in order to enable the button
          enabled:
            (action.payload.gender !== gender ||
              state.year !== year ||
              state.month !== month) &&
            state.password === state.confirmPassword &&
            action.payload.gender &&
            state.year &&
            state.month &&
            action.payload.originData
              ? true
              : false,
        };
      }
    case MEMBER_REGISTRATION_SET_YEAR:
      memberRegistrationInfo = MemberRegistrationValidationSchema.safeParse({
        email: state.email,
        gender: state.gender,
        year: action.payload.year,
        month: state.month,
        password: state.password,
        confirmPassword: state.confirmPassword,
      });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          emailError: null,
          genderError: null,
          year: action.payload.year,
          yearError: null,
          monthError: null,
          passwordError: null,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        const gender =
          action.payload.originData?.gender === "male"
            ? 1
            : action.payload.originData?.gender === "female"
              ? 2
              : action.payload.originData?.gender === "not_specified"
                ? 3
                : undefined;
        const year = action.payload.originData?.birth_year;
        const month = action.payload.originData?.birth_month;
        return {
          ...state,
          year: action.payload.year,
          yearError:
            memberRegistrationInfo.error.formErrors.fieldErrors["year"]?.[0] ??
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ??
            null,
          //enable the submit button for update member information.
          //Either user change password or not, the password and confirm password should be the same in order to enable the button
          enabled:
            (action.payload.year !== year ||
              state.gender !== gender ||
              state.month !== month) &&
            state.password === state.confirmPassword &&
            action.payload.year &&
            state.gender &&
            state.month &&
            action.payload.originData
              ? true
              : false,
        };
      }
    case MEMBER_REGISTRATION_SET_MONTH:
      memberRegistrationInfo = MemberRegistrationValidationSchema.safeParse({
        email: state.email,
        gender: state.gender,
        year: state.year,
        month: action.payload.month,
        password: state.password,
        confirmPassword: state.confirmPassword,
      });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          emailError: null,
          genderError: null,
          yearError: null,
          month: action.payload.month,
          monthError: null,
          passwordError: null,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        const gender =
          action.payload.originData?.gender === "male"
            ? 1
            : action.payload.originData?.gender === "female"
              ? 2
              : action.payload.originData?.gender === "not_specified"
                ? 3
                : undefined;
        const year = action.payload.originData?.birth_year;
        const month = action.payload.originData?.birth_month;
        return {
          ...state,
          month: action.payload.month,
          monthError:
            memberRegistrationInfo.error.formErrors.fieldErrors["month"]?.[0] ??
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ??
            null,
          enabled:
            (state.year !== year ||
              state.gender !== gender ||
              action.payload.month !== month) &&
            state.password === state.confirmPassword &&
            action.payload.month &&
            state.gender &&
            state.year &&
            action.payload.originData
              ? true
              : false,
        };
      }
    case MEMBER_REGISTRATION_SET_PASSWORD:
      memberRegistrationInfo = MemberRegistrationValidationSchema.safeParse({
        email: state.email,
        gender: state.gender,
        year: state.year,
        month: state.month,
        password: action.payload.password,
        confirmPassword: state.confirmPassword,
      });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          emailError: null,
          genderError: null,
          yearError: null,
          monthError: null,
          password: action.payload.password,
          passwordError: null,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        const gender =
          action.payload.originData?.gender === "male"
            ? 1
            : action.payload.originData?.gender === "female"
              ? 2
              : action.payload.originData?.gender === "not_specified"
                ? 3
                : undefined;
        const year = action.payload.originData?.birth_year;
        const month = action.payload.originData?.birth_month;
        return {
          ...state,
          password: action.payload.password,
          passwordError:
            memberRegistrationInfo.error.formErrors.fieldErrors.password?.[0] ??
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ??
            null,
          confirmPasswordError:
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ?? null,
          enabled:
            (state.year !== year ||
              state.gender !== gender ||
              state.month !== month) &&
            action.payload.password === "" &&
            state.confirmPassword == "" &&
            state.month &&
            state.gender &&
            state.year &&
            action.payload.originData
              ? true
              : false,
        };
      }
    case MEMBER_REGISTRATION_SET_CONFIRM_PASSWORD:
      memberRegistrationInfo = MemberRegistrationValidationSchema.safeParse({
        email: state.email,
        gender: state.gender,
        year: state.year,
        month: state.month,
        password: state.password,
        confirmPassword: action.payload.password,
      });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          emailError: null,
          genderError: null,
          yearError: null,
          monthError: null,
          passwordError: null,
          confirmPassword: action.payload.password,
          confirmPasswordError: null,
          enabled: true,
        };
      } else {
        const gender =
          action.payload.originData?.gender === "male"
            ? 1
            : action.payload.originData?.gender === "female"
              ? 2
              : action.payload.originData?.gender === "not_specified"
                ? 3
                : undefined;
        const year = action.payload.originData?.birth_year;
        const month = action.payload.originData?.birth_month;
        return {
          ...state,
          passwordError:
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ?? null,
          confirmPassword: action.payload.password,
          confirmPasswordError:
            memberRegistrationInfo.error.formErrors.fieldErrors.password?.[0] ??
            memberRegistrationInfo.error.formErrors.formErrors?.[0] ??
            null,
          enabled:
            (state.year !== year ||
              state.gender !== gender ||
              state.month !== month) &&
            state.password === "" &&
            action.payload.password == "" &&
            state.month &&
            state.gender &&
            state.year &&
            action.payload.originData
              ? true
              : false,
        };
      }
    case MEMBER_REGISTRATION_SET_MEMBERSHIP_NUMBER:
      return {
        ...state,
        membershipNumber: action.payload,
        membershipNumberError:
          action.payload.length === 0
            ? MEMBERSHIP_NUMBER_VALIDATION_TEXT
            : null,
        enabled: action.payload ? true : false,
      };
    case MEMBER_REGISTRATION_SET_LINE_SERVICE_ID:
      return {
        ...state,
        lineServiceId: action.payload,
      };
    case MEMBER_REGISTRATION_SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case MEMBER_REGISTRATION_EMAIL_SET_EMAIL:
      memberRegistrationInfo =
        MemberRegistrationEmailValidationSchema.safeParse({
          email: action.payload,
        });
      if (memberRegistrationInfo.success) {
        return {
          ...state,
          email: action.payload,
          emailError: null,
          enabled: true,
        };
      } else {
        return {
          ...state,
          email: action.payload,
          emailError:
            memberRegistrationInfo.error.formErrors.fieldErrors["email"]?.[0] ??
            null,
          enabled: false,
        };
      }
    case MEMBER_REGISTRATION_EMAIL_START:
      return { ...state, loading: true };
    case MEMBER_REGISTRATION_EMAIL_SUCCESS:
      return { ...state, loading: false };
    case MEMBER_REGISTRATION_EMAIL_FAILURE:
      return { ...state, loading: false };

    case MEMBER_REGISTRATION_START:
      return { ...state, loading: true };
    case MEMBER_REGISTRATION_SUCCESS:
      return { ...state, loading: false };
    case MEMBER_REGISTRATION_FAILURE:
      return { ...state, loading: false };
    case MEMBER_REGISTRATION_RESET_PASSWORD:
      return {
        ...state,
        password: "",
        passwordError: null,
        confirmPassword: "",
        confirmPasswordError: null,
      };

    case EDIT_MEMBER_START:
      return { ...state, loading: true };
    case EDIT_MEMBER_SUCCESS:
      return { ...state, loading: false };
    case EDIT_MEMBER_FAILURE:
      return { ...state, loading: false };

    case SET_MEMBER_DATA:
      return {
        ...state,
        email: action.payload.email ? action.payload.email : state.email,
        gender: action.payload.gender ? action.payload.gender : state.gender,
        year: action.payload.year ? action.payload.year : state.year,
        month: action.payload.month ? action.payload.month : state.month,
        password: action.payload.password
          ? action.payload.password
          : state.password,
        confirmPassword: action.payload.confirmPassword
          ? action.payload.confirmPassword
          : state.confirmPassword,
        membershipNumber: action.payload.membershipNumber
          ? action.payload.membershipNumber
          : state.membershipNumber,
        lineServiceId: action.payload.lineServiceId
          ? action.payload.lineServiceId
          : state.lineServiceId,
        token: action.payload.token ? action.payload.token : state.token,
      };

    case CLEAR_MEMBER_INFORMATION:
      return initialState;
    default:
      return state;
  }
};
