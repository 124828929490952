import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "../../lib/hooks/useNavigate";
import { templateStore } from "./store";

type Props = {
  children: ReactNode;
  requireLogin?: boolean;
  requireLogout?: boolean;
};

export const AuthTemplate: React.FC<Props> = ({
  children,
  requireLogin,
  requireLogout,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const setIsLoggedIn = templateStore((state) => state.setIsLoggedIn);
  useEffect(() => {
    const token =
      (localStorage.getItem(`t__mm_access_token`) as string | null) !== null;

    if (!token && requireLogin) {
      navigate("/login", { replace: true });
    }

    if (token && requireLogout) {
      navigate("/", { replace: true });
    }
    setIsLoggedIn(!!token);
  }, [location.pathname]);

  return <>{children}</>;
};
