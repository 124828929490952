import React, { useCallback, useEffect } from "react";
import { LINE } from "../../../common/icons/LINE";
import { settingStore } from "../../template/store/setting";
import { StyledLINEButton, StyledLINEButtonText } from "./style";

type Props = {
  onClick?: () => void;
};

export const LINEButton: React.FC<Props> = (props) => {
  const lineFriend = settingStore((state) => state.line_settings);
  //
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onClick = useCallback(() => {
    window.open(lineFriend.transition_script, "_blank");
  }, []);

  return (
    <StyledLINEButton
      onClick={props.onClick !== undefined ? props.onClick : onClick}
    >
      <LINE width={30} height={30} />
      <StyledLINEButtonText>友だち追加</StyledLINEButtonText>
    </StyledLINEButton>
  );
};
