import { useCallback, useState } from "react";
import { fetchCampaignData } from "../../../../common/utils/campaign";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { defaultModalProps } from "../../../ui/modal/initial";
import { ModalProps } from "../../../ui/modal/types";
import { Campaign } from "../campaign.type";
import { joinCampaign } from "../redux/campaign/store";

export const useCampaign = (
  campaign: Campaign,
  tenant_code: string | undefined,
  isLogin: boolean,
) => {
  const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);
  const navigate = useNavigate();

  const handleJoin = () => {
    if (campaign?.meta_data?.entry_qualifications?.is_over_twenty_year_old)
      return onClickJoin();
    if (campaign?.line_settings?.allow_adding_friends) return onClickAddLINE();
    return onLineLink();
  };

  const onClickAddLINE = () => {
    setModalProps({
      isShow: true,
      title: "LINE友だち追加",
      subTitle: "",
      image_url: "",
      onClose() {
        setModalProps(defaultModalProps);
      },
      contents: campaign?.line_settings?.expository_text ?? "",
      LINE: true,
      onClick: (): void => {
        onLineLink();
      },
      buttons: [
        {
          name: "友だち追加せず、参加する",
          callback: (): void => {
            if (tenant_code) {
              joinCampaign(campaign.id, tenant_code, async (res) => {
                await fetchCampaignData(tenant_code ?? "", isLogin);
                if (res) navigate("?tab=participating");
              });
            }
          },
        },
      ],
    });
  };

  const onClickJoin = useCallback(() => {
    setModalProps({
      title: "あなたは20歳以上ですか？",
      subTitle: "",
      contents: "本キャンペーンは20歳以上を対象としたキャンペーンです",
      onClose() {
        setModalProps(defaultModalProps);
      },
      buttons: [
        {
          name: "20歳以上",
          callback: () => {
            if (campaign?.line_settings?.allow_adding_friends) {
              return onClickAddLINE();
            } else {
              return onLineLink();
            }
          },
        },
        {
          name: "20歳未満",
          styling: "border",
          color: "#000000",
          callback: () => {
            setModalProps({
              isShow: true,
              title: "20歳未満は参加できません",
              subTitle: "",
              image_url: "",
              onClose() {
                setModalProps(defaultModalProps);
              },
              contents: "",
            });
          },
        },
      ],
      isShow: true,
      hideClose: true,
    });
  }, [onClickAddLINE]);

  const onLineLink = useCallback(() => {
    if (tenant_code) {
      joinCampaign(campaign.id, tenant_code, async (res) => {
        await fetchCampaignData(tenant_code ?? "", isLogin);
        if (res)
          window.location.replace(`/${tenant_code}/campaign?tab=participating`);
      });
    }
    // LINE友達追加設定がtrueの場合、LINE友達追加画面に遷移する
    if (campaign.line_settings.allow_adding_friends) {
      window.open(
        campaign.line_settings.transition_script,
        "_blank",
        "noopener noreferrer",
      );
    }
  }, []);

  return {
    handleJoin,
    onClickAddLINE,
    onClickJoin,
    onLineLink,
    modalProps,
    setModalProps,
  };
};
