import { Checkbox } from "@mui/material";
import React from "react";
import { Button } from "../button/Button";
import { TextButton } from "../button/TextButton";
import {
  AreementWrapper,
  FooterAgreeSubText,
  FooterAgreeText,
  FooterContainer,
  FooterSecondaryButton,
} from "./style";

type Props = {
  onClickButton: () => void;
  buttonText?: string;
  buttonDisabled?: boolean;
  secondaryButtonText?: string;
  onClickSecondaryButton?: () => void;
  textButtonText?: string;
  onClickTextButton?: () => void;
  isTermOfService?: boolean;
  handleCheckBox?: (event: any) => void;
  checked?: boolean;
};

export const Footer: React.FC<Props> = ({
  buttonText = "次へ",
  buttonDisabled = false,
  onClickButton,
  secondaryButtonText,
  onClickSecondaryButton,
  textButtonText,
  onClickTextButton,
  isTermOfService = false,
  checked,
  handleCheckBox,
}) => {
  const onCheck = (event: any) => {
    if (handleCheckBox) {
      handleCheckBox(event);
    }
  };
  return (
    <FooterContainer>
      {isTermOfService && (
        <>
          <AreementWrapper
            onClick={() => onCheck({ target: { checked: !checked } })}
          >
            <Checkbox checked={checked} />
            <FooterAgreeText>利用規約に同意しました。</FooterAgreeText>
          </AreementWrapper>
          <FooterAgreeSubText>
            利用規約を最後までお読みください。
          </FooterAgreeSubText>
        </>
      )}
      {secondaryButtonText && (
        <FooterSecondaryButton>
          <Button
            styling="border"
            color="#000000"
            text={secondaryButtonText}
            onClick={onClickSecondaryButton}
          />
        </FooterSecondaryButton>
      )}
      <Button
        text={buttonText}
        onClick={onClickButton}
        disabled={buttonDisabled}
      />
      {textButtonText && (
        <TextButton text={textButtonText} onClick={onClickTextButton} />
      )}
    </FooterContainer>
  );
};
