import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { ProgressDotSmall } from "../../../../common/icons/ProgressDotSmall";
import ProgressBarStyle from "./style/progress-bar.module.css";

interface ProgressBarProps {
  value: number;
  max: number;
  min?: number;
}

interface StyledLinearProgressProps extends LinearProgressProps {
  success?: boolean;
}

const screenWidth = window.innerWidth * 0.85;

const LinearProgressBar = styled(LinearProgress)<StyledLinearProgressProps>(
  ({}) => ({
    height: 6,
    color: "#0071ff",
    backgroundColor: "#e6e6e6",
    borderRadius: 8,
    margin: "10px auto",
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
    },
  }),
);

export const MileProgressBarSmall: React.FC<ProgressBarProps> = ({
  value,
  max,
  min,
}) => {
  const createMarks = () => {
    const marks = [];
    // ステップの分割
    let initVal = 0;
    let scaleVal = max / 5;

    if (min !== 0 && min !== undefined) {
      initVal = min;
      scaleVal = (max - min) / 5;
    }

    for (let i = 0; i <= 5; i += 1) {
      marks.push(
        <Box key={i} style={{ opacity: i === 0 ? 0 : 1 }}>
          <ProgressDotSmall style={ProgressBarStyle.progress_dot} />
          <Typography
            variant="caption"
            sx={{
              transform: "translateX(-50%)",
              opacity: 0,
            }}
          >
            {initVal}
          </Typography>
        </Box>,
      );
      initVal += scaleVal;
    }
    return marks;
  };

  return (
    <React.Fragment>
      <Box sx={{ position: "relative" }}>
        <LinearProgressBar variant="determinate" value={value} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: -0.6,
            pl: 0,
            transform: "translateY(-50%)",
          }}
        >
          {createMarks()}
        </Box>
      </Box>
    </React.Fragment>
  );
};
