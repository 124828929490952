import React, { useCallback, useEffect } from "react";
import {
  EMAIL_VALIDATION_TEXT,
  MEMBER_REGISTRATION_EMAIL_SUBTEXT,
  MEMBER_REGISTRATION_UPDATE_EMAIL_SUBTEXT,
} from "../../../../common/constant/validations";
import { BtnContainer, HeaderContainer } from "../../../../common/style";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { TextField } from "../../../ui";
import useMemberRegistrationStore, {
  setEmailMemberRegistrationEmail,
} from "../../member_registration/redux/store";
import { TextContainer } from "../../member_registration/style";

type Props = {
  page: "register" | "update";
};

export const MemberRegistrationEmailContainer: React.FC<Props> = ({ page }) => {
  const email = useMemberRegistrationStore((state) => state.email);
  const error = useMemberRegistrationStore((state) => state.emailError);
  const navigation = useNavigate();

  const onPrev = useCallback(() => {
    if (page === "register") navigation("/login");
    if (page === "update") navigation("/menu");
  }, []);

  useEffect(() => {
    setEmailMemberRegistrationEmail(email);
  }, [email]);

  return (
    <div>
      <HeaderContainer>
        <BtnContainer onClick={onPrev}>
          <img src="/assets/common/icon_back.svg" />
        </BtnContainer>
        {page === "register" && <h3>新規会員登録</h3>}
        {page === "update" && <h3>メールアドレス変更</h3>}
      </HeaderContainer>

      <TextContainer>
        <TextField
          type="email"
          name="email"
          label="メールアドレス"
          required
          placeholder={EMAIL_VALIDATION_TEXT}
          subtext={
            page === "register"
              ? MEMBER_REGISTRATION_EMAIL_SUBTEXT
              : MEMBER_REGISTRATION_UPDATE_EMAIL_SUBTEXT
          }
          value={email}
          onInput={setEmailMemberRegistrationEmail}
          error={error}
        />
      </TextContainer>
    </div>
  );
};
