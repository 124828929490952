export const WALKTHROUGH_IMAGES = [
  "/assets/walkthrough/walkthrough_1.svg",
  "/assets/walkthrough/walkthrough_2.svg",
  "/assets/walkthrough/walkthrough_3.svg",
  "/assets/walkthrough/walkthrough_4.svg",
];
export const WALKTHROUGH_TITLE = [
  "いつものお買い物で\n特典をゲットしよう！",
  "キャンペーンに参加して\nお買い物でマイルを貯めよう！",
  "キャンペーン参加には\nまず、会員登録を行います",
  "興味のあるキャンペーンに\n参加しましょう！",
];
export const WALKTHROUGH_TEXT = [
  "対象商品を購入し、\nマイルを貯めて、クーポンやポイントなど\nお得な特典をもらおう",
  [
    "キャンペーン対象の\n商品を購入するとマイルが貯まります",
    "\n※支払い方法によっては、\nマイルに反映されない場合があります",
  ],
  "キャンペーン参加には\n会員登録が必要です。\n情報を入力し登録を行ってください",
  "会員登録後に\n興味のあるキャンペーンの参加ボタンを\n押して、参加しましょう",
];
