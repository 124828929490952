import styled from "styled-components";
import {
  CONNECT_MEMBER_COLOR,
  DISABLED_COLOR,
  LINK_TEXT_COLOR,
  PRIMARY_COLOR,
} from "../../../../common/constant/colors";
import { ButtonProps } from "../Button";
import { TextButtonProps } from "../TextButton";

export const FilledButton = styled.div<Omit<ButtonProps, "text">>`
  text-align: center;
  margin: 0 10px;
  padding: 12px 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: ${(props) =>
    props.disabled
      ? DISABLED_COLOR
      : props.color
        ? props.color
        : PRIMARY_COLOR};
  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    background-color: ${(props) =>
      props.disabled ? "" : props.color ?? PRIMARY_COLOR};
    opacity: ${(props) => (props.disabled ? "" : "0.8")};
  }
`;

export const BorderButton = styled.div<Omit<ButtonProps, "text">>`
  text-align: center;
  margin: 0 10px;
  padding: 11px 0;
  font-size: 16px;
  font-weight: ${(props) => props.fontWeight || "normal"};
  border: ${(props) =>
    `1px solid ${props.disabled ? DISABLED_COLOR : props.color ?? "#000"}`};
  border-radius: 8px;
  color: ${(props) =>
    props.disabled ? DISABLED_COLOR : props.color ?? "#000"};
  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    color: ${(props) => (props.disabled ? "" : "#000")};
    opacity: 0.8;
  }
`;

export const FilledMemberButton = styled.div<Omit<ButtonProps, "text">>`
  text-align: center;
  margin: 0 10px;
  padding: 12px 0;
  width: 90%;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  background-color: ${(props) =>
    props.disabled ? DISABLED_COLOR : props.color ?? CONNECT_MEMBER_COLOR};
  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    background-color: ${(props) =>
      props.disabled ? "" : props.color ?? CONNECT_MEMBER_COLOR};
    opacity: ${(props) => (props.disabled ? "" : "0.8")};
  }
`;

export const StyledTextButton = styled.div<Omit<TextButtonProps, "text">>`
  text-align: center;
  text-decoration: underline;
  margin: 0 10px;
  padding: 10px 0;
  color: ${(props) =>
    props.disabled ? DISABLED_COLOR : props.color ?? LINK_TEXT_COLOR};
  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: 0.6;
  }
`;

export const ButtonSubtext = styled.div`
  margin: 0 10px;
  color: #999999;
  font-size: 12px;
  text-align: left;
`;

export const StyledLINEButton = styled.div`
  display: flex;
  width: 100px;
  margin: 10px auto 10px auto;
  padding: 7px 20px 3px 15px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  background-color: #35c755;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

export const LINELink = styled.div`
  text-decoration: none;
`;

export const StyledLINEButtonText = styled.div`
  margin-top: 3px;
`;
