import styled from "styled-components";

export const Container = styled.div`
  height: 80vh;
  display: flex;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;
