export const EMAIL_MAX_LENGTH = 319;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 19;
export const EMAIL_VALIDATION_TEXT = "メールアドレスを入力してください";
export const PASSWORD_VALIDATION_TEXT = "パスワードを入力してください";
export const MIN_LENGTH_VALIDATION_TEXT = "パスワードは{min}文字以上です";
export const MAX_LENGTH_VALIDATION_TEXT = "{max}文字以内で入力してください";
export const MIN_MAX_LENGTH_VALIDATION_TEXT =
  "{min}-{max}文字以内で入力してください";
export const FORGOT_PASSWORD_EMAIL_SUBTEXT =
  "※登録しているメールアドレスを入力して「送信」ボタンをタップしてください。届いたメールに記載しているURLをクリックし、パスワード変更を行ってください。";
export const MEMBER_REGISTRATION_EMAIL_SUBTEXT =
  "※メールアドレスを入力して「送信」ボタンをタップしてください。届いたメールに記載しているURLをクリックし、新規会員登録を行ってください。";
export const MEMBER_REGISTRATION_UPDATE_EMAIL_SUBTEXT =
  "※メールアドレスを入力して「送信」ボタンをタップしてください。届いたメールに記載しているURLをクリックし、メールアドレス変更を行ってください。";
export const PASSWORD_NOT_MATCH_VALIDATION_TEXT =
  "パスワードとパスワード（確認用）が一致しません";
export const MEMBER_REGISTRATION_PASSWORD_SUBTEXT =
  "パスワードは大文字、小文字、数字、記号の組合せの8桁以上20桁未満で入力して下さい。 記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。";
export const RESET_PASSWORD_SUBTEXT =
  "パスワードは大文字、小文字数字の組み合わせの8桁以上20桁未満で入力してください";
