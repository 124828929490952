import { LoginValidationSchema } from "../validation";
import {
  LOGIN_FAILURE,
  LOGIN_SET_EMAIL,
  LOGIN_SET_PASSWORD,
  LOGIN_START,
  LOGIN_SUCCESS,
} from "./actions";
import { LoginState } from "./type";

export const initialState: LoginState = {
  loading: false,
  email: "",
  emailError: null,
  password: "",
  passwordError: null,
  enabled: false,
};

export const reducer = (state = initialState, action: any) => {
  let resetPasswordInfo;
  switch (action.type) {
    case LOGIN_SET_EMAIL:
      resetPasswordInfo = LoginValidationSchema.safeParse({
        email: action.payload,
        password: state.password,
      });
      if (resetPasswordInfo.success) {
        return {
          ...state,
          email: action.payload,
          emailError: null,
          passwordError: null,
          enabled: true,
        };
      } else {
        return {
          ...state,
          email: action.payload,
          emailError:
            resetPasswordInfo.error.formErrors.fieldErrors["email"]?.[0] ??
            resetPasswordInfo.error.formErrors.formErrors?.[0] ??
            null,
          enabled: false,
        };
      }
    case LOGIN_SET_PASSWORD:
      resetPasswordInfo = LoginValidationSchema.safeParse({
        email: state.email,
        password: action.payload,
      });

      if (resetPasswordInfo.success) {
        return {
          ...state,
          emailError: null,
          password: action.payload,
          passwordError: null,
          enabled: true,
        };
      } else {
        return {
          ...state,
          password: action.payload,
          passwordError:
            resetPasswordInfo.error.formErrors.fieldErrors["password"]?.[0] ??
            resetPasswordInfo.error.formErrors.formErrors?.[0] ??
            null,
          enabled: false,
        };
      }
    case LOGIN_START:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return { ...state, loading: false };
    case LOGIN_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
