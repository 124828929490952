import { TextField as MUITextField, styled } from "@mui/material";
import React, { HTMLInputTypeAttribute } from "react";
import { IconEye } from "../../../common/icons/IconEye";
import { useTextField } from "./hook";
import {
  DisabledTextField,
  ErrorMessage,
  InputLabel,
  RequiredLabel,
  TextInputSubtext,
  ToggleShowPasswordButton,
} from "./style";

type Props = {
  type?: HTMLInputTypeAttribute;
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  subtext?: string;
  error?: string | null;
  disabled?: boolean;
  value?: string;
  disactived?: boolean;
  onInput?: (value: string) => void;
};

const MuiInput = styled(MUITextField)(({ theme }) => ({
  width: "100%",
  margin: "12px 0px",

  "&.MuiTextField-root": {
    input: {
      padding: "12.5px 0 11.5px 10.5px",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#999",
    },
  },
}));
export const TextField: React.FC<Props> = ({
  type = "text",
  name,
  label,
  placeholder,
  required,
  subtext,
  error,
  disabled,
  value,
  disactived,
  onInput,
}) => {
  const {
    inputType,
    showError,
    handleOnInput,
    showPassword,
    handleToggleShowPassword,
  } = useTextField(type, onInput);
  return (
    <div style={{ margin: label && "16px 0" }}>
      {label && (
        <InputLabel>
          <div>{label}</div>
          {required && !disabled && <RequiredLabel>必須</RequiredLabel>}
        </InputLabel>
      )}

      <div style={{ position: "relative" }}>
        {!disabled && (
          <>
            {type === "password" && (
              <ToggleShowPasswordButton onClick={handleToggleShowPassword}>
                <IconEye open={showPassword} />
              </ToggleShowPasswordButton>
            )}
            <MuiInput
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
              }}
              type={inputType}
              placeholder={placeholder}
              onChange={handleOnInput}
              value={value}
              disabled={disactived}
            />
          </>
        )}

        {disabled && <DisabledTextField>{value}</DisabledTextField>}
      </div>
      {error && showError && <ErrorMessage>{error}</ErrorMessage>}
      {subtext && <TextInputSubtext>{subtext}</TextInputSubtext>}
    </div>
  );
};
