export const ADD_DISPLAYED_REWARD = "ADD_DISPLAYED_REWARD";
export const REMOVE_DISPLAYED_REWARD = "REMOVE_DISPLAYED_REWARD";

export const addDisplayReward = (
  reward_id: string,
  user_id: string,
  tenant_code: string,
) => ({
  type: ADD_DISPLAYED_REWARD,
  payload: { reward_id, user_id, tenant_code },
});
export const removeDisplayReward = (
  reward_id: string,
  user_id: string,
  tenant_code: string,
) => ({
  type: REMOVE_DISPLAYED_REWARD,
  payload: { reward_id, user_id, tenant_code },
});
