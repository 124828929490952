import React from "react";

export const IconMenu: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <path
        id="Path_1171"
        data-name="Path 1171"
        d="M9,13.5H27m-18,7H27m-18,7H27"
        transform="translate(-8 -12.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
