import create from "zustand";
import { redux } from "zustand/middleware";
import { memberApi } from "../../../../core/http/openAPIClient";
import { log } from "../../../../lib/hooks/useLogMiddleware";
import {
  loginFailure,
  loginSetEmail,
  loginSetPassword,
  loginSuccess,
} from "./actions";
import { initialState, reducer } from "./reducers";

const useLoginStore = create(log(redux(reducer, initialState)));

export const setLoginEmail = (email: string) => {
  const dispatch = useLoginStore.getState().dispatch;
  dispatch(loginSetEmail(email));
};
export const setLoginPassword = (password: string) => {
  const dispatch = useLoginStore.getState().dispatch;
  dispatch(loginSetPassword(password));
};

export const onLogin = (
  tenant_code: string,
  onSuccess: (data: any) => void,
) => {
  const dispatch = useLoginStore.getState().dispatch;
  const email = useLoginStore.getState().email;
  const password = useLoginStore.getState().password;

  memberApi
    .loginApiV1TenantCodeMembersLoginPost(tenant_code, {
      tenant_code,
      email,
      password,
    })
    .then((res) => {
      onSuccess(res);
      dispatch(loginSuccess(res));
    })
    .catch((e) => dispatch(loginFailure(e)));
};

export default useLoginStore;
