import dayjs from "dayjs";
import "dayjs/locale/ja";

dayjs.locale("ja");

export const formatDate = (date: string) => {
  return dayjs(date).format("YYYY/MM/DD (ddd)");
};

export const formatDateTime = (date: string) => {
  return dayjs(date).format("YYYY/MM/DD (ddd) HH:mm");
};

export const isWithinThreeDays = (targetDateStr: string): boolean => {
  const targetDate = new Date(targetDateStr);
  const now = new Date();

  const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
  return (
    targetDate.getTime() > now.getTime() &&
    targetDate.getTime() - now.getTime() <= threeDaysInMs
  );
};

export const isFutureDate = (targetDateStr: string): boolean => {
  const targetDate = new Date(targetDateStr);
  const now = new Date();

  return targetDate.getTime() > now.getTime();
};

export const isWithinPeriod = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();

  return (
    startDate.getTime() <= now.getTime() && now.getTime() <= endDate.getTime()
  );
};

export const isFutureStartDate = (start: string) => {
  const startDate = new Date(start);
  const now = new Date();

  return startDate.getTime() > now.getTime();
};
