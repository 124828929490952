import axios, { AxiosError, AxiosResponse } from "axios";
import { AppConfig } from "../../AppConfig";
import { onLogout } from "../../components/features/menu/action";
import {
  ErrorTexts,
  NETWORK_ERROR,
  RETRY_DELAY,
  RETRY_MAX_COUNT,
} from "../../lib/error/constants/Error";
import {
  Error401ModaState,
  ErrorModaState,
  useErrorModal,
} from "../../lib/error/store";
import {
  AssetsApi,
  CampaignsApi,
  MemberNotificationsApi,
  MembersApi,
  NotificationsApi,
  OauthApi,
  PointApi,
  RewardsApi,
  TenantsApi,
} from "../api/api";
import { Configuration } from "../api/configuration";
import { LocalStorageManager } from "../storage/LocalStorageManager";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE}`;

const configuration = new Configuration({
  basePath: API_BASE_URL,
});

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  retries: RETRY_MAX_COUNT,
  retryCount: 0,
});

// リクエスト前の処理
axiosInstance?.interceptors.request.use((config) => {
  const accessToken = LocalStorageManager.Instance.getObject<string>(
    AppConfig.Instance.LocalStorageKey["accessToken"],
  );
  if (config.useAuth !== false) {
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  }

  return config;
});

axiosInstance?.interceptors.response.use(
  (config) => requestSuccess(config),
  (config) => requestFailure(config),
);
const requestSuccess = (config: AxiosResponse<any, any>) => {
  // ここでAPIの共通処理を描く
  return config;
};
export const requestFailure = (config: AxiosError) => {
  // タイムアウト(ECONNABORTED) もしくは 5xx エラーのリトライ処理
  if (
    config.code === "ECONNABORTED" ||
    config.response?.status.toString().substring(0, 1) === "5"
  ) {
    if (
      config.config &&
      (config.config.retries ?? 0) > (config.config.retryCount ?? 0)
    ) {
      config.config.retryCount = (config.config.retryCount ?? 0) + 1;
      return new Promise((resolve) => {
        setTimeout(() => {
          if (config.config) {
            resolve(axiosInstance?.request(config.config));
          }
        }, RETRY_DELAY);
      });
    }
  }

  // APIのエラーの共通処理
  const isPAssError = config?.config?.url?.indexOf(
    "/members/password_reset/send",
  );
  if (isPAssError !== undefined && isPAssError >= 0) {
    // パスワード忘れのエラー（基本的に200を返すのでありえないが、サーバーが落ちた時など）
    useErrorModal.getState().showData({
      ...ErrorModaState,
      title: ErrorTexts.PasswordForgetError.title,
      contents: ErrorTexts.PasswordForgetError.contents(
        (config?.response?.data as any)["error-id"],
        config?.response?.status,
      ),
      buttons: [
        {
          name: "閉じる",
          callback: () => {
            useErrorModal.getState().closeModal();
          },
          styling: "border",
        },
      ],
    });
    return Promise.reject({ error: config, errorHandled: true });
  } else if (config && config.code === NETWORK_ERROR) {
    // ネットワークエラー
    useErrorModal.getState().showData({
      ...ErrorModaState,
      title: ErrorTexts.NetworkError.title,
      contents: ErrorTexts.NetworkError.contents,
      buttons: [
        {
          name: "閉じる",
          callback: () => {
            useErrorModal.getState().closeModal();
          },
          styling: "border",
        },
      ],
    });
    return Promise.reject({ error: config, errorHandled: true });
  } else if (config && config?.response?.status === 404) {
    // 404エラー
    //　アクセストークンを削除
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["accessToken"],
    );
    // エラーメッセージを表示
    useErrorModal.getState().showData({
      ...Error401ModaState,
      title: ErrorTexts.Error404.title,
      contents: ErrorTexts.Error404.contents(
        (config?.response?.data as any)["error-id"],
      ),
      buttons: [
        {
          name: "閉じる",
          callback: () => {
            useErrorModal.getState().closeModal();
            const tenant_code = location.pathname.split("/")[1];
            location.href = `/${tenant_code}/login`;
          },
          styling: "border",
        },
      ],
    });
  } else if (config && config?.response?.status === 401) {
    // 401エラー
    //　アクセストークンを削除
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["accessToken"],
    );
    // エラーメッセージを表示
    useErrorModal.getState().showData({
      ...Error401ModaState,
      title: ErrorTexts.Login401.title,
      contents: ErrorTexts.Login401.contents(
        (config?.response?.data as any)["error-id"],
      ),
      buttons: [
        {
          name: "閉じる",
          callback: () => {
            useErrorModal.getState().closeModal();
            const tenant_code = location.pathname.split("/")[1];
            location.href = `/${tenant_code}/login`;
          },
          styling: "border",
        },
      ],
    });
    return Promise.reject({ error: config, errorHandled: true });
  } else if (config && config?.response?.status === 400) {
    // tenant_codeが不正
    if (
      (config?.response?.data as any)["error-code"] === "invalid-tenant-code"
    ) {
      setTimeout(() => {
        onLogout();
        location.reload();
      }, 50);
    } else if (
      (config?.response?.data as any)["error-code"] === "invalid-token"
    ) {
      // トークンが不正
      useErrorModal.getState().showData({
        ...ErrorModaState,
        title: ErrorTexts.Error400.title,
        contents: ErrorTexts.Error400Token.contents(
          (config?.response?.data as any)["error-id"],
        ),
        buttons: [
          {
            name: "閉じる",
            callback: () => {
              useErrorModal.getState().closeModal();
            },
            styling: "border",
          },
        ],
      });
    } else if (
      (config?.response?.data as any)["error-code"] === "invalid-already-registered-email"
    ) {
      // メールアドレスがすでに登録済み
      useErrorModal.getState().showData({
        ...ErrorModaState,
        title: ErrorTexts.MemberRegisteredError.title,
        contents: ErrorTexts.MemberRegisteredError.contents(
          (config?.response?.data as any)["error-id"],
        ),
        buttons: [
          {
            name: "閉じる",
            callback: () => {
              useErrorModal.getState().closeModal();
            },
            styling: "border",
          },
        ],
      });
    } else {
      // その他エラー
      useErrorModal.getState().showData({
        ...ErrorModaState,
        title: ErrorTexts.Error400.title,
        contents: ErrorTexts.Error400.contents(
          (config?.response?.data as any)["error-id"],
        ),
        buttons: [
          {
            name: "閉じる",
            callback: () => {
              useErrorModal.getState().closeModal();
            },
            styling: "border",
          },
        ],
      });
    }
  } else if (config && config?.response?.status === 500) {
    // その他エラー
    useErrorModal.getState().showData({
      ...ErrorModaState,
      title: ErrorTexts.Error500.title,
      contents: ErrorTexts.Error500.contents(
        (config?.response?.data as any)["error-id"],
      ),
      buttons: [
        {
          name: "閉じる",
          callback: () => {
            useErrorModal.getState().closeModal();
          },
          styling: "border",
        },
      ],
    });
  }
  return Promise.reject({ error: config, errorHandled: true });
};

const assetApi = new AssetsApi(configuration, "", axiosInstance);
const campaignApi = new CampaignsApi(configuration, "", axiosInstance);
const memberApi = new MembersApi(configuration, "", axiosInstance);
const memberNotificationApi = new MemberNotificationsApi(
  configuration,
  "",
  axiosInstance,
);
const notificationApi = new NotificationsApi(configuration, "", axiosInstance);
const oauthApi = new OauthApi(configuration, "", axiosInstance);
const pointApi = new PointApi(configuration, "", axiosInstance);
const rewardApi = new RewardsApi(configuration, "", axiosInstance);
const tenantApi = new TenantsApi(configuration, "", axiosInstance);

export {
  assetApi,
  campaignApi,
  memberApi,
  memberNotificationApi,
  notificationApi,
  oauthApi,
  pointApi,
  rewardApi,
  tenantApi,
};
