import create from "zustand";
import { redux } from "zustand/middleware";
import { memberApi } from "../../../../core/http/openAPIClient";
import { LocalStorageManager } from "../../../../core/storage/LocalStorageManager";
import { log } from "../../../../lib/hooks/useLogMiddleware";
import { GENDER_EXCHANGE_SELECTION_OPTIONS_NUMBER } from "../constant";
import {
  clearMemberInfo,
  memberData,
  memberRegistrationEmailFailure,
  memberRegistrationEmailSetEmail,
  memberRegistrationEmailStart,
  memberRegistrationEmailSuccess,
  memberRegistrationFailure,
  memberRegistrationResetPassword,
  memberRegistrationSetConfirmPassword,
  memberRegistrationSetEmail,
  memberRegistrationSetGender,
  memberRegistrationSetLineServiceId,
  memberRegistrationSetMembershipNumber,
  memberRegistrationSetMonth,
  memberRegistrationSetPassword,
  memberRegistrationSetToken,
  memberRegistrationSetYear,
  memberRegistrationSuccess,
} from "./actions";
import { initialState, reducer } from "./reducers";
import { MemberData } from "./type";

const useMemberRegistrationStore = create(log(redux(reducer, initialState)));

export const setMemberRegistrationEmail = (email: string, isEdit?: boolean) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetEmail(email, isEdit));
};
export const setMemberRegistrationGender = (
  gender: number,
  isEdit?: boolean,
  originData?: MemberData,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetGender(gender, isEdit, originData));
};
export const setMemberRegistrationYear = (
  year: number,
  isEdit?: boolean,
  originData?: MemberData,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetYear(year, isEdit, originData));
};
export const setMemberRegistrationMonth = (
  month: number,
  isEdit?: boolean,
  originData?: MemberData,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetMonth(month, isEdit, originData));
};
export const setMemberRegistrationPassword = (
  password: string,
  originData?: MemberData,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetPassword(password, originData));
};
export const setMemberRegistrationConfirmPassword = (
  password: string,
  originData?: MemberData,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetConfirmPassword(password, originData));
};
export const setMemberRegistrationMembershipNumber = (
  membershipNumber: string,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetMembershipNumber(membershipNumber));
};
export const setMemberRegistrationLineServiceId = (lineServiceId: string) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetLineServiceId(lineServiceId));
};
export const setMemberRegistrationToken = (token: string) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationSetToken(token));
};
export const setEmailMemberRegistrationEmail = (email: string) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationEmailSetEmail(email));
};

export const resetPassword = () => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberRegistrationResetPassword());
};

export const sendMemberRegistrationEmail = (
  tenant_code: string,
  mode: string,
) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  const email = useMemberRegistrationStore.getState().email;
  dispatch(memberRegistrationEmailStart());
  return memberApi
    .sendEmailApiV1TenantCodeMembersVerificationEmailSendPost(tenant_code, {
      tenant_code,
      email,
      mode,
      redirect_url:
        mode === "register"
          ? `${location.protocol}//${location.host}/${tenant_code}/register/1`
          : `${location.protocol}//${location.host}/${tenant_code}/change_email`,
    })
    .then((res) => {
      dispatch(memberRegistrationEmailSuccess(res));
      return new Promise((resolve) => resolve(res));
    })
    .catch((e) => {
      dispatch(memberRegistrationEmailFailure(e));
      return new Promise((_, reject) => reject(e));
    });
};

export const onMemberRegistration = (tenant_code: string) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  const email = useMemberRegistrationStore.getState().email;
  const gender = useMemberRegistrationStore.getState().gender;
  const birth_year = useMemberRegistrationStore.getState().year;
  const birth_month = useMemberRegistrationStore.getState().month;
  const password = useMemberRegistrationStore.getState().password;
  const member_code = useMemberRegistrationStore.getState().membershipNumber;
  const line_service_id = useMemberRegistrationStore.getState().lineServiceId;
  const token = useMemberRegistrationStore.getState().token;

  return memberApi
    .memberRegisterApiV1TenantCodeMembersRegisterPost(tenant_code, {
      tenant_code,
      token,
      gender: gender === 1 ? "male" : gender === 2 ? "female" : "not_specified",
      birth_year,
      birth_month,
      password,
      member_code,
      line_service_id: line_service_id ? line_service_id : undefined,
    })
    .then((res) => {
      dispatch(memberRegistrationSuccess(res));
      return new Promise((resolve) => resolve(res));
    })
    .catch((e) => {
      dispatch(memberRegistrationFailure(e));
      return new Promise((_, reject) => reject(e));
    });
};

export const updateMemberInfomation = (tenant_code: string, token?: string) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  // const email = useMemberRegistrationStore.getState().email;
  const gender = useMemberRegistrationStore.getState().gender;
  const birth_year = useMemberRegistrationStore.getState().year;
  const birth_month = useMemberRegistrationStore.getState().month;
  const password = useMemberRegistrationStore.getState().password;
  const member_code = useMemberRegistrationStore.getState().membershipNumber;

  const body: any = {
    // tenant_code,
    token,
    gender:
      gender === null
        ? undefined
        : GENDER_EXCHANGE_SELECTION_OPTIONS_NUMBER[gender - 1],
    birth_year: birth_year === null ? undefined : birth_year,
    birth_month: birth_month === null ? undefined : birth_month,
    // password,
    member_code: member_code === "" ? undefined : member_code,
  };

  if (password !== "") {
    body["password"] = password;
  }

  return memberApi
    .updateMemberApiV1TenantCodeMembersUpdatePost(tenant_code, body)
    .then((res) => {
      dispatch(memberRegistrationSuccess(res));
      return new Promise((resolve) => resolve(res));
    })
    .catch((e) => {
      dispatch(memberRegistrationFailure(e));
      return new Promise((resolve) => resolve(e));
    });
};

export const saveMemberData = (uuid: string) => {
  const memberData: MemberData = {
    email: useMemberRegistrationStore.getState().email,
    gender: useMemberRegistrationStore.getState().gender,
    year: useMemberRegistrationStore.getState().year,
    month: useMemberRegistrationStore.getState().month,
    password: useMemberRegistrationStore.getState().password,
    confirmPassword: useMemberRegistrationStore.getState().confirmPassword,
    membershipNumber: useMemberRegistrationStore.getState().membershipNumber,
    lineServiceId: useMemberRegistrationStore.getState().lineServiceId,
    token: useMemberRegistrationStore.getState().token,
  };
  LocalStorageManager.Instance.saveObject(uuid, memberData);
};

export const remapMemberData = (uuid: string) => {
  const memberData: MemberData | null =
    LocalStorageManager.Instance.getObject(uuid);
  return memberData;
};

export const removeMemberData = (uuid: string) => {
  LocalStorageManager.Instance.removeObject(uuid);
};

export const setMemberData = (data: MemberData) => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(memberData(data));
};

export const clearMember = () => {
  const dispatch = useMemberRegistrationStore.getState().dispatch;
  dispatch(clearMemberInfo());
};
export default useMemberRegistrationStore;
