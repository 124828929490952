import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Clock } from "../../../../common/icons/Clock";
import { onSuccessAppliedPoint } from "../../../../common/utils/campaign";
import { formatDateTime, isFutureDate } from "../../../../common/utils/date";
import { settingStore } from "../../../template/store/setting";
import { Modal } from "../../../ui";
import { ModalCoupon } from "../../../ui/modal/component/ModalCoupon";
import { ModalPoint } from "../../../ui/modal/component/ModalPoint";
import { ModalRoulette } from "../../../ui/modal/component/ModalRoulette";
import { Reward } from "../campaign.type";
import {
  CampaignCard,
  CampaignCardImage,
  CampaignCardName,
  CampaignCardNameWrapper,
  CampaignCardText,
  CampaignDetailLink,
  CampaignSpanTitle,
  RewardDescription,
  RewardUsed,
  RewardUsedBg,
  RewardUsedTxt,
} from "../style/campaign-earned-card";
import { applyPointData } from "./../redux/earnedCampaign/store";

interface RewardModalProps {
  subTitle: string;
  title: string;
  contents: string;
  janCode: string;
  codeType: "barcode" | "none" | "qr";
  isShowCoupon: boolean;
  isShowPoint: boolean;
  onClose?: () => void;
  buttons?: {
    name: string;
    callback?: () => void;
    styling?: "filled" | "border";
    color?: string;
  }[];
}

interface ModalRouletteProps {
  rewardId: number;
  isShow: boolean;
  image_url: string;
  content: any;
  point_auto_assignment: boolean;
  onClose: () => void;
}

type Props = {
  reward: Reward;
};

export const RewardCard: React.FC<Props> = (props) => {
  // state
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [showRewardModal, setShowRewardModal] = useState<RewardModalProps>({
    subTitle: "",
    title: "",
    contents: "",
    janCode: "",
    codeType: "none",
    isShowCoupon: false,
    isShowPoint: false,
  });
  const [showRoulette, setShowRoulette] = useState<ModalRouletteProps>({
    rewardId: 0,
    isShow: false,
    image_url: "",
    content: {},
    point_auto_assignment: false,
    onClose: () => {
      /**/
    },
  });
  const themeColor = settingStore((store) => store.theme_color);

  const { tenant_code } = useParams();
  const closePointModal = useCallback(() => {
    setShowRewardModal({
      subTitle: "",
      title: "",
      contents: "",
      janCode: "",
      codeType: "none",
      isShowCoupon: false,
      isShowPoint: false,
    });
  }, []);

  const onClickUseCoupon = useCallback(() => {
    if (props.reward.reward_type === "coupon") {
      setShowRewardModal({
        subTitle: props.reward.description,
        title: props.reward.name,
        contents: props.reward.term_of_use,
        janCode: props.reward.code,
        codeType: props.reward.code_type,
        isShowCoupon: true,
        isShowPoint: false,
      });
    }
    if (props.reward.reward_type === "point") {
      setShowRewardModal({
        subTitle: props.reward.description,
        title: props.reward.name,
        contents: props.reward.term_of_use,
        janCode: "",
        codeType: props.reward.code_type,
        isShowCoupon: false,
        isShowPoint: true,
        buttons: [
          {
            name: "ポイント反映",
            callback: () =>
              applyPointData(tenant_code ?? "", props.reward.id, () => {
                onSuccessAppliedPoint(tenant_code ?? "");
              }),
          },
        ],
      });
    }
    closeModal();
  }, [tenant_code]);

  const closeModal = useCallback(() => {
    setIsShowModal(false);
  }, []);

  const onClickConfirmUseCoupon = useCallback(() => {
    setIsShowModal(true);
  }, []);

  const onClickLottery = useCallback((banner_image_url: string) => {
    setShowRoulette({
      rewardId: props.reward.id,
      isShow: true,
      content: "特典GET！",
      image_url: banner_image_url,
      point_auto_assignment: props.reward.point_auto_assignment,
      onClose: () => {
        onCloseLottery();
      },
    });
  }, []);

  const onCloseLottery = useCallback(() => {
    setShowRoulette({
      rewardId: 0,
      isShow: false,
      image_url: "",
      content: {},
      point_auto_assignment: false,
      onClose: () => {
        /**/
      },
    });
  }, []);

  const modalProps = {
    isShow: isShowModal,
    title: "特典を利用しますか？",
    subTitle: "",
    contents: "",
    onClose: closeModal,
    buttons: [
      {
        name: "特典を利用",
        callback: () => {
          onClickUseCoupon();
        },
      },
    ],
  };
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current as any;
    if (textElement) {
      const textWidth = textElement.scrollWidth; // Get text width
      const containerWidth = textElement.offsetWidth; // Get div width
      if (textWidth > containerWidth) {
        const truncatedText = `${props.reward.name.slice(0, containerWidth - 3)}...`; // Truncate with ellipsis
        textElement.textContent = truncatedText;
      }
    }
  }, [props.reward.name]);

  return (
    <>
      <CampaignCard>
        <CampaignCardImage
          src={
            props.reward.is_lottery
              ? "/image/lottery_image.svg"
              : props.reward.banner_image_url
          }
        />
        <CampaignCardNameWrapper>
          <CampaignCardName ref={textRef}>
            {props.reward.is_lottery && (
              <>
                <CampaignSpanTitle>抽選で特典が当たる！</CampaignSpanTitle>
                <br />
              </>
            )}

            {props.reward.name}
          </CampaignCardName>
          <RewardDescription>
            {props.reward.description && <>{props.reward.description}</>}
          </RewardDescription>
        </CampaignCardNameWrapper>

        <CampaignCardText>
          <Clock style={{ margin: "0 5px", verticalAlign: "middle" }} />
          {formatDateTime(props.reward.end_at)}まで
        </CampaignCardText>
        {!props.reward.is_lottery &&
          isFutureDate(props.reward.used_at) &&
          isFutureDate(props.reward.end_at) && (
            <CampaignDetailLink
              color={themeColor}
              onClick={onClickConfirmUseCoupon}
            >
              特典を利用
            </CampaignDetailLink>
          )}
        {props.reward.is_lottery &&
          isFutureDate(props.reward.used_at) &&
          isFutureDate(props.reward.end_at) && (
            <CampaignDetailLink
              color={themeColor}
              onClick={() => onClickLottery(props.reward.banner_image_url)}
            >
              抽選を行う
            </CampaignDetailLink>
          )}

        {!isFutureDate(props.reward.used_at) && (
          <RewardUsed color={themeColor} onClick={() => onClickUseCoupon()}>
            <RewardUsedBg />
            <RewardUsedTxt>特典利用済み</RewardUsedTxt>
          </RewardUsed>
        )}

        {isFutureDate(props.reward.used_at) &&
          !isFutureDate(props.reward.end_at) && (
            <RewardUsed color={themeColor}>
              <RewardUsedBg />
              <RewardUsedTxt>特典利用期限切れ</RewardUsedTxt>
            </RewardUsed>
          )}
      </CampaignCard>
      <ModalPoint
        {...showRewardModal}
        usedAt={props.reward.used_at}
        onClose={closePointModal}
      />
      <ModalCoupon
        usedAt={props.reward.used_at}
        {...showRewardModal}
        onClose={closePointModal}
      />
      <ModalRoulette {...showRoulette} />
      <Modal {...modalProps} />
    </>
  );
};
