import React, { useCallback, useEffect } from "react";
import { HeaderContainer } from "../../../../common/style";
import { formatDateTime } from "../../../../common/utils/date";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { useNotification } from "../hook/useNotification";
import { useReaded } from "../hook/useReaded";
import {
  NoNotification,
  NoNotificationText,
  NotificationBadge,
  NotificationContent,
  NotificationDate,
  NotificationItem,
  NotificationRoot,
  NotificationTitle,
  PaginationButton,
  PaginationText,
} from "../style";

export const NotificationContainer: React.FC = () => {
  const {
    notificationsToDisplay,
    disablePrevious,
    disableNext,
    onPrevious,
    onNext,
    pageText,
  } = useNotification();
  const { checkUnread, makeKey, getUnredList, setUnread } = useReaded();

  useEffect(() => {
    return () => {
      updateUnread();
    };
  }, [notificationsToDisplay]);

  const updateUnread = useCallback(() => {
    const list = getUnredList();
    const unreadData = notificationsToDisplay.filter((notification) => {
      return !list?.includes(makeKey(notification));
    });
    unreadData.forEach((notification) => {
      setUnread(makeKey(notification));
    });
  }, [notificationsToDisplay]);

  const navigation = useNavigate();

  const onPrev = useCallback(() => {
    navigation("/");
  }, []);

  return (
    <NotificationRoot>
      <HeaderContainer>
        <NotificationTitle>お知らせ</NotificationTitle>
      </HeaderContainer>
      {notificationsToDisplay.length === 0 && (
        <NoNotification>
          <NoNotificationText>お知らせはありません</NoNotificationText>
        </NoNotification>
      )}
      {notificationsToDisplay.length > 0 && (
        <>
          <div>
            {notificationsToDisplay.map((notification, i) => (
              <NotificationItem key={`notification-${i}`}>
                <NotificationDate>
                  {`${formatDateTime(notification.start_at)}`}~
                  {`${formatDateTime(notification.end_at)}`}
                </NotificationDate>
                <NotificationContent>
                  {notification.content}
                  {!checkUnread(notification) && (
                    <NotificationBadge>NEW</NotificationBadge>
                  )}
                </NotificationContent>
              </NotificationItem>
            ))}
          </div>

          <div style={{ margin: "16px 0" }}>
            <PaginationText>{pageText}</PaginationText>

            <div>
              <PaginationButton
                disabled={disablePrevious}
                onClick={() => {
                  updateUnread();
                  onPrevious();
                }}
              >
                ＜前へ
              </PaginationButton>
              <PaginationButton
                disabled={disableNext}
                onClick={() => {
                  updateUnread();
                  onNext();
                }}
              >
                次へ＞
              </PaginationButton>
            </div>
          </div>
        </>
      )}
    </NotificationRoot>
  );
};
