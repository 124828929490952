import styled, { css } from "styled-components";
import { Image } from "../../ui/Image/Image";

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  height: 100svh;
  margin: auto;
`;

export const Header = styled.div<{ bg_color: string; isPreview: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  text-align: left;
  font-size: 12px;
  color: #ffffff;
  ${(props) => {
    return css`
      background-color: ${props.bg_color};
      ${props.isPreview && "pointer-events: none;"};
    `;
  }}
  flex-shrink: 0;
`;

export const HeaderTitle = styled.div`
  font-size: 0;
  font-weight: bolder;
`;

export const MenuIcon = styled.div`
  padding-top: 5px;
  text-align: center;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 100;
  background-color: #ffffff;
  color: #000000;
  font-size: 20px;
`;

export const LogoImage = styled(Image)`
  width: auto;
  height: 30px;
  margin: 5px 0;
`;
