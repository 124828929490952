import lotie from "lottie-web";
import React, { useCallback } from "react";
import { Button } from "../../../button/Button";
import {
  AnimationBg,
  IconCheck,
  Image,
  ImageContainer,
  Message,
  RedrawContainer,
} from "../../style/Roulette";
import { CloseBtn, Container, SubTitle, Title } from "../../style/style";

interface RouletteWinProps {
  close: () => void;
  content: any;
  image_url: string;
}

export const RouletteWin: React.FC<RouletteWinProps> = (props) => {
  const { close } = props;
  const onLoadImage = useCallback((className: string) => {
    const target = document
      .getElementsByClassName(className)
      .item(0) as Element;
    const anim = lotie.loadAnimation({
      container: target,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "/assets/animation/redraw/data.json",
    });
    anim.play();
  }, []);

  return (
    <Container isOpen={true} key={`roulette`}>
      <div>
        <SubTitle>{"特典抽選ルーレット"}</SubTitle>
        <Title>{"おめでとう、当たり！"}</Title>
        <CloseBtn
          onClick={() => {
            props.close();
          }}
        >
          <img src="/assets/ui/modal/close.svg" alt="close" />
        </CloseBtn>
      </div>

      <RedrawContainer>
        <AnimationBg className={`result_win_anim`}></AnimationBg>
        <ImageContainer>
          <Image
            onLoad={() => {
              onLoadImage(`result_win_anim`);
            }}
            src={props.image_url ?? ""}
            alt="sample"
            width="100%"
            height="auto"
          />
        </ImageContainer>
        <IconCheck src="/assets/ui/modal/icon_check.svg" />
        {props.content !== "" && <Message>{props.content}</Message>}
      </RedrawContainer>

      <Button
        text={"閉じる"}
        styling={"border"}
        color={"#000000"}
        onClick={() => close()}
      />
    </Container>
  );
};
