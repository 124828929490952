import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { EditMemberContainer } from "../../features/member_registration/container";
import useMemberRegistrationStore, {
  updateMemberInfomation,
} from "../../features/member_registration/redux/store";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { Modal } from "../../ui";
import { Footer } from "../../ui/footer/Footer";
import { ModalProps } from "../../ui/modal/types";
import { fetchAssets } from "../../../lib/asset/redux/store";

const defaultModalProps: ModalProps = {
  isShow: false,
  title: "",
  subTitle: "",
  image_url: "",
  icon: <div />,
  LINE: false,
  contents: "",
  buttons: [],
  onClose: () => {
    /**/
  },
};
export const EditMember: React.FC = () => {
  const { tenant_code } = useParams();

  const enabled = useMemberRegistrationStore((state) => state.enabled);
  const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);

  const onEditMember = useCallback(() => {
    if (tenant_code)
      updateMemberInfomation(tenant_code).then((res) => {
        setModalProps({
          ...defaultModalProps,
          isShow: true,
          title: "会員情報を更新しました",
          onClose: () => {
            setModalProps(defaultModalProps);
            fetchAssets(tenant_code || "").then(() => {
              location.reload();
            });
          },
        });
      });
    else alert("登録できませんでした");
  }, [tenant_code]);

  return (
    <Base>
      <HeaderTemplate>
        <EditMemberContainer />
        <Modal {...modalProps} />
      </HeaderTemplate>
      <Footer
        buttonText="保存"
        onClickButton={() => onEditMember()}
        buttonDisabled={!enabled}
      />
    </Base>
  );
};
