import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { Button } from "../../../ui";
import { getTextImage, imageToText } from "../action";
import { CloseBtn, OCRWrapper, Title, WebcamWrapper } from "../style";

type Props = {
  onCapture: (text: string) => void;
  onClose: () => void;
  onError?: (e: string | DOMException) => void;
};

export const OCR: React.FC<Props> = ({ onCapture, onClose, onError }) => {
  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      getTextImage(imageSrc).then(async (base64Img) => {
        if (base64Img) {
          try {
            const text = await imageToText(base64Img, onClose);
            onCapture(text?.replace(/\n/g, ""));
          } catch (e) {
            console.log("error", e);
          }
        }
      });
    }
  }, [webcamRef]);

  const handleUserMediaError = (e: string | DOMException) => {
    onClose();
    if (onError) onError(e);
  };

  return (
    <OCRWrapper>
      <div>
        <Title>カメラで読み取り入力</Title>
        <CloseBtn onClick={onClose}>
          <img src="/assets/ui/modal/close.svg" alt="close" />
        </CloseBtn>
      </div>

      <WebcamWrapper>
        <Webcam
          style={{ width: "100%", height: "100%", display: "block" }}
          audio={false}
          imageSmoothing={false}
          ref={webcamRef}
          screenshotFormat={"image/png"}
          forceScreenshotSourceSize
          videoConstraints={{
            width: 960,
            height: 1080,
            facingMode: "environment",
          }}
          onUserMediaError={(e) => handleUserMediaError(e)}
        />
        <div
          style={{
            border: `1px solid #ff0000`,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%)`,
            width: "50%",
            height: "10%",
          }}
        ></div>
      </WebcamWrapper>

      <Button text="設定" onClick={capture} />
    </OCRWrapper>
  );
};
