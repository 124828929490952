import React from "react";
import { TextField } from "../../../ui";
import {
  PASSWORD_VALIDATION_TEXT,
  RESET_PASSWORD_SUBTEXT,
} from "../../../../common/constant/validations";
import useResetPasswordStore, {
  setResetPasswordConfirmPassword,
  setResetPasswordPassword,
} from "../redux/store";

export const ResetPasswordContainer: React.FC = () => {
  const password = useResetPasswordStore((state) => state.password);
  const passwordError = useResetPasswordStore((state) => state.passwordError);
  const confirmPassword = useResetPasswordStore(
    (state) => state.confirmPassword,
  );
  const confirmPasswordError = useResetPasswordStore(
    (state) => state.confirmPasswordError,
  );

  return (
    <div>
      <h3>パスワードを忘れた方</h3>

      <div style={{ margin: "10px" }}>
        <TextField
          type="password"
          name="password"
          label="新パスワード"
          required
          placeholder={PASSWORD_VALIDATION_TEXT}
          subtext={RESET_PASSWORD_SUBTEXT}
          onInput={setResetPasswordPassword}
          value={password}
          error={passwordError}
        />
        <TextField
          type="password"
          name="confirmPassword"
          label="新パスワード（確認用）"
          required
          placeholder={PASSWORD_VALIDATION_TEXT}
          onInput={setResetPasswordConfirmPassword}
          value={confirmPassword}
          error={confirmPasswordError}
        />
      </div>
    </div>
  );
};
