import React from "react";
import { BodyTextRewardContainer } from "../../features/campaign_detail/style/campaign_detail";
import { Button } from "../button/Button";
import { Bg, CloseBtn, Container, Image, Message, Title } from "./style/style";

type ModalProps = {
  isShow: boolean;
  title: string;
  subTitle: string;
  image_url?: string;
  icon?: React.ReactElement;
  LINE?: boolean;
  contents: any;
  buttons?: {
    name: string;
    callback?: () => void;
    styling?: "filled" | "border";
    color?: string;
  }[];
  onClose(): void;
  hideClose?: boolean;
};

export const RewardModal: React.FC<ModalProps> = (props) => {
  return (
    <div>
      <Bg isOpen={props.isShow} />
      <Container isOpen={props.isShow}>
        <div>
          {props.title && (
            <Title dangerouslySetInnerHTML={{ __html: props.title }}></Title>
          )}
          <CloseBtn
            onClick={() => {
              props.onClose();
            }}
          >
            <img src="/assets/ui/modal/close.svg" alt="close" />
          </CloseBtn>
        </div>
        <BodyTextRewardContainer>
          {props.image_url && (
            <Image
              src={props.image_url || "/image/dummy_banner_image.png"}
              alt="sample"
            />
          )}
          {props.contents !== "" && (
            <Message hasIcon={props.icon ? true : false}>
              {props.contents}
            </Message>
          )}
        </BodyTextRewardContainer>
        {props.hideClose !== true && (
          <Button
            text={"閉じる"}
            styling={"border"}
            color={"#000000"}
            onClick={() => props.onClose()}
          />
        )}
      </Container>
    </div>
  );
};
