import create from "zustand";
import { redux } from "zustand/middleware";
import { memberApi } from "../../../../core/http/openAPIClient";
import { log } from "../../../../lib/hooks/useLogMiddleware";
import {
  forgotPasswordFailure,
  forgotPasswordSetEmail,
  forgotPasswordStart,
  forgotPasswordSuccess,
} from "./actions";
import { initialState, reducer } from "./reducers";

const useForgotPasswordStore = create(log(redux(reducer, initialState)));

export const setForgotPasswordEmail = (email: string) => {
  const dispatch = useForgotPasswordStore.getState().dispatch;
  dispatch(forgotPasswordSetEmail(email));
};

export const onForgotPassword = (tenant_code: string) => {
  const dispatch = useForgotPasswordStore.getState().dispatch;
  const email = useForgotPasswordStore.getState().email;
  dispatch(forgotPasswordStart());

  return memberApi
    .sendPasswordResetApiV1TenantCodeMembersPasswordResetSendPost(tenant_code, {
      tenant_code,
      email,
    })
    .then((res) => {
      dispatch(forgotPasswordSuccess(res));
      return new Promise((resolve) => resolve(res));
    })
    .catch((e) => {
      dispatch(forgotPasswordFailure(e));
      return new Promise((_, reject) => reject(e));
    });
};

export default useForgotPasswordStore;
