import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Clock } from "../../../../common/icons/Clock";
import { RewardLabel } from "../../../../common/icons/RewardLabel";
import { isSameRewardPeriod } from "../../../../common/utils/campaign";
import {
  formatDateTime,
  isFutureStartDate,
  isWithinPeriod,
} from "../../../../common/utils/date";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { settingStore } from "../../../template/store/setting";
import { Button, Modal } from "../../../ui";
import { RewardModal } from "../../../ui/modal/RewardModal";
import { TargetItemModal } from "../../../ui/modal/TargetItemModal";
import { defaultModalProps } from "../../../ui/modal/initial";
import { ModalProps } from "../../../ui/modal/types";
import {
  Campaign,
  CampaignReward,
  TargetItem,
  TargetItemDetail,
} from "../../campaign/campaign.type";
import { EncourageRegisterMember } from "../../campaign/component/EncourageRegisterMember";
import { useCampaign } from "../../campaign/hooks/useCampaign";
import { MileProgressBar } from "../component/MileProgressBar";
import {
  CampaignBulletList,
  CampaignBulletListItem,
  CampaignBulletTargetItemItem,
  CampaignBulletTargetItemList,
  CampaignContentDotLine,
  CampaignContentMultipleReward,
  CampaignContentMultipleRewardLine,
  CampaignContentNotes,
  CampaignContentNotesTitle,
  CampaignContentRewardText,
  CampaignContentTargetItem,
  CampaignContentTerm,
  CampaignContentText,
  CampaignContentWrapper,
  CampaignDetailBottomButton,
  CampaignJoinButton,
  CampaignMileWrapper,
  CampaignOverViewImage,
  CampaignOverViewTitle,
  CampaignOverViewWrapper,
  CampaignPurchasedItemWrapper,
  CampaignPurchasedNoItem,
  CampaignTargetItemDateTitle,
  CampaignTargetItemWrapper,
  ContentWrapper,
  MileCard,
  MileCardText,
  MileCardTextEndAt,
  MileCardTextWrapper,
  MileCardTitle,
  RewardDetailButton,
  RewardEarnMethod,
  RewardImage,
  RewardMessage,
  RewardTitle,
  RewardWrapper,
  RewardWrapperMessage,
  SubTitleBar,
} from "../style/campaign_detail";
import {
  CampaignTargetItemButton,
  MileCardMiddleText,
  MileCardMiddleTextColored,
} from "../style/campaign_detail_participating";
import useCampaignStore from "./../../campaign/redux/campaign/store";

export const CampaignDetailContainer: React.FC = () => {
  const navigate = useNavigate();
  const campaigns = useCampaignStore((state) => state.campaigns);
  const param = useParams();

  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [targetItemCount, setTargetItemCount] = useState<number[]>([]);
  const [rewardModalProps, setRewardModalprops] =
    useState<ModalProps>(defaultModalProps);
  const campaign = campaigns.find((campaign: Campaign) => {
    return campaign.id === Number(param.campaignId);
  });
  const { tenant_code } = useParams();
  const themeColor = settingStore((store) => store.theme_color);
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const { modalProps, handleJoin, setModalProps, onClickAddLINE, onClickJoin } =
    useCampaign(campaign, tenant_code, isLogin);

  if (typeof campaign === "undefined") {
    window.location.replace(`/${tenant_code}/campaign`);
  }

  const navigateTop = () => {
    navigate(`/campaign`);
  };

  const onGoToLogin = () => {
    navigate("/login");
  };

  const onShowReward = useCallback((index: number) => {
    setRewardModalprops({
      title: campaign.rewards[index].reward_name,
      subTitle: "",
      onClose() {
        setRewardModalprops(defaultModalProps);
      },
      contents: campaign.rewards[index].description,
      image_url:
        campaign.rewards[index].banner_image_url ||
        "/image/dummy_reward_image.png",
      buttons: [],
      isShow: true,
    });
  }, []);

  const countTargetItems = () => {
    return campaign?.target_items.reduce((acc: number, targetItems: any) => {
      return acc + targetItems.items.length;
    }, 0);
  };

  useEffect(() => {
    // target itemの数をカウントする
    const countArray: number[] = [];
    let targetCount = 0;
    campaign?.target_items.map((targetItems: any, index: number) => {
      targetCount += targetItems.items.length;
      countArray.push(targetCount);
    }, 0);
    setTargetItemCount(countArray);
  }, [campaign]);

  const onShowAllItem = useCallback(() => {
    setIsShowModal(true);
  }, []);

  return (
    <>
      {!isLogin && <EncourageRegisterMember onClick={onGoToLogin} />}
      <CampaignMileWrapper>
        <MileCard>
          <MileCardTextWrapper>
            <MileCardTitle>現在の獲得マイル</MileCardTitle>
            <MileCardText>0マイル</MileCardText>
          </MileCardTextWrapper>
          <MileProgressBar
            value={0}
            max={campaign?.rewards[0]?.needed_mile || 0}
          ></MileProgressBar>
          <MileCardMiddleText>
            {campaign?.rewards[0]?.is_repetition && (
              <RewardLabel is_card={false} />
            )}
            <MileCardMiddleTextColored>
              キャンペーンに参加していません
            </MileCardMiddleTextColored>
          </MileCardMiddleText>
          {/* 参加中の場合`あと***マイルで特典GET！` */}
        </MileCard>
        {/* TODO: ステータス */}
        <MileCardTextEndAt>
          <Clock style={{ marginRight: 5, verticalAlign: "middle" }} />
          {isFutureStartDate(campaign?.implementation_period_start)
            ? formatDateTime(campaign.implementation_period_start) + "から"
            : formatDateTime(campaign.implementation_period_end) + "まで"}
        </MileCardTextEndAt>

        {isLogin &&
          isWithinPeriod(
            campaign?.implementation_period_start,
            campaign?.implementation_period_end,
          ) && (
            <CampaignJoinButton onClick={() => handleJoin()} color={themeColor}>
              キャンペーンに参加
            </CampaignJoinButton>
          )}
      </CampaignMileWrapper>
      <ContentWrapper>
        {/* Label */}
        <SubTitleBar>特典</SubTitleBar>
        {campaign?.rewards.map((reward: CampaignReward, index: number) => {
          return (
            <>
              {reward.is_lottery && (
                <RewardTitle>
                  {reward.needed_mile}マイル獲得で抽選にチャレンジ！
                </RewardTitle>
              )}
              {!reward.is_lottery && (
                <RewardTitle>
                  {reward.needed_mile}マイル獲得でゲット！
                </RewardTitle>
              )}
              <RewardWrapper key={index}>
                <RewardImage
                  src={
                    reward.banner_image_url || "/image/dummy_reward_image.png"
                  }
                />
                <RewardWrapperMessage>
                  <RewardMessage>{reward.reward_name}</RewardMessage>
                  <RewardDetailButton onClick={() => onShowReward(index)}>
                    詳細
                  </RewardDetailButton>
                </RewardWrapperMessage>
              </RewardWrapper>
            </>
          );
        })}
        {/* Label */}
        <SubTitleBar>特典獲得方法</SubTitleBar>
        <RewardEarnMethod>{campaign?.acquisition_method}</RewardEarnMethod>
      </ContentWrapper>
      <CampaignContentWrapper>
        {/* Label */}
        <SubTitleBar>キャンペーン概要</SubTitleBar>
        <CampaignOverViewImage
          src={campaign?.campaign_banner_url || "/image/dummy_banner_image.png"}
        />
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>期間：</CampaignOverViewTitle>
          <CampaignContentTerm>
            実施期間
            <CampaignContentText>
              {formatDateTime(campaign?.implementation_period_start)} 〜<br />{" "}
              {formatDateTime(campaign?.implementation_period_end)}まで
            </CampaignContentText>
          </CampaignContentTerm>
          <CampaignContentDotLine></CampaignContentDotLine>
          <CampaignContentTerm>
            特典利用期間
            {isSameRewardPeriod(campaign?.rewards) && (
              <>
                <CampaignContentText>
                  {formatDateTime(
                    campaign?.rewards[0]?.reward_validity_period_start,
                  )}{" "}
                  〜<br />
                  {formatDateTime(
                    campaign?.rewards[0]?.reward_validity_period_end,
                  )}
                  まで
                </CampaignContentText>
              </>
            )}
          </CampaignContentTerm>
          {!isSameRewardPeriod(campaign?.rewards) &&
            campaign.rewards.map((reward: CampaignReward, index: number) => {
              return (
                <>
                  <CampaignContentMultipleReward key={index}>
                    <CampaignContentRewardText>
                      {reward.reward_name}
                    </CampaignContentRewardText>
                    <CampaignContentRewardText>
                      {formatDateTime(reward?.reward_validity_period_start)} 〜
                      <br />
                      {formatDateTime(reward?.reward_validity_period_end)}
                      まで
                    </CampaignContentRewardText>
                  </CampaignContentMultipleReward>
                  <CampaignContentMultipleRewardLine></CampaignContentMultipleRewardLine>
                </>
              );
            })}
        </CampaignOverViewWrapper>
        {campaign?.campaign_content && (
          <CampaignOverViewWrapper>
            <CampaignOverViewTitle>キャンペーン内容：</CampaignOverViewTitle>
            <CampaignContentTerm>
              <CampaignContentText isFull>
                {campaign?.campaign_content}
              </CampaignContentText>
            </CampaignContentTerm>
          </CampaignOverViewWrapper>
        )}
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>最低購入金額：</CampaignOverViewTitle>
          <CampaignContentTerm>
            <CampaignContentText isFull>
              {"対象商品の1日の合計購入金額が" +
                campaign?.minimum_purchase_amount +
                "円（税込）以上"}
            </CampaignContentText>
          </CampaignContentTerm>
        </CampaignOverViewWrapper>
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>対象商品：</CampaignOverViewTitle>
          <CampaignContentTargetItem>
            <CampaignContentText>
              {campaign &&
                campaign?.target_items.map(
                  (target_item: TargetItem, index: number) => {
                    if (targetItemCount[index] > 11) return;
                    return (
                      <CampaignTargetItemWrapper key={index}>
                        <CampaignTargetItemDateTitle>
                          {formatDateTime(target_item.target_item_period_start)}
                          ~{formatDateTime(target_item.target_item_period_end)}
                          まで
                        </CampaignTargetItemDateTitle>
                        <CampaignBulletTargetItemList>
                          {target_item.items.map(
                            (item: TargetItemDetail, idx: number) => {
                              return (
                                <CampaignBulletTargetItemItem key={idx}>
                                  ・
                                  {`${item.name}（マイル付与率：購入金額の${item.rate}%）`}
                                </CampaignBulletTargetItemItem>
                              );
                            },
                          )}
                        </CampaignBulletTargetItemList>
                      </CampaignTargetItemWrapper>
                    );
                  },
                )}
              {campaign?.target_items &&
                targetItemCount[targetItemCount.length - 1] > 11 && (
                  <CampaignTargetItemButton onClick={() => onShowAllItem()}>
                    すべて見る
                  </CampaignTargetItemButton>
                )}
            </CampaignContentText>
          </CampaignContentTargetItem>
        </CampaignOverViewWrapper>
        <CampaignContentNotes>
          <CampaignContentNotesTitle>注意事項</CampaignContentNotesTitle>
          <CampaignBulletList>
            {campaign?.caution &&
              campaign?.caution
                .split("\n")
                .map((note: string, index: string) => {
                  return (
                    <CampaignBulletListItem key={index}>
                      {note}
                    </CampaignBulletListItem>
                  );
                })}
          </CampaignBulletList>
        </CampaignContentNotes>
      </CampaignContentWrapper>
      <CampaignPurchasedItemWrapper>
        {/* Label */}
        <SubTitleBar>商品購入履歴</SubTitleBar>
        <CampaignPurchasedNoItem>
          商品購入履歴は
          <br />
          ありません
        </CampaignPurchasedNoItem>
      </CampaignPurchasedItemWrapper>
      <CampaignDetailBottomButton>
        <Button
          text="キャンペーン一覧に戻る"
          styling="border"
          color="#000000"
          onClick={navigateTop}
        />
      </CampaignDetailBottomButton>
      <TargetItemModal
        isShow={isShowModal}
        title={"対象商品"}
        target_items={campaign?.target_items}
        onClose={() => setIsShowModal(false)}
      ></TargetItemModal>
      <RewardModal {...rewardModalProps} />
      <Modal {...modalProps} />
    </>
  );
};
