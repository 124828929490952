import React from "react";
import { NotificationContainer } from "../../features/notification";
import { HeaderTemplate } from "../../template";

export const Notification: React.FC = () => {
  return (
    <HeaderTemplate>
      <NotificationContainer />
    </HeaderTemplate>
  );
};
