import React, { useCallback, useEffect } from "react";
import { CloseBtn, Container, SubTitle, Title } from "../../style/style";
import { RouletteLoseAnimationBg } from "../../style/Roulette";
import { Button } from "../../../button/Button";
import lotie from "lottie-web";

interface RouletteLoseProps {
  close: () => void;
}

export const RouletteLose: React.FC<RouletteLoseProps> = ({ close }) => {
  const onLoad = useCallback(() => {
    const className = "result_lose_anim";
    const target = document
      .getElementsByClassName(className)
      .item(0) as Element;
    const anim = lotie.loadAnimation({
      container: target,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "/assets/animation/lose_result/data.json",
    });
    anim.play();
  }, []);

  return (
    <Container isOpen={true} key={`roulette`}>
      <div>
        <SubTitle>{"特典抽選ルーレット"}</SubTitle>
        <Title>{"残念、ハズレです・・・"}</Title>
        <CloseBtn
          onClick={() => {
            close();
          }}
        >
          <img src="/assets/ui/modal/close.svg" alt="close" onLoad={onLoad} />
        </CloseBtn>
      </div>
      <RouletteLoseAnimationBg className={`result_lose_anim`} />
      <Button
        text={"閉じる"}
        styling={"border"}
        color={"#000000"}
        onClick={() => close()}
      />
    </Container>
  );
};
