import { z } from "zod";
import {
  MAX_LENGTH_VALIDATION_TEXT,
  MIN_MAX_LENGTH_VALIDATION_TEXT,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_NOT_MATCH_VALIDATION_TEXT,
  PASSWORD_VALIDATION_TEXT,
} from "../../../../common/constant/validations";

export const ResetPasswordValidationSchema = z
  .object({
    password: z
      .string()
      .min(1, PASSWORD_VALIDATION_TEXT)
      .min(PASSWORD_MIN_LENGTH, {
        message: MIN_MAX_LENGTH_VALIDATION_TEXT.replace(
          "{min}",
          PASSWORD_MIN_LENGTH.toString(),
        ).replace("{max}", PASSWORD_MAX_LENGTH.toString()),
      })
      .max(PASSWORD_MAX_LENGTH, {
        message: MIN_MAX_LENGTH_VALIDATION_TEXT.replace(
          "{min}",
          PASSWORD_MIN_LENGTH.toString(),
        ).replace("{max}", PASSWORD_MAX_LENGTH.toString()),
      }),
    confirmPassword: z
      .string()
      .min(1, PASSWORD_VALIDATION_TEXT)
      .min(PASSWORD_MIN_LENGTH, {
        message: MIN_MAX_LENGTH_VALIDATION_TEXT.replace(
          "{min}",
          PASSWORD_MIN_LENGTH.toString(),
        ).replace("{max}", PASSWORD_MAX_LENGTH.toString()),
      })
      .max(PASSWORD_MAX_LENGTH, {
        message: MIN_MAX_LENGTH_VALIDATION_TEXT.replace(
          "{min}",
          PASSWORD_MIN_LENGTH.toString(),
        ).replace("{max}", PASSWORD_MAX_LENGTH.toString()),
      }),
  })
  .refine(
    (obj) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,19}$/.test(
        obj.password,
      ),
    (obj) => ({
      path: ["password"],
      message: `パスワードは大文字、小文字、数字、記号の組合せの${PASSWORD_MIN_LENGTH}桁以上${PASSWORD_MAX_LENGTH}桁未満で入力して下さい。 記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。`,
    }),
  )
  .refine(
    (obj) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,19}$/.test(
        obj.confirmPassword,
      ),
    (obj) => ({
      path: ["confirmPassword"],
      message: `パスワードは大文字、小文字、数字、記号の組合せの${PASSWORD_MIN_LENGTH}桁以上${PASSWORD_MAX_LENGTH}桁未満で入力して下さい。 記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。`,
    }),
  )
  .refine((obj) => {
    if (obj.password.length === 0 || obj.confirmPassword.length === 0)
      return true;

    return obj.password === obj.confirmPassword;
  }, PASSWORD_NOT_MATCH_VALIDATION_TEXT);
