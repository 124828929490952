import styled from "styled-components";

export const StyledLimitLabel = styled.span`
  position: relative;
  padding: 2px 8px;
  margin-right: 10px;
  background-color: #f0948c;
  border-radius: 12px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  display: inline-block;
  user-select: none;
`;
