import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { LocalStorageManager } from "../../../../core/storage/LocalStorageManager";
import useAssetStore from "../../../../lib/asset/redux/store";
import useInformationStore from "../../../../lib/information/redux/store";
import { Notification } from "../types";

export const useReaded = () => {
  const { tenant_code } = useParams();
  const notifications = useInformationStore(
    (state) => state.concatedInformationList,
  ) as Notification[];
  const member = useAssetStore((state) => state.member);
  const key = `unread-${tenant_code}-${member.id}`;

  const getUnredList = useCallback((): string[] | null => {
    return LocalStorageManager.Instance.getObject(key);
  }, []);

  const setUnread = useCallback((id: string) => {
    const list =
      (LocalStorageManager.Instance.getObject(key) as string[]) || [];
    list.push(id);
    LocalStorageManager.Instance.saveObject(key, list);
  }, []);

  const makeKey = useCallback((notification: Notification) => {
    return `${notification.start_at}-${notification.end_at}-${notification.content}`;
  }, []);

  const checkUnread = useCallback(
    (notification: Notification) => {
      const key = makeKey(notification);
      const list = getUnredList();
      return list?.includes(key);
    },
    [makeKey, getUnredList],
  );

  const hasUnread = useCallback(() => {
    const list = getUnredList();
    const unreadAry = notifications.filter((notification) => {
      return !list?.includes(makeKey(notification));
    });
    return unreadAry.length > 0;
  }, [getUnredList, notifications]);

  return {
    getUnredList,
    setUnread,
    makeKey,
    checkUnread,
    hasUnread,
  };
};
