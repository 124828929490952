import React from "react";
import { Menu } from "../../features/menu";

export const MenuPage: React.FC = () => {
  return (
    <div>
      <Menu
        onClose={() => {
          history.back();
        }}
      />
    </div>
  );
};
