export interface IAppConfig {
  LocalStorageKey: Record<string, string>;
}

export class AppConfig implements IAppConfig {
  private static _instance: IAppConfig;
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  readonly LocalStorageKey = {
    accessToken: "t__mm_access_token",
    refreshToken: "t__mm_refresh_token",
    information: "information",
    assets: "assets",
    campaigns: "campaigns",
    participatingCampaigns: "participating-campaigns",
    earnedCampaigns: "earned-campaigns",
  };
}
