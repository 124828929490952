import React, { MouseEventHandler } from "react";
import { StyledTextButton } from "./style";

export type TextButtonProps = {
  text: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  color?: string;
};

export const TextButton: React.FC<TextButtonProps> = ({
  text,
  color,
  ...props
}) => {
  return <StyledTextButton {...props}>{text}</StyledTextButton>;
};
