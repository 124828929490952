import React, { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { BtnContainer, HeaderContainer } from "../../../../common/style";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { TermOfServiceFrame } from "../style/TermOfService";
import "../style/terms_of_service.css";

type TermOfServicePros = {
  onScroll: (scroll: boolean) => void;
};

export const TermOfServiceContainer: React.FC<TermOfServicePros> = (props) => {
  const { tenant_code } = useParams();
  const navigation = useNavigate();
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const iframeRef = useRef(null);
  useEffect(() => {
    window.addEventListener("message", (e) => {
      const { data } = e;
      if (data?.message?.toString() !== undefined) {
        props.onScroll(data?.message?.toString() === "true");
      }
    });
  }, []);

  const onPrev = useCallback(() => {
    navigation("/");
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current as any;
    if (iframe) {
      try {
        iframe?.contentWindow.addEventListener("scroll", () => {
          if (
            iframe?.contentWindow?.innerHeight +
              iframe?.contentWindow?.scrollY +
              60 >=
            iframe?.contentWindow?.document?.body?.offsetHeight
          ) {
            props.onScroll(true);
          }
        });
        iframe?.contentWindow.addEventListener("load", () => {
          if (
            iframe?.contentWindow?.innerHeight +
              iframe?.contentWindow?.scrollY +
              60 >=
            iframe?.contentWindow?.document?.body?.offsetHeight
          ) {
            props.onScroll(true);
          }
        });
      } catch (e) {
        props.onScroll(true);
      }
    }
  }, [iframeRef.current]);

  return (
    <>
      <div className="text_wrapper">
        <HeaderContainer>
          {isLogin && (
            <BtnContainer
              onClick={() => {
                history.back();
              }}
            >
              <img src="/assets/common/icon_back.svg" />
            </BtnContainer>
          )}
          <h3>利用規約</h3>
        </HeaderContainer>
        <TermOfServiceFrame
          ref={iframeRef}
          src={`${process.env.REACT_APP_GCS_BASE}media/tenant/${tenant_code}/terms_of_service.html`}
        />
      </div>
    </>
  );
};
