import React from "react";
import { ImageCarousel } from "../../../ui/carousel/ImageCarousel";
import { WALKTHROUGH_IMAGES } from "../constant";
import { Container } from "../style";

type Props = {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  swiperRef: any;
};

export const WalkthroughContainer: React.FC<Props> = ({
  currentStep,
  setCurrentStep,
  swiperRef,
}) => {
  return (
    <Container>
      <ImageCarousel
        images={WALKTHROUGH_IMAGES}
        currentStep={currentStep}
        onChange={setCurrentStep}
        swiperRef={swiperRef}
      />
    </Container>
  );
};
