import { create } from "zustand";
import { ModalBtn } from "../types";

export type ModalState = {
  isShow: boolean;
  isShowLINE: boolean;
  isShowCoupon: boolean;
  isShowPoint: boolean;
  title: string;
  titleSize?: "sm" | "lg";
  contents: any;
  subTitle?: string;
  image_url?: string;
  janCode?: string;
  icon?: React.ReactElement;
  buttons?: Array<ModalBtn>;
  isShowCloseButton?: boolean;
  closeText?: string;
  useAnimation?: boolean;
  reward_id?: string;
};

type ModalRewardState = {
  rewardArray: Array<ModalState>;
  setRewardArray: (data: Array<ModalState>) => void;
  closeReward: () => void;
};

type ModalRouletteState = {
  roulette?: {
    isShow: boolean;
    rewardId: number;
    contents: any;
    image_url: string;
  };
};

type ModalAction = {
  showLINE: (flg: boolean) => void;
  showCoupon: (flg: boolean) => void;
  showPoint: (flg: boolean) => void;
  showData: (data: ModalState) => void;
  showRoulette: (
    rewardId: number,
    isShow: boolean,
    content: any,
    image_url: string,
  ) => void;
  closeModal: () => void;
};

export const useModal = create<
  ModalState & ModalRewardState & ModalRouletteState & ModalAction
>()((set) => {
  return {
    isShow: false,
    isShowLINE: false,
    isShowCoupon: false,
    isShowPoint: false,
    title: "",
    subTitle: "",
    image_url: "",
    titleSize: "lg",
    janCode: "",
    contents: "",
    buttons: [],
    rewardArray: [],
    closeText: "閉じる",
    isShowCloseButton: true,
    roulette: {
      isShow: false,
      rewardId: 0,
      contents: null,
      image_url: "",
    },
    useAnimation: false,
    showLINE: (flg) => set({ isShowLINE: flg }),
    showCoupon: (flg) => set({ isShowCoupon: flg }),
    showPoint: (flg) => set({ isShowPoint: flg }),
    showData: (data) => set({ ...data }),
    setRewardArray: (rewardArray: any) => set({ rewardArray: rewardArray }),
    closeReward: () =>
      set((state: any) => ({ rewardArray: state.rewardArray.slice(1) })),
    showRoulette: (rewardId, isShow, contents, image_url) =>
      set({ roulette: { isShow, rewardId, contents, image_url } }),
    closeModal: () =>
      set({
        isShow: false,
        isShowLINE: false,
        isShowCoupon: false,
        isShowPoint: false,
        title: "",
        contents: "",
        buttons: [],
        subTitle: "",
        image_url: "",
        janCode: "",
        rewardArray: [],
        useAnimation: false,
        closeText: "閉じる",
        roulette: {
          isShow: false,
          rewardId: 0,
          contents: null,
          image_url: "",
        },
      }),
  };
});
