import {
  CLEAR_INFORMATION_DATA,
  FETCH_ADMIN_INFORMATION_DATA_FAILURE,
  FETCH_ADMIN_INFORMATION_DATA_START,
  FETCH_ADMIN_INFORMATION_DATA_SUCCESS,
  FETCH_INFORMATION_DATA_FAILURE,
  FETCH_INFORMATION_DATA_START,
  FETCH_INFORMATION_DATA_SUCCESS,
} from "./actions";
import { InformationStore } from "./types";

export const initialState: InformationStore = {
  loading: false,
  error: null,
  concatedInformationList: [],
  informationList: [],
  adminInformationList: [],
};

export const reducer = (
  state = initialState,
  action: any,
): InformationStore => {
  switch (action.type) {
    case FETCH_INFORMATION_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_INFORMATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        informationList: action.payload.notifications,
      };
    case FETCH_INFORMATION_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_ADMIN_INFORMATION_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_ADMIN_INFORMATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        adminInformationList: action.payload.notifications,
      };
    case FETCH_ADMIN_INFORMATION_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_INFORMATION_DATA:
      return initialState;
    default:
      return state;
  }
};
