import { setMemberData } from "../../../components/features/member_registration/redux/store";
import {
  CLEAR_ASSET_DATA,
  FETCH_ASSET_DATA_FAILURE,
  FETCH_ASSET_DATA_START,
  FETCH_ASSET_DATA_SUCCESS,
} from "./actions";
import { AssetStore } from "./types";

export const initialState: AssetStore = {
  member: {
    id: 0,
    email: "",
    birth_month: 0,
    birth_year: 0,
    member_code: "",
    tenant_id: 0,
    line_service_id: "",
    gender: "not_specified",
  },
  memberCampaigns: [],
  memberNotifications: [],
  memberRewards: [],
  loading: false,
  error: null,
};

export const reducer = (state = initialState, action: any): AssetStore => {
  switch (action.type) {
    case FETCH_ASSET_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_ASSET_DATA_SUCCESS:
      setMemberData({
        email: action.payload.member.email,
        gender: action.payload.member.gender,
        year: action.payload.member.birth_year,
        month: action.payload.member.birth_month,
        password: "",
        confirmPassword: "",
        membershipNumber: action.payload.member.member_code,
        lineServiceId: action.payload.member.line_service_id,
        token: "",
      });
      return {
        ...state,
        loading: false,
        member: action.payload.member,
        memberCampaigns: action.payload.member_campaigns,
        memberNotifications: action.payload.member_notifications,
        memberRewards: action.payload.member_rewards,
      };
    case FETCH_ASSET_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_ASSET_DATA:
      return initialState;
    default:
      return state;
  }
};
