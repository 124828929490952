import styled from "styled-components";

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
`;

export const Container = styled.div`
  margin: 20px 16px;
  font-size: 14px;
`;

export const BtnContainer = styled.div`
  margin: 20px 6px 0 6px;
`;

export const TextContainer = styled.div`
  margin: 30px 20px;
  font-size: 14px;
`;

export const LineBtnContainer = styled.div`
  margin: 0px 6px 0 6px;
`;

export const Title = styled.div`
  font-size: 20px;
`;

export const OptionContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 14px;
`;
