import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MenuContainer = styled.div`
  animation: ${fadeIn} 0.25s linear;
  margin: 50px 0 0 0;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const MenuItem = styled.div`
  margin: 0;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: none;
    cursor: pointer;
  }
`;
export const MenuItemInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #dddddd;
`;

export const MenuText = styled.div`
  margin: 10px 0px;
`;

export const MenuSubtext = styled.div`
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
`;

export const MenuBadge = styled.div<{ color: string }>`
  display: flex;
  height: 100%;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px;
  color: #ffffff;
  background-color: ${(props) => props.color};
`;

export const MenuWrapper = styled.div`
  max-width: 640px;
  margin: auto;
`;
