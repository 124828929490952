export const FETCH_PARTICIPATING_CAMPAIGN_DATA_START =
  "FETCH_PARTICIPATING_CAMPAIGN_DATA_START";
export const FETCH_PARTICIPATING_CAMPAIGN_DATA_SUCCESS =
  "FETCH_PARTICIPATING_CAMPAIGN_DATA_SUCCESS";
export const FETCH_PARTICIPATING_CAMPAIGN_DATA_FAILURE =
  "FETCH_PARTICIPATING_CAMPAIGN_DATA_FAILURE";
export const FILTER_PARTICIPATING_CAMPAIGN_DATA =
  "FILTER_PARTICIPATING_CAMPAIGN_DATA";
export const RESET_PARTICIPATING_CAMPAIGN_DATA =
  "RESET_PARTICIPATING_CAMPAIGN_DATA";
export const FETCH_PARTICIPATING_CATEGORY_DATA =
  "FETCH_PARTICIPATING_CATEGORY_DATA";
export const CLEAR_PARTICIPATING_CAMPAIGN_DATA =
  "CLEAR_PARTICIPATING_CAMPAIGN_DATA";

export const fetchParticipatingCampaignStart = () => ({
  type: FETCH_PARTICIPATING_CAMPAIGN_DATA_START,
});
export const fetchParticipatingCampaignSuccess = (data: any) => ({
  type: FETCH_PARTICIPATING_CAMPAIGN_DATA_SUCCESS,
  payload: data,
});
export const fetchParticipatingCampaignFailure = (error: any) => ({
  type: FETCH_PARTICIPATING_CAMPAIGN_DATA_FAILURE,
  payload: error,
});
export const filterParticipatingCampaignData = (data: any) => ({
  type: FILTER_PARTICIPATING_CAMPAIGN_DATA,
  payload: data,
});
export const resetParticipatingCampaignData = (data: any) => ({
  type: RESET_PARTICIPATING_CAMPAIGN_DATA,
  payload: data,
});
export const fetchParticipatingCategoryData = (data: any) => ({
  type: FETCH_PARTICIPATING_CATEGORY_DATA,
  payload: data,
});
export const clearParticipatingCampaignData = () => ({
  type: "CLEAR_PARTICIPATING_CAMPAIGN_DATA",
});
