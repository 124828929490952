import styled from "styled-components";

export const ImageContainer = styled.div`
  margin: 40px;
`;

export const CarouselImage = styled.img`
  width: 61% !important;
  object-fit: contain;
`;

export const WalkthroughTitle = styled.h2`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 20px;
  white-space: pre-wrap;
  margin: 0 5vw;
`;

export const WalkthroughText = styled.p`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  white-space: pre-wrap;
  color: #707070;
  height: 14vh;
  line-height: 1.5;
  margin: 0 10vw;
  margin-bottom: 3vw;
`;

export const WalkthroughSubText = styled.span`
  font-size: 12px;
`;
