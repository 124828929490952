export const FETCH_EARNED_CAMPAIGN_DATA_START =
  "FETCH_EARNED_CAMPAIGN_DATA_START";
export const FETCH_EARNED_CAMPAIGN_DATA_SUCCESS =
  "FETCH_EARNED_CAMPAIGN_DATA_SUCCESS";
export const FETCH_EARNED_CAMPAIGN_DATA_FAILURE =
  "FETCH_EARNED_CAMPAIGN_DATA_FAILURE";
export const APPLY_POINT_SUCCESS = "APPLY_POINT_SUCCESS";
export const APPLY_POINT_FAILURE = "APPLY_POINT_FAILURE";
export const FILTER_EARNED_CAMPAIGN_DATA = "FILTER_EARNED_CAMPAIGN_DATA";
export const RESET_EARNED_CAMPAIGN_DATA = "RESET_EARNED_CAMPAIGN_DATA";
export const CLEAR_EARNED_CAMPAIGN_DATA = "CLEAR_EARNED_CAMPAIGN_DATA";

export const fetchEarnedCampaignStart = () => ({
  type: FETCH_EARNED_CAMPAIGN_DATA_START,
});
export const fetchEarnedCampaignSuccess = (data: any) => ({
  type: FETCH_EARNED_CAMPAIGN_DATA_SUCCESS,
  payload: data,
});
export const fetchEarnedCampaignFailure = (error: any) => ({
  type: FETCH_EARNED_CAMPAIGN_DATA_FAILURE,
  payload: error,
});
export const filterEarnedCampaignData = (data: any) => ({
  type: FILTER_EARNED_CAMPAIGN_DATA,
  payload: data,
});
export const resetEarnedCampaignData = (data: any) => ({
  type: RESET_EARNED_CAMPAIGN_DATA,
  payload: data,
});
export const applyPointSuccess = (data: any) => ({
  type: APPLY_POINT_SUCCESS,
  payload: data,
});
export const applyPointFailure = (error: any) => ({
  type: APPLY_POINT_FAILURE,
  payload: error,
});
export const clearEarnedCampaignData = () => ({
  type: CLEAR_EARNED_CAMPAIGN_DATA,
});
