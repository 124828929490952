export const FORGOT_PASSWORD_SET_EMAIL = "FORGOT_PASSWORD_SET_EMAIL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const forgotPasswordSetEmail = (email: string) => ({
  type: FORGOT_PASSWORD_SET_EMAIL,
  payload: email,
});

export const forgotPasswordStart = () => ({
  type: FORGOT_PASSWORD_START,
});
export const forgotPasswordSuccess = (data: any) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: data,
});
export const forgotPasswordFailure = (error: any) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});
