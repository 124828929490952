import React from "react";
import { useErrorModal } from "../../../../lib/error/store";
import { Button } from "../../button/Button";
import {
  ButtonWrapper,
  Container,
  ErrorBg,
  Message,
  Title,
} from "../style/style";

export const ModalErrorContainer: React.FC = () => {
  // state
  const isShow = useErrorModal((state) => state.isShow);
  const title = useErrorModal((state) => state.title);
  const contents = useErrorModal((state) => state.contents);
  const buttons = useErrorModal((state) => state.buttons);

  if (!isShow) {
    return null;
  }

  return (
    <div>
      <ErrorBg isOpen={isShow} />
      <Container isOpen={isShow}>
        <div>
          <Title>{title}</Title>
        </div>
        {contents !== "" && <Message>{contents}</Message>}
        <ButtonWrapper>
          {buttons?.map((button, index) => {
            return (
              <div
                key={`custom-button-${index}`}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  key={button.name}
                  text={button.name}
                  styling={button.styling}
                  color={button.color}
                  onClick={
                    button.callback
                      ? (button.callback as any)
                      : () => {
                          /* nothing to do */
                        }
                  }
                />
              </div>
            );
          })}
        </ButtonWrapper>
      </Container>
    </div>
  );
};
