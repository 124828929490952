export const FETCH_CAMPAIGN_DATA_START = "FETCH_CAMPAIGN_DATA_START";
export const FETCH_CAMPAIGN_DATA_SUCCESS = "FETCH_CAMPAIGN_DATA_SUCCESS";
export const FETCH_CAMPAIGN_DATA_FAILURE = "FETCH_CAMPAIGN_DATA_FAILURE";
export const FILTER_CAMPAIGN_DATA = "FILTER_CAMPAIGN_DATA";
export const FILTER_PROMOTED_CAMPAIGN_DATA = "FILTER_PROMOTED_CAMPAIGN_DATA";
export const RESET_CAMPAIGN_DATA = "RESET_CAMPAIGN_DATA";
export const JOIN_CAMPAIGN_SUCCESS = "JOIN_CAMPAIGN_SUCCESS";
export const JOIN_CAMPAIGN_FAILURE = "JOIN_CAMPAIGN_FAILURE";
export const FETCH_CATEGORY_DATA = "FETCH_CATEGORY_DATA";

export const fetchCampaignStart = () => ({ type: FETCH_CAMPAIGN_DATA_START });
export const fetchCampaignSuccess = (data: any) => ({
  type: FETCH_CAMPAIGN_DATA_SUCCESS,
  payload: data,
});
export const fetchCampaignFailure = (error: any) => ({
  type: FETCH_CAMPAIGN_DATA_FAILURE,
  payload: error,
});
export const filterCampaignData = (data: any) => ({
  type: FILTER_CAMPAIGN_DATA,
  payload: data,
});
export const filterPromotedCampaignData = (data: any) => ({
  type: FILTER_PROMOTED_CAMPAIGN_DATA,
  payload: data,
});
export const resetCampaignData = (data: any) => ({
  type: RESET_CAMPAIGN_DATA,
  payload: data,
});
export const joinCampaignSuccess = (data: any) => ({
  type: JOIN_CAMPAIGN_SUCCESS,
  payload: data,
});
export const joinCampaignFailure = (error: any) => ({
  type: JOIN_CAMPAIGN_FAILURE,
  payload: error,
});
export const fetchCategoryData = (data: any) => ({
  type: FETCH_CATEGORY_DATA,
  payload: data,
});
