import React, { useCallback } from "react";
import { Button } from "../../button/Button";
import { LINEButton } from "../../button/LINEButton";
import { useModal } from "../store";
import {
  Bg,
  ButtonWrapper,
  CloseBtn,
  Container,
  Image,
  Message,
  SubTitle,
  Title,
} from "../style/style";

export const ModalLINEContainer: React.FC = () => {
  // state
  const isShowLINE = useModal((state) => state.isShowLINE);
  const showLINE = useModal((state) => state.showLINE);
  const title = useModal((state) => state.title);
  const subTitle = useModal((state) => state.subTitle);
  const icon = useModal((state) => state.icon);
  const image_url = useModal((state) => state.image_url);
  const contents = useModal((state) => state.contents);
  const buttons = useModal((state) => state.buttons);

  const close = useCallback(() => {
    showLINE(false);
  }, [showLINE]);

  if (!isShowLINE) {
    return null;
  }

  return (
    <div>
      <Bg />
      <Container isOpen={isShowLINE}>
        <div>
          <SubTitle>{subTitle}</SubTitle>
          <Title>{title}</Title>
          <CloseBtn
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/ui/modal/close.svg" alt="close" />
          </CloseBtn>
        </div>
        {image_url && <Image src={image_url ?? ""} alt="sample" />}
        {icon && icon}
        {contents !== "" && <Message>{contents}</Message>}
        <LINEButton />

        {buttons?.map((button, index) => {
          return (
            <ButtonWrapper key={`custom-button-${index}`}>
              <Button
                key={button.name}
                text={button.name}
                styling={button.styling}
                color={button.color}
                onClick={
                  button.callback
                    ? (button.callback as any)
                    : () => {
                        /* nothing to do */
                      }
                }
              />
            </ButtonWrapper>
          );
        })}

        {/* <Button
          text={"閉じる"}
          styling={"border"}
          color={"#000000"}
          onClick={() => close()}
        /> */}
      </Container>
    </div>
  );
};
