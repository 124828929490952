import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Clock } from "../../../../common/icons/Clock";
import { LimitLabel } from "../../../../common/icons/LimitLabel";
import { RewardLabel } from "../../../../common/icons/RewardLabel";
import {
  isAquiredAllReward,
  isAquiredReward,
  isSameRewardPeriod,
} from "../../../../common/utils/campaign";
import {
  formatDate,
  formatDateTime,
  isWithinThreeDays,
} from "../../../../common/utils/date";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { Button } from "../../../ui";
import { RewardModal } from "../../../ui/modal/RewardModal";
import { TargetItemModal } from "../../../ui/modal/TargetItemModal";
import { ModalProps } from "../../../ui/modal/types";
import {
  Campaign,
  CampaignReward,
  PurchaseLog,
  Reward,
  TargetItem,
  TargetItemDetail,
} from "../../campaign/campaign.type";
import { CampaignDetailAquiredText } from "../../campaign/style/campaign-participating-card";
import { MileProgressBar } from "../component/MileProgressBar";
import {
  CampaignContentMultipleReward,
  CampaignContentMultipleRewardLine,
  CampaignContentRewardText,
  CampaignContentTargetItem,
  CampaignContentTerm,
  CampaignContentText,
  CampaignContentWrapper,
  CampaignDetailBottomButton,
  CampaignOverViewImage,
  CampaignOverViewTitle,
  CampaignOverViewWrapper,
} from "../style/campaign_detail";
import {
  CampaignBulletList,
  CampaignBulletListItem,
  CampaignBulletTargetItemItem,
  CampaignBulletTargetItemList,
  CampaignContentDotLine,
  CampaignContentNotes,
  CampaignContentNotesTitle,
  CampaignMileWrapper,
  CampaignPurchasedItem,
  CampaignPurchasedItemContent,
  CampaignPurchasedItemDate,
  CampaignPurchasedItemLi,
  CampaignPurchasedItemUl,
  CampaignPurchasedItemWrapper,
  CampaignPurchasedNoItem,
  CampaignTargetItemButton,
  CampaignTargetItemDateTitle,
  CampaignTargetItemWrapper,
  ClockWrapper,
  ContentWrapper,
  MileCard,
  MileCardMiddleText,
  MileCardMiddleTextColored,
  MileCardReward,
  MileCardRewardTitle,
  MileCardRewardWrapper,
  MileCardText,
  MileCardTextEndAt,
  MileCardTextWrapper,
  MileCardTitle,
  MileRewardImage,
  MileRewardMessage,
  MileRewardWrapperMessage,
  RewardDetailButton,
  RewardEarnMethod,
  RewardImage,
  RewardMessage,
  RewardTitle,
  RewardTriangleLabel,
  RewardTriangleText,
  RewardWrapper,
  RewardWrapperMessage,
  SubTitleBar,
} from "../style/campaign_detail_participating";
import useParticipatingCampaignStore from "./../../campaign/redux/participatingCampaign/store";

const defaultModalProps: ModalProps = {
  isShow: false,
  title: "",
  subTitle: "",
  image_url: "",
  icon: <div />,
  LINE: false,
  contents: "",
  buttons: [],
  onClose: () => {
    /**/
  },
};

export const CampaignParticipatingDetailContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);
  const [targetItemCount, setTargetItemCount] = useState<number[]>([]);

  const participatingCampaigns = useParticipatingCampaignStore(
    (state) => state.participatingCampaigns,
  );
  const { campaignId, tenant_code } = useParams();
  const campaign = participatingCampaigns.find((campaign: Campaign) => {
    return campaign.id === Number(campaignId);
  });
  const targetReward = campaign?.rewards?.find((reward: CampaignReward) => {
    return reward.id === campaign.next_campaign_reward_id;
  });

  type MileageState = {
    accumulated_mileage: number;
    before_mileage: number;
    next_mileage: number;
  };

  const [mileage, setMileage] = useState<MileageState>({
    accumulated_mileage: 0,
    before_mileage: 0,
    next_mileage: 0,
  });

  useEffect(() => {
    setMileage({
      accumulated_mileage: campaign?.accumulated_mileage || 0,
      before_mileage: campaign?.before_mileage || 0,
      next_mileage: campaign?.next_mileage || 0,
    });

    // target itemの数をカウントする
    const countArray: number[] = [];
    let targetCount = 0;
    campaign?.target_items.map((targetItems: any, index: number) => {
      targetCount += targetItems.items.length;
      countArray.push(targetCount);
    }, 0);
    setTargetItemCount(countArray);
  }, [campaign]);

  if (typeof campaign === "undefined") {
    window.location.replace(`/${tenant_code}/campaign?tab=participating`);
  }

  const navigateTop = () => {
    navigate(`/campaign?tab=participating`);
  };

  const onShowReward = useCallback((index: number) => {
    setModalProps({
      title: campaign.rewards[index].reward_name,
      subTitle: "",
      onClose() {
        setModalProps(defaultModalProps);
      },
      contents: campaign.rewards[index].description,
      image_url:
        campaign.rewards[index].banner_image_url ||
        "/image/dummy_banner_image.png",
      buttons: [],
      isShow: true,
    });
  }, []);

  const onShowAllItem = useCallback(() => {
    setIsShowModal(true);
  }, []);

  const caluclatedMile = (): number => {
    if (campaign?.accumulated_mileage === 0 || mileage.next_mileage <= 0) {
      return 0;
    }
    const numerator = campaign?.accumulated_mileage - campaign?.before_mileage;
    const denominator = mileage.next_mileage - campaign?.before_mileage;
    if (numerator <= 0) return 0;
    if (denominator <= 0) return 0;
    const calc = numerator / denominator;

    if (calc > 1) return 100;
    return calc * 100;
  };

  return (
    <>
      <CampaignMileWrapper>
        <MileCard>
          <MileCardTextWrapper>
            <MileCardTitle>現在の獲得マイル</MileCardTitle>
            <MileCardText>
              {!isAquiredAllReward(campaign)
                ? campaign?.accumulated_mileage
                : `-`}
              マイル
            </MileCardText>
          </MileCardTextWrapper>
          {!isAquiredAllReward(campaign) && (
            <>
              <MileProgressBar
                value={caluclatedMile()}
                max={mileage.next_mileage || 500}
                min={mileage.before_mileage || 0}
              ></MileProgressBar>
              <MileCardMiddleText>
                {targetReward?.is_repetition && <RewardLabel is_card={false} />}
                <MileCardMiddleTextColored>
                  あと
                  {(mileage.next_mileage || 0) -
                    (campaign?.accumulated_mileage || 0)}
                  マイル
                </MileCardMiddleTextColored>
                で特典GET！
              </MileCardMiddleText>
              <MileCardReward>
                <MileCardRewardTitle>特典獲得条件</MileCardRewardTitle>
                <MileCardRewardWrapper>
                  <MileRewardImage
                    src={
                      targetReward?.banner_image_url ||
                      "/image/dummy_banner_image.png"
                    }
                  />
                  <MileRewardWrapperMessage>
                    {targetReward?.is_lottery && "抽選で特典が当たる！"}
                    <MileRewardMessage>
                      {targetReward?.reward_name}
                    </MileRewardMessage>
                  </MileRewardWrapperMessage>
                </MileCardRewardWrapper>
              </MileCardReward>
            </>
          )}
          {isAquiredAllReward(campaign) && (
            <CampaignDetailAquiredText>特典獲得済み</CampaignDetailAquiredText>
          )}
          {/* 参加中の場合`あと***マイルで特典GET！` */}
        </MileCard>
        {/* TODO: ステータス */}
        <MileCardTextEndAt>
          {isWithinThreeDays(campaign?.implementation_period_end) && (
            <LimitLabel />
          )}
          <ClockWrapper>
            <Clock />
          </ClockWrapper>
          {formatDateTime(campaign?.implementation_period_end)}まで
        </MileCardTextEndAt>
      </CampaignMileWrapper>
      <ContentWrapper>
        {/* Label */}
        <SubTitleBar>特典</SubTitleBar>
        {campaign?.rewards?.map((reward: Reward, index: number) => {
          return (
            <>
              {reward.is_lottery && (
                <RewardTitle>
                  {reward.needed_mile}マイル獲得で抽選にチャレンジ！
                </RewardTitle>
              )}
              {!reward.is_lottery && (
                <RewardTitle>
                  {reward.needed_mile}マイル獲得でゲット！
                </RewardTitle>
              )}
              <RewardWrapper>
                <RewardImage
                  src={
                    reward.banner_image_url || "/image/dummy_reward_image.png"
                  }
                />
                <RewardWrapperMessage>
                  <RewardMessage>{reward.reward_name}</RewardMessage>
                  <RewardDetailButton onClick={() => onShowReward(index)}>
                    詳細
                  </RewardDetailButton>
                </RewardWrapperMessage>
                {isAquiredReward(
                  reward,
                  reward.is_repetition,
                  campaign.matchedRewards,
                ) && (
                  <RewardTriangleLabel>
                    <RewardTriangleText>獲得済み</RewardTriangleText>
                  </RewardTriangleLabel>
                )}
              </RewardWrapper>
            </>
          );
        })}
        {/* Label */}
        <SubTitleBar>特典獲得方法</SubTitleBar>
        <RewardEarnMethod>{campaign?.acquisition_method}</RewardEarnMethod>
      </ContentWrapper>
      <CampaignContentWrapper>
        {/* Label */}
        <SubTitleBar>キャンペーン概要</SubTitleBar>
        <CampaignOverViewImage
          src={campaign?.campaign_banner_url || "/image/dummy_banner_image.png"}
        />
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>期間：</CampaignOverViewTitle>
          <CampaignContentTerm>
            実施期間
            <CampaignContentText>
              {formatDateTime(campaign?.implementation_period_start)} 〜<br />{" "}
              {formatDateTime(campaign?.implementation_period_end)}まで
            </CampaignContentText>
          </CampaignContentTerm>
          <CampaignContentDotLine></CampaignContentDotLine>
          <CampaignContentTerm>
            特典利用期間
            {isSameRewardPeriod(campaign?.rewards) && (
              <>
                <CampaignContentText>
                  {formatDateTime(
                    campaign?.rewards[0].reward_validity_period_start,
                  )}{" "}
                  〜<br />
                  {formatDateTime(
                    campaign?.rewards[0].reward_validity_period_end,
                  )}
                  まで
                </CampaignContentText>
              </>
            )}
          </CampaignContentTerm>
          {!isSameRewardPeriod(campaign?.rewards) &&
            campaign?.rewards?.map((reward: CampaignReward, index: number) => {
              return (
                <>
                  <CampaignContentMultipleReward key={index}>
                    <CampaignContentRewardText>
                      {reward.reward_name}
                    </CampaignContentRewardText>
                    <CampaignContentRewardText>
                      {formatDateTime(reward?.reward_validity_period_start)} 〜
                      <br />
                      {formatDateTime(reward?.reward_validity_period_end)}
                      まで
                    </CampaignContentRewardText>
                  </CampaignContentMultipleReward>
                  <CampaignContentMultipleRewardLine></CampaignContentMultipleRewardLine>
                </>
              );
            })}
        </CampaignOverViewWrapper>
        {campaign?.campaign_content && (
          <CampaignOverViewWrapper>
            <CampaignOverViewTitle>キャンペーン内容：</CampaignOverViewTitle>
            <CampaignContentTerm>
              <CampaignContentText>
                {campaign?.campaign_content}
              </CampaignContentText>
            </CampaignContentTerm>
          </CampaignOverViewWrapper>
        )}
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>最低購入金額：</CampaignOverViewTitle>
          <CampaignContentTerm>
            <CampaignContentText isFull>
              {"対象商品の1日の合計購入金額が" +
                campaign?.minimum_purchase_amount +
                "円（税込）以上"}
            </CampaignContentText>
          </CampaignContentTerm>
        </CampaignOverViewWrapper>
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>対象商品：</CampaignOverViewTitle>
          <CampaignContentTargetItem>
            <CampaignContentText>
              {campaign &&
                campaign?.target_items.map(
                  (target_item: TargetItem, index: number) => {
                    if (targetItemCount[index] > 11) return;
                    return (
                      <CampaignTargetItemWrapper key={index}>
                        <CampaignTargetItemDateTitle>
                          {formatDateTime(target_item.target_item_period_start)}
                          ~{formatDateTime(target_item.target_item_period_end)}
                        </CampaignTargetItemDateTitle>
                        <CampaignBulletTargetItemList>
                          {target_item.items.map(
                            (item: TargetItemDetail, idx: number) => {
                              return (
                                <CampaignBulletTargetItemItem key={idx}>
                                  ・
                                  {`${item.name}（マイル付与率：購入金額の${item.rate}%）`}
                                </CampaignBulletTargetItemItem>
                              );
                            },
                          )}
                        </CampaignBulletTargetItemList>
                      </CampaignTargetItemWrapper>
                    );
                  },
                )}
              {campaign?.target_items &&
                targetItemCount[targetItemCount.length - 1] > 11 && (
                  <CampaignTargetItemButton onClick={() => onShowAllItem()}>
                    すべて見る
                  </CampaignTargetItemButton>
                )}
            </CampaignContentText>
          </CampaignContentTargetItem>
        </CampaignOverViewWrapper>
        <CampaignContentNotes>
          <CampaignContentNotesTitle>注意事項</CampaignContentNotesTitle>
          <CampaignBulletList>
            {campaign?.caution &&
              campaign?.caution
                .split("\n")
                .map((note: string, index: number) => {
                  return (
                    <CampaignBulletListItem key={index}>
                      ・{note}
                    </CampaignBulletListItem>
                  );
                })}
          </CampaignBulletList>
        </CampaignContentNotes>
      </CampaignContentWrapper>
      <CampaignPurchasedItemWrapper>
        {/* Label */}
        <SubTitleBar>商品購入履歴</SubTitleBar>
        {campaign?.purchase_logs.lentgh === 0 && (
          <CampaignPurchasedNoItem>
            商品購入履歴は
            <br />
            ありません
          </CampaignPurchasedNoItem>
        )}
        {campaign?.purchase_logs.map((log: PurchaseLog, index: number) => {
          return (
            <CampaignPurchasedItem key={index}>
              <CampaignPurchasedItemDate>
                {formatDate(log.date)}
              </CampaignPurchasedItemDate>
              <CampaignPurchasedItemUl>
                {Object.entries(log.purchase_logs).map(([id, purchase]) => (
                  <CampaignPurchasedItemLi key={id}>
                    <CampaignPurchasedItemContent left={true}>
                      {purchase.name}
                    </CampaignPurchasedItemContent>
                    <CampaignPurchasedItemContent right={true}>
                      {purchase.amount}点
                    </CampaignPurchasedItemContent>
                    <CampaignPurchasedItemContent right={true}>
                      {purchase.total}円
                    </CampaignPurchasedItemContent>
                  </CampaignPurchasedItemLi>
                ))}
              </CampaignPurchasedItemUl>
            </CampaignPurchasedItem>
          );
        })}
      </CampaignPurchasedItemWrapper>
      <CampaignDetailBottomButton>
        <Button
          text="一覧を見る"
          styling="border"
          color="#000000"
          onClick={navigateTop}
        />
      </CampaignDetailBottomButton>
      <TargetItemModal
        isShow={isShowModal}
        title={"対象商品"}
        target_items={campaign?.target_items}
        onClose={() => setIsShowModal(false)}
      ></TargetItemModal>
      <RewardModal {...modalProps} />
    </>
  );
};
