import React, { useCallback } from "react";
import styled from "styled-components";
import { LINE } from "../../../common/icons/LINE";
import { useMemberRegister } from "../../features/member_registration/hooks/useMemberRegister";
import { HeaderTemplate } from "../../template";
import { settingStore } from "../../template/store/setting";
import { Base } from "../../template/style";
import { LINEButton } from "../../ui/button/LINEButton";
import { LINELink } from "../../ui/button/style";
import { Footer } from "../../ui/footer/Footer";

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 1em;
  padding: 35px 0;
`;
export const Message = styled.p`
  margin: 30px 50px 35px 50px;
  font-size: 16px;
  line-height: 1.5em;
`;
export const Contents = styled.div`
  position: relative;
  width: 100%;
`;

export const LineFriend: React.FC = () => {
  const lineFriend = settingStore((state) => state.line_settings);
  const { loginWithRegisterData } = useMemberRegister();

  const onNext = useCallback(() => {
    loginWithRegisterData();
  }, []);

  const onLink = useCallback(() => {
    window.open(lineFriend.transition_script, "_blank", "noopener noreferrer");
  }, []);

  return (
    <Base>
      <HeaderTemplate>
        <HeaderContainer>
          <Title>LINE友だち追加</Title>
        </HeaderContainer>
        <Contents>
          <LINE width={75} height={75} />
          <Message>{lineFriend.expository_text}</Message>
          <LINELink onClick={() => onLink()}>
            <LINEButton />
          </LINELink>
        </Contents>
      </HeaderTemplate>
      <Footer onClickButton={onNext} />
    </Base>
  );
};
