import create from "zustand";
import { persist, redux } from "zustand/middleware";
import { log } from "../../hooks/useLogMiddleware";
import { initialState, reducer } from "./reducers";

const useModalRewardStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "modal_reward",
    }),
  ),
);

export default useModalRewardStore;
