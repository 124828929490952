import React from "react";
import { useParams } from "react-router-dom";
import { Clock } from "../../../../common/icons/Clock";
import { LimitLabel } from "../../../../common/icons/LimitLabel";
import { RewardLabel } from "../../../../common/icons/RewardLabel";
import { isAquiredAllReward } from "../../../../common/utils/campaign";
import {
  formatDateTime,
  isWithinThreeDays,
} from "../../../../common/utils/date";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { Campaign, Reward } from "../campaign.type";
import {
  CampaignAquiredText,
  CampaignButtonWrapper,
  CampaignCard,
  CampaignCardContentWrapper,
  CampaignCardImage,
  CampaignCardMile,
  CampaignCardMileText,
  CampaignCardText,
  CampaignDetailLink,
  TextContainer,
} from "../style/campaign-participating-card";
import { MileProgressBarSmall } from "./MileProgressBarSmall";

type Props = {
  campaign: Campaign;
  memberRewards: Reward[];
};

const caluclatedMile = (campaign: Campaign): number => {
  if (campaign?.accumulated_mileage === 0 || campaign.next_mileage <= 0) {
    return 0;
  }
  const numerator = campaign?.accumulated_mileage - campaign?.before_mileage;
  const denominator = campaign.next_mileage - campaign?.before_mileage;
  if (numerator <= 0) return 0;
  if (denominator <= 0) return 0;
  const calc = numerator / denominator;

  if (calc > 1) return 100;
  return calc * 100;
};

const calculateMileRemaining = (
  next_mileage: number,
  accumulated_mileage: number,
): number => {
  const calc = next_mileage - accumulated_mileage;
  return calc > 0 ? calc : 0;
};

export const CampaignParticipatingCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { tenant_code } = useParams();

  return (
    <CampaignCard>
      <CampaignCardImage
        src={
          props.campaign?.campaign_banner_url || "/image/dummy_banner_image.png"
        }
        alt="sample01"
      />
      <CampaignCardText>
        <TextContainer>
          {isWithinThreeDays(props.campaign.implementation_period_end) && (
            <LimitLabel />
          )}
          <Clock style={{ margin: 5, verticalAlign: "middle" }} />
          {formatDateTime(props.campaign.implementation_period_end)}まで
        </TextContainer>
      </CampaignCardText>
      <CampaignCardContentWrapper
        isAquired={isAquiredAllReward(props.campaign)}
      >
        {props.campaign?.rewards[0]?.is_repetition && (
          <RewardLabel is_card={true} />
        )}
        {!isAquiredAllReward(props.campaign) && (
          <CampaignCardMile>
            <CampaignCardMileText>
              あと
              {calculateMileRemaining(
                props.campaign?.next_mileage || 0,
                props.campaign?.accumulated_mileage || 0,
              )}
              マイル
            </CampaignCardMileText>
            で特典GET!
            <MileProgressBarSmall
              value={caluclatedMile(props.campaign)}
              max={props.campaign?.next_mileage || 500}
              min={props.campaign?.before_mileage || 0}
            ></MileProgressBarSmall>
          </CampaignCardMile>
        )}
        <CampaignButtonWrapper>
          {isAquiredAllReward(props.campaign) && (
            <CampaignAquiredText>特典獲得済み</CampaignAquiredText>
          )}
          <CampaignDetailLink
            onClick={() =>
              navigate(`/campaign/${props.campaign.id}?tab=participating`)
            }
            isAlreadyEarned={isAquiredAllReward(props.campaign)}
          >
            詳細
          </CampaignDetailLink>
        </CampaignButtonWrapper>
      </CampaignCardContentWrapper>
    </CampaignCard>
  );
};
