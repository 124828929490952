import styled from "styled-components";

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const BtnContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0 0 0 15px;
  transform: translateY(-50%);
  line-height: 0;
  cursor: pointer;
`;
