import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMemberRegister } from "../../features/member_registration/hooks/useMemberRegister";
import { Spinner } from "../../ui/spinner/Spinner";
import { useParams } from "react-router";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
`;

export const Redirect: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const iss = query.get("iss");
  const { tenant_code } = useParams();
  const { getSupayId } = useMemberRegister();

  useEffect(() => {
    if (code && iss) {
      const params = {
        code_verifier: localStorage.getItem("verifier"),
        redirect_uri: localStorage.getItem("redirect_uri"),
        code: code,
      };
      getSupayId(params, tenant_code as string);
    }
  }, [code, iss]);
  return (
    <Container>
      <Spinner />
    </Container>
  );
};
