import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  WALKTHROUGH_TEXT,
  WALKTHROUGH_TITLE,
} from "../../features/walkthrough/constant";
import {
  CarouselImage,
  ImageContainer,
  WalkthroughSubText,
  WalkthroughText,
  WalkthroughTitle,
} from "./style";
import "./style/index.css";

type Props = {
  images: string[];
  currentStep: number;
  onChange: (index: number) => void;
  swiperRef: any;
};

export const ImageCarousel: React.FC<Props> = ({
  images,
  currentStep,
  onChange,
  swiperRef,
}) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      onSlideChange={(swiper: any) => onChange(swiper.activeIndex)}
      onSwiper={(swiper: any) => (swiperRef.current = swiper)}
      modules={[Pagination]}
    >
      {images.map((image, i) => (
        <SwiperSlide key={i}>
          <WalkthroughTitle>{WALKTHROUGH_TITLE[i]}</WalkthroughTitle>
          <ImageContainer key={`img-container-${i}`}>
            <CarouselImage src={image} />
          </ImageContainer>
          <WalkthroughText>
            {i !== 1 && WALKTHROUGH_TEXT[i]}
            {i === 1 && WALKTHROUGH_TEXT[i][0]}
            {i === 1 && (
              <WalkthroughSubText>{WALKTHROUGH_TEXT[i][1]}</WalkthroughSubText>
            )}
          </WalkthroughText>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
