import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { ForgotPasswordContainer } from "../../features/forgot_password";
import useForgotPasswordStore, {
  onForgotPassword,
} from "../../features/forgot_password/redux/store";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { Modal } from "../../ui";
import { Footer } from "../../ui/footer/Footer";

export const ForgotPassword: React.FC = () => {
  const { tenant_code } = useParams();
  const enabled = useForgotPasswordStore((state) => state.enabled);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const onComplete = useCallback(() => {
    setIsComplete(true);
  }, []);

  const onClose = useCallback(() => {
    setIsComplete(false);
  }, []);

  return (
    <Base>
      <HeaderTemplate>
        <ForgotPasswordContainer />
        <Modal
          isShow={isComplete}
          title={"メールを送信しました。"}
          subTitle={""}
          contents={"ご確認ください。"}
          onClose={onClose}
        />
      </HeaderTemplate>
      <Footer
        buttonText="送信"
        onClickButton={() => {
          onForgotPassword(tenant_code ?? "")
            .then(onComplete)
            .catch((e) => {
              /* nothing to do */
            });
        }}
        buttonDisabled={!enabled}
      />
    </Base>
  );
};
