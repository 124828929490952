import React from "react";
import {
  EncourageRegisterMemberText,
  EncourageRegisterMemberWrapper,
} from "../style/connect-member";
import { ConnectMemberButton } from "../ui/ConnectMemberButton";

type Props = {
  onClick: () => void;
};

export const EncourageRegisterMember: React.FC<Props> = (props) => {
  return (
    <EncourageRegisterMemberWrapper>
      <EncourageRegisterMemberText>
        ログインまたは、会員登録をして
        <br />
        キャンペーンに参加しよう！
      </EncourageRegisterMemberText>
      <ConnectMemberButton text="ログイン・会員登録" onClick={props.onClick} />
    </EncourageRegisterMemberWrapper>
  );
};
