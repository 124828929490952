import { ADD_DISPLAYED_REWARD, REMOVE_DISPLAYED_REWARD } from "./actions";
import { ModalRewardStore } from "./types";

export const CONNECTOR = "____";

export const initialState: ModalRewardStore = {
  displayedRewards: {},
};

export const reducer = (
  state = initialState,
  action: any,
): ModalRewardStore => {
  switch (action.type) {
    case ADD_DISPLAYED_REWARD:
      const uuid =
        action.payload.tenant_code + CONNECTOR + action.payload.user_id;
      const displayedRewards: Array<string> = state.displayedRewards[uuid]
        ? state.displayedRewards[uuid]
        : [];
      if (!displayedRewards.find((r) => r === action.payload.reward_id)) {
        displayedRewards.push(action.payload.reward_id);
      }
      state.displayedRewards[uuid] = displayedRewards;
      return {
        ...state,
        displayedRewards: { ...state.displayedRewards },
      };
    case REMOVE_DISPLAYED_REWARD:
      const id = action.payload.tenant_code + "____" + action.payload.user_id;
      const dispRewards: Array<string> = state.displayedRewards[id]
        ? state.displayedRewards[id]
        : [];
      const filteredRewards = dispRewards.filter((r) => {
        return r !== action.payload.reward_id;
      });
      state.displayedRewards[id] = filteredRewards;
      return {
        ...state,
        displayedRewards: { ...state.displayedRewards },
      };
    default:
      return state;
  }
};
