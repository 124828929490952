import styled from "styled-components";

type CustomProps = {
  is_card?: boolean;
};

export const StyledLabelRepeat = styled.div<CustomProps>`
  position: relative;
  padding: 6px 4px;
  margin-right: 20px;
  background-color: #f5f5f5;
  border: none;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  display: flex;
  user-select: none;
  min-width: 39px;
  &:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 10px solid #f5f5f5;
  }
  ${({ is_card }) =>
    is_card &&
    `
    display: flex;
    text-align: left;
    font-size: 10px;
    margin-top: 10px;
    margin-right: 10px;
    height: 25px;
    &:after {
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
    }
  `}
`;

export const LabelText = styled.div`
  line-height: 1em;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 10px;
`;

export const Icon = styled.div<CustomProps>`
  margin-top: 3px;
  ${({ is_card }) =>
    !is_card &&
    `
    margin-top: 2px;
  `}
`;
