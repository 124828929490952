import styled from "styled-components";

export const CampaignMileWrapper = styled.div`
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px 16px 5px 16px;
`;

export const MileCard = styled.div`
  align-items: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const MileCardTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const BodyTextContainer = styled.div`
  height: 50vh;
  overflow-y: auto;
  padding: 12px 24px;
`;

export const BodyTextRewardContainer = styled.div`
  height: 25vh;
  overflow-y: auto;
  padding: 12px 24px;
`;

export const MileCardTitle = styled.div`
  font-size: 16px;
`;

export const MileCardText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0271ff;
`;

export const MileBar = styled.div`
  background-color: #f5f5f5;
  height: 20px;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
`;

export const CampaignParticipateStatus = styled.div`
  text-align: center;
  color: #0271ff;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: bold;
`;

export const MileCardTextEndAt = styled.p`
  font-size: 13px;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  text-align: left;
  background-color: #ffffff;
  padding: 0px 16px;
`;

export const SubTitleBar = styled.div`
  background-color: #999999;
  color: #ffffff;
  width: 100%;
  font-size: 14px;
  padding: 3px 0;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
`;

export const RewardTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
`;

export const RewardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  justify-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #999999;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const RewardImage = styled.img`
  height: 100px;
  border-radius: 4px;
  object-fit: contain;
`;

export const RewardWrapperMessage = styled.div`
  width: 70%;
  font-size: 14px;
  text-align: left;
  margin-left: 16px;
  word-wrap: break-word;
`;

export const RewardDetailButton = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid black;
  margin-left: auto;
  margin-top: 40px;
  width: 30px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
`;

export const RewardMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  text-align: left;
  word-wrap: break-word;

  width: 90%;
`;

export const RewardEarnMethod = styled.div`
  font-size: 14px;
  margin: 20px 0;
  word-wrap: break-word;
`;

export const CampaignContentWrapper = styled.div`
  text-align: left;
  background-color: #f5f5f5;
  padding: 3px 16px;
`;

export const CampaignOverViewImage = styled.img`
  object-fit: contain;
  height: 170px;
  width: 100%;
`;

export const CampaignOverViewWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px 20px 16px 20px;
  margin: 16px 0;
`;

export const CampaignOverViewTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
`;

export const CampaignContentTerm = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
  word-wrap: break-word;
`;

export const CampaignContentDotLine = styled.span`
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c3c3c3;
  margin: 10px 0;
`;

export const CampaignContentText = styled.span<{ isFull?: boolean }>`
  vertical-align: top;
  font-size: 14px;
  word-wrap: break-word;
  ${(props) => {
    if (props.isFull) {
      return `width: 100%`;
    }
  }}
`;

export const CampaignContentMultipleReward = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const CampaignContentRewardText = styled.div`
  font-size: 12px;
`;

export const CampaignContentMultipleRewardLine = styled.span`
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid #c3c3c3;
  margin: 10px 0;
`;

export const CampaignContentTargetItem = styled.div`
  font-size: 14px;
  width: 100%;
  padding: 0 0 10px 0;
  text-align: center;
`;

export const CampaignContentNotes = styled.div`
  font-size: 14px;
  padding: 3px 10px;
`;

export const CampaignContentNotesTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
`;

export const CampaignPurchasedItemWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0 16px;
  text-align: center;
`;

export const CampaignPurchasedNoItem = styled.div`
  font-size: 16px;
  color: #999999;
  margin-bottom: 24px;
`;

export const CampaignBulletList = styled.ul`
  padding-left: 10px;
  font-family: "Arial", sans-serif;
`;

export const CampaignBulletListItem = styled.li`
  position: relative;
`;

export const CampaignTargetItemWrapper = styled.div`
  text-align: left;
`;

export const CampaignTargetItemDateTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const CampaignBulletTargetItemList = styled.ul`
  padding-left: 10px;
  list-style-type: none;
`;

export const CampaignBulletTargetItemItem = styled.li`
  font-size: 14px;
`;

export const CampaignJoinButton = styled.button<{ color: string }>`
  background-color: ${(props) => (props.color ? props.color : "#10459b")};
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid ${(props) => (props.color ? props.color : "#10459b")};
  border-radius: 8px;
  padding: 4px 16px;
  margin: 0 0 16px 0;
  cursor: pointer;
  min-height: 48px;
  width: 100%;
`;

export const CampaignDetailBottomButton = styled.div`
  margin-bottom: 16px;
`;
