import create from "zustand";
import { redux } from "zustand/middleware";
import { memberApi } from "../../../../core/http/openAPIClient";
import { log } from "../../../../lib/hooks/useLogMiddleware";
import {
  resetPasswordFailure,
  resetPasswordSetConfirmPassword,
  resetPasswordSetPassword,
  resetPasswordSuccess,
} from "./actions";
import { initialState, reducer } from "./reducers";

const useResetPasswordStore = create(log(redux(reducer, initialState)));

export const setResetPasswordPassword = (password: string) => {
  const dispatch = useResetPasswordStore.getState().dispatch;
  dispatch(resetPasswordSetPassword(password));
};
export const setResetPasswordConfirmPassword = (password: string) => {
  const dispatch = useResetPasswordStore.getState().dispatch;
  dispatch(resetPasswordSetConfirmPassword(password));
};

export const onResetPassword = (tenant_code: string, code: string) => {
  const dispatch = useResetPasswordStore.getState().dispatch;
  const new_password = useResetPasswordStore.getState().password;

  return memberApi
    .passwordResetApiV1TenantCodeMembersPasswordResetPost(tenant_code, {
      tenant_code,
      code,
      new_password,
    })
    .then((res) => {
      dispatch(resetPasswordSuccess(res));
      return new Promise((resolve) => {
        resolve(res);
      });
    })
    .catch((e) => {
      dispatch(resetPasswordFailure(e));
      return new Promise((_, reject) => {
        reject(e);
      });
    });
};

export default useResetPasswordStore;
