import styled from "styled-components";

type CustomProps = {
  right?: boolean;
  left?: boolean;
};

export const CampaignMileWrapper = styled.div`
  align-items: center;
  background-color: #f5f5f5;
  padding: 16px 12px 3px 12px;
`;

export const MileCard = styled.div`
  align-items: center;
  padding: 6px 16px 16px 16px;
  background-color: #ffffff;
  min-height: 140px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const MileCardTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const MileCardTitle = styled.div`
  font-size: 16px;
`;

export const MileCardText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0271ff;
`;

export const MileBar = styled.div`
  background-color: #f5f5f5;
  height: 20px;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
`;

export const MileCardMiddleText = styled.div`
  display: flex;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
`;

export const MileCardMiddleTextColored = styled.span`
  font-size: 16px;
  text-align: center;
  color: #0271ff;
  font-weight: bold;
`;

export const MileCardReward = styled.div`
  border-radius: 8px;
  outline: 1px solid #999999;
  cursor: pointer;
`;

export const MileCardRewardWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  padding-top: 9px;
  padding-bottom: 11px;
  padding-left: 14px;
  margin-right: 20px;
`;

export const MileCardRewardTitle = styled.div`
  font-size: 12px;
  background-color: #edf2fe;
  color: #61a0ed;
  padding: 4px 0;
`;

export const MileRewardImage = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 4px;
  object-fit: contain;
`;

export const MileRewardWrapperMessage = styled.div`
  font-size: 12px;
  text-align: left;
  padding-left: 14px;
`;

export const MileRewardMessage = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 14px;
`;

export const CampaignParticipateStatus = styled.div`
  text-align: center;
  color: #0271ff;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: bold;
`;

export const MileCardTextEndAt = styled.p`
  font-size: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  text-align: left;
  background-color: #ffffff;
  padding: 0px 16px 20px 16px;
`;

export const SubTitleBar = styled.div`
  background-color: #999999;
  color: #ffffff;
  width: 100%;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  font-size: 14px;
`;

export const RewardTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
`;

export const RewardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  justify-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0 0 16px 16px;
  border: 1px solid #999999;
  pointer: cursor;
  margin-bottom: 8px;
  position: relative;
`;

export const RewardTriangleLabel = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 8px 0 0;
  height: 65px;
  width: 65px;
  background-color: #0271ff;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
`;

export const RewardTriangleText = styled.span`
  position: absolute;
  top: 40%;
  left: 65%;
  transform: translate(-50%, -50%) rotate(45deg);
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
`;

export const RewardImage = styled.img`
  height: 84px;
  width: 84px;
  border-radius: 4px;
  object-fit: contain;
  padding-top: 16px;
`;

export const RewardWrapperMessage = styled.div`
  font-size: 16px;
  text-align: left;
  width: 100%;
  padding-left: 10px;
  padding-top: 16px;
`;

export const RewardMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
`;

export const RewardDetailButton = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid black;
  margin-left: auto;
  margin-right: 12px;
  margin-top: 24px;
  width: 30px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
`;

export const RewardEarnMethod = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

export const CampaignContentWrapper = styled.div`
  text-align: left;
  background-color: #f5f5f5;
  padding: 3px 16px;
`;

export const CampaignOverViewImage = styled.img`
  object-fit: contain;
  height: 170px;
  width: 100%;
`;

export const CampaignOverViewWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px 20px 16px 20px;
  margin: 16px 0;
`;

export const CampaignOverViewTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const CampaignContentTerm = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
`;

export const CampaignContentRewardText = styled.div`
  font-size: 12px;
`;

export const CampaignContentTermTitle = styled.div`
  display: table-cell;
  width: 100px;
`;

export const CampaignContentDotLine = styled.span`
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c3c3c3;
  margin: 10px 0;
`;

export const CampaignContextTermList = styled.ul`
  list-style-type: none;
`;

export const CampaignContextTermItem = styled.li`
  list-style-type: none;
`;

export const CampaignContentText = styled.span`
  vertical-align: top;
  font-size: 14px;
`;

export const CampaignContentNotes = styled.div`
  font-size: 14px;
  padding: 12px 10px;
`;

export const CampaignContentNotesTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
`;

export const CampaignPurchasedItemWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  margin-bottom: 25px;
`;

export const CampaignPurchasedItem = styled.div`
  background-color: #ffffff;
  text-align: center;
  font-size: 14px;
  border-bottom: 0.5px solid #dbdbdb;
`;

export const CampaignPurchasedItemDate = styled.div`
  text-align: left;
  margin-bottom: 10px;
`;

export const CampaignPurchasedItemUl = styled.div`
  list-style-type: none;
  margin-bottom: 15px;
`;

export const CampaignPurchasedItemLi = styled.div`
  display: flex;
`;

export const CampaignPurchasedItemContent = styled.div<CustomProps>`
  ${(props) =>
    props.right &&
    `
    text-align: right;
    flex: 0.5;
  `}
  ${(props) =>
    props.left &&
    `
    text-align: left;
    flex: 1;
  `}
`;

export const CampaignPurchasedNoItem = styled.div`
  font-size: 16px;
  color: #999999;
  margin-bottom: 24px;
`;

export const CampaignBulletList = styled.ul`
  padding-left: 10px;
  list-style-type: none;
  font-family: "Arial", sans-serif;
`;

export const CampaignBulletListItem = styled.li``;

export const CampaignTargetItemButton = styled.button`
  font-size: 12px;
  background-color: transparent;
  border: 0.5px solid #070707;
  border-radius: 4px;
  cursor: pointer;
  color: #000000;
`;

export const CampaignTargetItemWrapper = styled.div`
  text-align: left;
`;

export const CampaignTargetItemDateTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const CampaignBulletTargetItemList = styled.ul`
  padding-left: 10px;
  list-style-type: none;
`;

export const CampaignBulletTargetItemItem = styled.li`
  font-size: 14px;
`;

export const ClockWrapper = styled.div`
  margin: 3px 4px 0px 4px;
`;
