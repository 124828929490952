export const LOGIN_SET_EMAIL = "LOGIN_SET_EMAIL";
export const LOGIN_SET_PASSWORD = "LOGIN_SET_PASSWORD";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const loginSetEmail = (email: string) => ({
  type: LOGIN_SET_EMAIL,
  payload: email,
});
export const loginSetPassword = (password: string) => ({
  type: LOGIN_SET_PASSWORD,
  payload: password,
});

export const loginStart = () => ({
  type: LOGIN_START,
});
export const loginSuccess = (data: any) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});
export const loginFailure = (error: any) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
