import { z } from "zod";
import {
  EMAIL_MAX_LENGTH,
  EMAIL_VALIDATION_TEXT,
  MAX_LENGTH_VALIDATION_TEXT,
  PASSWORD_MAX_LENGTH,
  PASSWORD_VALIDATION_TEXT,
} from "../../../../common/constant/validations";

export const LoginValidationSchema = z.object({
  email: z
    .string()
    .email({ message: EMAIL_VALIDATION_TEXT })
    .max(EMAIL_MAX_LENGTH, {
      message: MAX_LENGTH_VALIDATION_TEXT.replace(
        "{max}",
        EMAIL_MAX_LENGTH.toString(),
      ),
    }),
  password: z
    .string()
    .min(1, { message: PASSWORD_VALIDATION_TEXT })
    .max(PASSWORD_MAX_LENGTH, {
      message: MAX_LENGTH_VALIDATION_TEXT.replace(
        "{max}",
        PASSWORD_MAX_LENGTH.toString(),
      ),
    }),
});
