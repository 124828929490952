import create from "zustand";
import { persist, redux } from "zustand/middleware";
import { filterWithinPublishPeriodInformations } from "../../../common/utils/information";
import { getInformation } from "../../../core/http/httpClient";
import { log } from "../../hooks/useLogMiddleware";
import { ADMIN_TENANT_CODE } from "../constant";
import {
  clearInformationData,
  fetchAdminInformationDataFailure,
  fetchAdminInformationDataStart,
  fetchAdminInformationDataSuccess,
  fetchInformationDataFailure,
  fetchInformationDataStart,
  fetchInformationDataSuccess,
} from "./actions";
import { initialState, reducer } from "./reducers";

const useInformationStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "information",
    }),
  ),
);

export const fetchInformation = async (tenant_code: string) => {
  const dispatch = useInformationStore.getState().dispatch;
  dispatch(fetchInformationDataStart());
  await getInformation(tenant_code)
    .then((res) => {
      dispatch(fetchInformationDataSuccess(res.data));
    })
    .catch((e) => {
      dispatch(fetchInformationDataFailure(e));
    });
};

export const fetchAdminInformation = async () => {
  const dispatch = useInformationStore.getState().dispatch;
  dispatch(fetchAdminInformationDataStart());
  await getInformation(ADMIN_TENANT_CODE)
    .then((res) => {
      dispatch(fetchAdminInformationDataSuccess(res.data));
    })
    .catch((e) => {
      dispatch(fetchAdminInformationDataFailure(e));
    });
};

export const concatInformation = async (tenant_code: string) => {
  fetchInformation(tenant_code);
  await fetchAdminInformation();
  const state = useInformationStore.getState();
  const informationList = state.informationList;
  const adminInformationList = state.adminInformationList;
  const concatedInformationList = [...informationList, ...adminInformationList];
  const filteredInformationList = filterWithinPublishPeriodInformations(
    concatedInformationList,
  );
  filteredInformationList.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );
  state.concatedInformationList = filteredInformationList;
};

export const clearInformation = () => {
  useInformationStore.getState().dispatch(clearInformationData());
};

export default useInformationStore;
