import { Image } from "image-js";
import { createWorker } from "tesseract.js";
import { OCR_WHITELIST } from "../constant";

export const imageToText = async (
  base64Image: string,
  onFinish: () => void,
) => {
  // Tesseract.jsのWorkerを生成
  const worker = await createWorker("jpn", 1, {
    logger: (m: any) => console.log(m),
  });

  await worker.setParameters({
    tessedit_char_whitelist: OCR_WHITELIST,
  });

  const {
    data: { text },
  } = await worker.recognize(base64Image);

  await worker.terminate();

  onFinish();

  return text;
};

export const getTextImage = async (base64CapImg: string) => {
  const image = await Image.load(base64CapImg);
  const width = image.width;
  const height = image.height;
  if (image) {
    // 画像が取得できた場合のみ処理を続行する
    const ocrImg = image
      /*.crop({y:100}) // 画像の上部100pxを切り取り
      .flipY() // 上下反転
      .crop({x:1798, y:950}) // 画像の右下(反転前の右上)を切り取り
      .flipY() // 反転を戻す
      .grey()	// グレースケール化
      .invert() // 色反転
      .toBase64('image/png');*/ // base64形式に変換
      .crop({
        x: width / 2 - width * (0.5 / 2),
        y: height / 2 - height * (0.1 / 2),
        width: width * 0.5,
        height: height * 0.1,
      })
      .toBase64("image/png");

    return `data:image/png;base64,${ocrImg}`;
  }
};
