import { create } from "zustand";
import { ModalBtn } from "../../../components/ui/modal/types";

export type ErrorModalState = {
  isShow: boolean;
  title: string;
  contents: any;
  buttons?: Array<ModalBtn>;
};

export const Error401ModaState = {
  isShow: true,
  title: "",
  contents: "",
  buttons: [
    {
      name: "閉じる",
      callback: () => {
        useErrorModal.getState().closeModal();
        const tenant_code = location.pathname.split("/")[1];
        location.href = `/${tenant_code}/login`;
      },
      styling: "border",
    },
  ],
};

export const ErrorModaState = {
  isShow: true,
  title: "",
  contents: "",
  buttons: [
    {
      name: "閉じる",
      callback: () => {
        useErrorModal.getState().closeModal();
      },
      styling: "border",
    },
  ],
};

type ErrorModalAction = {
  showData: (data: ErrorModalState) => void;
  closeModal: () => void;
};

export const useErrorModal = create<ErrorModalState & ErrorModalAction>()((
  set,
) => {
  return {
    isShow: false,
    title: "",
    contents: "",
    buttons: [],
    showData: (data) => set({ ...data }),
    closeModal: () =>
      set({
        isShow: false,
        title: "",
        contents: "",
        buttons: [],
      }),
  };
});
