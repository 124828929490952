import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useAssetStore from "../../../../lib/asset/redux/store";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import useInformationStore from "../../../../lib/information/redux/store";
import { Information } from "../../../../lib/information/redux/types";

const PER_PAGE = 10;

export const useNotification = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const member = useAssetStore((state) => state.member);
  const [currentPage, setCurrentPage] = useState<number>(
    page ? Number(page) : 1,
  );

  const notifications: Information[] = useInformationStore(
    (state) => state.concatedInformationList as Information[],
  );

  useEffect(() => {
    if (currentPage > 0 && currentPage <= totalPage)
      navigate(`/notification/${currentPage}`);
    else setCurrentPage(1);
  }, [currentPage]);

  const totalPage = useMemo(() => {
    return Math.ceil(notifications.length / PER_PAGE);
  }, [notifications.length]);

  const notificationsToDisplay = useMemo(() => {
    return notifications.slice(
      PER_PAGE * (currentPage - 1),
      PER_PAGE * currentPage,
    );
  }, [notifications, currentPage]);

  const disablePrevious = useMemo(() => {
    return currentPage === 1;
  }, [currentPage]);

  const disableNext = useMemo(() => {
    return currentPage === totalPage;
  }, [currentPage, totalPage]);

  const onPrevious = useCallback(() => {
    if (currentPage === 1) return;

    setCurrentPage((prev) => prev - 1);
  }, [currentPage]);

  const onNext = useCallback(() => {
    if (currentPage === totalPage) return;

    setCurrentPage((prev) => prev + 1);
  }, [currentPage, totalPage]);

  const pageText = useMemo(() => {
    if (currentPage === totalPage) {
      return `${PER_PAGE * (currentPage - 1) + 1} - ${notifications.length}件／全${notifications.length}件中`;
    }
    return `${PER_PAGE * (currentPage - 1) + 1} - ${PER_PAGE * currentPage}件／全${notifications.length}件中`;
  }, [currentPage, totalPage, notifications.length]);

  return {
    notificationsToDisplay,
    currentPage,
    totalPage,
    disablePrevious,
    disableNext,
    onPrevious,
    onNext,
    pageText,
  };
};
