export const PAGE_TYPE: { [key: string]: string } = {
  PROGRESS: "progress",
  PARTICIPATING: "participating",
  EARNED: "earned",
};

export const PAGE_TITLE: { [key: string]: string } = {
  progress: "実施中",
  participating: "参加中",
  earned: "獲得した特典",
};

export const EARNED_FILTER_NAME: { [key: string]: string } = {
  all: "すべて",
  available: "利用可能特典",
  used: "利用済み特典",
};

export const SCROLL_PAGE_HEIGHT = 200;
export const PREVIEW = "preview";
