import {
  CLEAR_PARTICIPATING_CAMPAIGN_DATA,
  FETCH_PARTICIPATING_CAMPAIGN_DATA_FAILURE,
  FETCH_PARTICIPATING_CAMPAIGN_DATA_START,
  FETCH_PARTICIPATING_CAMPAIGN_DATA_SUCCESS,
  FETCH_PARTICIPATING_CATEGORY_DATA,
  FILTER_PARTICIPATING_CAMPAIGN_DATA,
  RESET_PARTICIPATING_CAMPAIGN_DATA,
} from "./actions";

export const initialState = {
  loading: false,
  participatingCampaigns: [],
  filteredParticipatingCampaigns: [],
  participatingCategories: [],
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PARTICIPATING_CAMPAIGN_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_PARTICIPATING_CAMPAIGN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        participatingCampaigns: action.payload,
      };
    case FETCH_PARTICIPATING_CAMPAIGN_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FILTER_PARTICIPATING_CAMPAIGN_DATA:
      return { ...state, filteredParticipatingCampaigns: action.payload };
    case RESET_PARTICIPATING_CAMPAIGN_DATA:
      return {
        ...state,
        filteredParticipatingCampaigns: state.participatingCampaigns,
      };
    case FETCH_PARTICIPATING_CATEGORY_DATA:
      return { ...state, participatingCategories: action.payload };
    case CLEAR_PARTICIPATING_CAMPAIGN_DATA:
      return initialState;
    default:
      return state;
  }
};
