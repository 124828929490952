import { create } from "zustand";

interface MenuState {
  isShowWithdrawModal: boolean;
  setIsShowWithdrawModal: (isShowWithdrawModal: boolean) => void;
}

export const useMenu = create<MenuState>()((set) => ({
  isShowWithdrawModal: false,
  setIsShowWithdrawModal: (isShow: boolean) =>
    set((state: any) => ({ isShowWithdrawModal: isShow })),
}));
