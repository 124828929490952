import styled from "styled-components";

type ImageProps = {
  src: string;
};

export const RouletteAnimationBg = styled.div`
  position: relative;
  width: 220px;
  height: 253px;
  top: 0;
  left: 0;
`;
export const RouletteAnimContainer = styled.div`
  position: relative;
  margin: 0 auto 20px auto;
  width: 220px;
`;
export const RouletteLoseAnimationBg = styled.div`
  position: relative;
  margin: 0 auto 20px auto;
  width: 282px;
  height: 241px;
`;
export const RouletteBg = styled.div`
  position: absolute;
  width: 220px;
  height: 253px;
  top: 0;
  left: 0;
`;
//Redraw
export const RedrawContainer = styled.div`
  position: relative;
  width: 254px;
  height: 247px;
  margin: 0 auto 35px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AnimationBg = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 254px;
  transform: translateX(-50%);
`;
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  animation: 0.5s ease-out 0s fade-in;
`;
export const IconCheck = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  margin: 40px 45px 0 0;
  opacity: 0;
  animation: 0.5s ease-out 0.75s fade-in forwards;
`;
export const Message = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 0 30px 0;
  color: #0271ff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5em;
  opacity: 0;
  animation: 0.5s ease-out 1s fade-in forwards;
`;
export const Image = styled.img<ImageProps>`
  width: 50%;
  height: auto;
  margin: 0 auto;
`;
