export const log = (config: any) => (set: any, get: any, api: any) =>
  config(
    (...args: any) => {
      process.env.REACT_APP_API_BASE !== "https://bb-mileage.com/" &&
        console.log("  applying", args);
      set(...args);
      process.env.REACT_APP_API_BASE !== "https://bb-mileage.com/" &&
        console.log("  new state", get());
    },
    get,
    api,
  );
