import { useCallback, useState } from "react";
import { useNavigate } from "../../../../lib/hooks/useNavigate";

type Props = {
  images: string[];
  swiperRef: any;
};

export const useWalkthrough = ({ images, swiperRef }: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const navigate = useNavigate();

  const onNext = useCallback(() => {
    if (currentStep === images.length - 1) return navigate("/terms_of_service");
    setCurrentStep((prev) => prev + 1);
    swiperRef.current.slideNext();
  }, [currentStep]);

  const onPrevious = useCallback(() => {
    if (currentStep === 0) return;
    setCurrentStep((prev) => prev - 1);
    swiperRef.current.slidePrev();
  }, [currentStep]);

  const onSkip = useCallback(() => {
    navigate("/terms_of_service");
  }, []);

  return { currentStep, setCurrentStep, onNext, onPrevious, onSkip };
};
