import styled from "styled-components";

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1em;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1000;
`;

export const OCRWrapper = styled.div`
  margin: auto;
  max-width: 640px;
  z-index: 1001;
  padding: 0 20px 25px 20px;
`;

export const WebcamWrapper = styled.div`
  position: relative;
  margin-top: 0px;
  max-width: 340px;
  width: 100%;
  margin: 0 auto 20px auto;
`;
