import styled from "styled-components";

export const StyledInput = styled.input`
  margin: 12px 0px;
  padding: 13px 16px;
  border-width: 1px;
  border-radius: 5px;
  border-color: #99999999;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  height: 48px;
  font-size: 16px;
`;

export const InputLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const RequiredLabel = styled.div`
  margin: 5px 10px;
  padding: 0px 5px;
  color: #ffffff;
  background-color: #cc7673;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
`;

export const ToggleShowPasswordButton = styled.div`
  position: absolute;
  top: 37%;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
`;

export const TextInputSubtext = styled.div`
  color: #999999;
  font-size: 12px;
  text-align: left;
`;

export const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  text-align: left;
`;

export const DisabledTextField = styled.div`
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid #99999999;
`;
