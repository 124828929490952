import React from "react";
import { settingStore } from "../../../template/store/setting";
import { EARNED_FILTER_NAME, PAGE_TITLE, PAGE_TYPE } from "../constant";
import {
  FilterButton,
  FilterButtonWrap,
  SelectTab,
  TabLabel,
  TabRadioInput,
  VerticalDivider,
} from "../style";

interface ButtonGroupProps {
  options?: string[];
  selectedOption: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const RadioButtonGroup: React.FC<ButtonGroupProps> = ({
  selectedOption,
  onChange,
}) => {
  const themeColor = settingStore((store) => store.theme_color);
  return (
    <SelectTab
      name="controlled-radio-buttons-group"
      value={selectedOption}
      onChange={onChange}
      row
    >
      <TabLabel
        value={PAGE_TYPE.PROGRESS}
        control={<TabRadioInput />}
        label={PAGE_TITLE.progress}
        checked={selectedOption === PAGE_TYPE.PROGRESS}
        color={themeColor}
      />
      <TabLabel
        value={PAGE_TYPE.PARTICIPATING}
        control={<TabRadioInput />}
        label={PAGE_TITLE.participating}
        checked={selectedOption === PAGE_TYPE.PARTICIPATING}
        color={themeColor}
      />
      <TabLabel
        value={PAGE_TYPE.EARNED}
        control={<TabRadioInput />}
        label={PAGE_TITLE.earned}
        checked={selectedOption === PAGE_TYPE.EARNED}
        color={themeColor}
      />
    </SelectTab>
  );
};

export const FilterButtonGroup: React.FC<ButtonGroupProps> = ({
  options,
  selectedOption,
  onClick,
}) => {
  const themeColor = settingStore((store) => store.theme_color);
  return (
    <FilterButtonWrap>
      {options?.map((option, index) =>
        index === 0 ? (
          <div key={`${option}-${index}`} style={{ display: "flex" }}>
            <FilterButton
              key={`${option}-${index}`}
              option={option}
              selected_option={selectedOption}
              value={option}
              color={themeColor}
              onClick={onClick}
            >
              {option === "all" && "すべて"}
            </FilterButton>
            <VerticalDivider />
          </div>
        ) : (
          index > 0 && (
            <FilterButton
              key={`${option}-${index}`}
              option={option}
              selected_option={selectedOption}
              value={option}
              onClick={onClick}
              color={themeColor}
            >
              {option}
            </FilterButton>
          )
        ),
      )}
    </FilterButtonWrap>
  );
};

export const EarnedFilterButtonGroup: React.FC<ButtonGroupProps> = ({
  options,
  selectedOption,
  onClick,
}) => {
  const themeColor = settingStore((store) => store.theme_color);
  return (
    <FilterButtonWrap>
      {options?.map((option, index) =>
        index === 0 ? (
          <>
            <FilterButton
              key={`${option}-${index}`}
              option={option}
              selected_option={selectedOption}
              value={option}
              onClick={onClick}
              color={themeColor}
            >
              {EARNED_FILTER_NAME[option]}
            </FilterButton>
            <VerticalDivider />
          </>
        ) : (
          index > 0 && (
            <FilterButton
              key={`${option}-${index}`}
              option={option}
              selected_option={selectedOption}
              value={option}
              onClick={onClick}
              color={themeColor}
            >
              {EARNED_FILTER_NAME[option]}
            </FilterButton>
          )
        ),
      )}
    </FilterButtonWrap>
  );
};
