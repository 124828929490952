import { create } from "zustand";

type TemplateState = {
  isLoggedIn: boolean;
};

type TemplateAction = {
  setIsLoggedIn: (data: boolean) => void;
};

export const templateStore = create<TemplateState & TemplateAction>()((set) => {
  return {
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) =>
      set((state) => ({ ...state, isLoggedIn })),
  };
});
