import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { MemberRegistrationEmailContainer } from "../../features/member_registration";
import useMemberRegistrationStore, {
  sendMemberRegistrationEmail,
} from "../../features/member_registration/redux/store";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { Footer } from "../../ui/footer/Footer";
import { useNavigate } from "../../../lib/hooks/useNavigate";

export const MemberRegistrationEmail: React.FC = () => {
  const { tenant_code } = useParams();
  const navigation = useNavigate();
  const enabled = useMemberRegistrationStore((state) => state.enabled);

  const onComplete = useCallback(() => {
    navigation("/mail_send");
  }, []);

  return (
    <Base>
      <HeaderTemplate>
        <MemberRegistrationEmailContainer page={`register`} />
      </HeaderTemplate>
      <Footer
        buttonText="送信"
        onClickButton={() => {
          sendMemberRegistrationEmail(tenant_code ?? "", "register")
            .then(onComplete)
            .catch((e) => {
              /* nothing to do */
              console.log("error", e);
            });
        }}
        buttonDisabled={!enabled}
      />
    </Base>
  );
};
