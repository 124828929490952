import React from "react";
import { WalkthroughContainer } from "../../features/walkthrough";
import { WALKTHROUGH_IMAGES } from "../../features/walkthrough/constant";
import { useWalkthrough } from "../../features/walkthrough/hook";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { Footer } from "../../ui/footer/Footer";

export const Walkthrough: React.FC = () => {
  const swiperRef = React.useRef();
  const { currentStep, setCurrentStep, onNext, onPrevious, onSkip } =
    useWalkthrough({ images: WALKTHROUGH_IMAGES, swiperRef: swiperRef });

  return (
    <Base>
      <HeaderTemplate>
        <WalkthroughContainer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          swiperRef={swiperRef}
        />
      </HeaderTemplate>
      <Footer
        onClickButton={onNext}
        textButtonText="スキップ"
        onClickTextButton={onSkip}
      />
    </Base>
  );
};
