import React, { useCallback } from "react";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import styled from "styled-components";
import { Footer } from "../../ui/footer/Footer";
import { useNavigate } from "../../../lib/hooks/useNavigate";
import { BtnContainer, HeaderContainer } from "../../../common/style";

export const MainContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const TextContainer = styled.p`
  text-align: left;
  margin: 40px 16px 0 16px;
`;

export const Attention = styled.p`
  font-size: 12px;
  text-align: left;
  line-height: 1.5em;
  letter-spacing: 0.05em;
`;

export const MailSend: React.FC = () => {
  const navigation = useNavigate();
  const onPrev = useCallback(() => {
    history.back();
  }, []);
  return (
    <Base>
      <HeaderTemplate>
        <HeaderContainer>
          <BtnContainer onClick={onPrev}>
            <img src="/assets/common/icon_back.svg" />
          </BtnContainer>
          <h3>メールを送信しました。</h3>
        </HeaderContainer>
        <TextContainer>
          受信したメールに記載されているURLから、会員登録ページへお進みください。
          <br />
          <Attention>
            ※メールが届かない場合は、迷惑メールフォルダに入っていないか、またメールの受信拒否設定をしている場合は解除してください。
          </Attention>
        </TextContainer>
      </HeaderTemplate>
      <Footer buttonText="戻る" onClickButton={onPrev} />
    </Base>
  );
};
