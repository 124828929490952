import React from "react";
import { CSSProperties } from "styled-components";

interface ProgressBarProps {
  style?: CSSProperties;
}

export const Clock: React.FC<ProgressBarProps> = (props) => {
  return (
    <span style={props.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <path
          id="schedule_FILL0_wght400_GRAD0_opsz24_2_"
          data-name="schedule_FILL0_wght400_GRAD0_opsz24 (2)"
          d="M89.31-869.71l.98-.98-2.59-2.59v-3.22H86.3v3.78ZM87-866a6.816,6.816,0,0,1-2.73-.551,7.07,7.07,0,0,1-2.222-1.5,7.068,7.068,0,0,1-1.5-2.222A6.816,6.816,0,0,1,80-873a6.816,6.816,0,0,1,.551-2.73,7.069,7.069,0,0,1,1.5-2.222,7.07,7.07,0,0,1,2.222-1.5A6.817,6.817,0,0,1,87-880a6.817,6.817,0,0,1,2.73.551,7.07,7.07,0,0,1,2.222,1.5,7.069,7.069,0,0,1,1.5,2.222A6.816,6.816,0,0,1,94-873a6.816,6.816,0,0,1-.551,2.73,7.068,7.068,0,0,1-1.5,2.222,7.07,7.07,0,0,1-2.222,1.5A6.816,6.816,0,0,1,87-866ZM87-873Zm0,5.6a5.4,5.4,0,0,0,3.964-1.636A5.4,5.4,0,0,0,92.6-873a5.4,5.4,0,0,0-1.636-3.964A5.4,5.4,0,0,0,87-878.6a5.4,5.4,0,0,0-3.964,1.636A5.4,5.4,0,0,0,81.4-873a5.4,5.4,0,0,0,1.636,3.964A5.4,5.4,0,0,0,87-867.4Z"
          transform="translate(-80 880)"
        />
      </svg>
    </span>
  );
};
