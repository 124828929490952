export const FETCH_INFORMATION_DATA_START = "FETCH_INFORMATION_DATA_START";
export const FETCH_INFORMATION_DATA_SUCCESS = "FETCH_INFORMATION_DATA_SUCCESS";
export const FETCH_INFORMATION_DATA_FAILURE = "FETCH_INFORMATION_DATA_FAILURE";
export const FETCH_ADMIN_INFORMATION_DATA_START =
  "FETCH_ADMIN_INFORMATION_DATA_START";
export const FETCH_ADMIN_INFORMATION_DATA_SUCCESS =
  "FETCH_ADMIN_INFORMATION_DATA_SUCCESS";
export const FETCH_ADMIN_INFORMATION_DATA_FAILURE =
  "FETCH_ADMIN_INFORMATION_DATA_FAILURE";
export const CLEAR_INFORMATION_DATA = "CLEAR_INFORMATION_DATA";

export const fetchInformationDataStart = () => ({
  type: FETCH_INFORMATION_DATA_START,
});
export const fetchInformationDataSuccess = (data: any) => ({
  type: FETCH_INFORMATION_DATA_SUCCESS,
  payload: data,
});
export const fetchInformationDataFailure = (error: any) => ({
  type: FETCH_INFORMATION_DATA_FAILURE,
  payload: error,
});
export const fetchAdminInformationDataStart = () => ({
  type: FETCH_ADMIN_INFORMATION_DATA_START,
});
export const fetchAdminInformationDataSuccess = (data: any) => ({
  type: FETCH_ADMIN_INFORMATION_DATA_SUCCESS,
  payload: data,
});
export const fetchAdminInformationDataFailure = (error: any) => ({
  type: FETCH_ADMIN_INFORMATION_DATA_FAILURE,
  payload: error,
});
export const clearInformationData = () => ({ type: "CLEAR_INFORMATION_DATA" });
